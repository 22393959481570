import React, { createContext, useContext } from 'react';
import { SetURLSearchParams, createSearchParams, useSearchParams } from 'react-router-dom';

const SearchParamsContext = createContext<[URLSearchParams, SetURLSearchParams]>([createSearchParams(), () => {}]);

type searchParamsProviderProperties = {
  children: React.ReactNode;
};

export const SearchParamsProvider = ({ children }: searchParamsProviderProperties) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <SearchParamsContext.Provider value={[searchParams, setSearchParams]}>{children}</SearchParamsContext.Provider>
  );
};

export const useGlobalSearchParams = () => {
  return useContext(SearchParamsContext);
};
