import { Box, SxProps, Theme } from '@mui/material';

const boxStyle: SxProps<Theme> = {
  padding: 0,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box sx={boxStyle}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
