import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { Person, Project, ProjectRole } from 'types/generated/graphql';
import {
  ASSIGN_TO_AN_EXISTING_ROLE_BUTTON_TEXT,
  ASSIGN_TO_A_NEW_ROLE_BUTTON_TEXT,
  LEAVE_AVAILABLE_BUTTON_TEXT,
  REASSIGN_PERSONNEL_STEP_INDEX,
  REASSIGN_SUMMARY_STEP_INDEX,
  REASSIGN_TO_LEAVE_OR_NEW_ROLE_STEP_INDEX,
} from '../ReassignRoleDialog';

type ReassignToAnotherRoleStepTypeParams = {
  radioButtonSelection: string;
  setActiveStep: (value: React.SetStateAction<number>) => void;
  setReassignProjectAndRole: (x: ProjectsGridRowData) => void;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  setRadioButtonSelection: (value: React.SetStateAction<string>) => void;
};

export const reassignToAnotherRoleStep = ({
  radioButtonSelection,
  setActiveStep,
  setReassignProjectAndRole,
  reassignProjectAndRole,
  setRadioButtonSelection,
}: ReassignToAnotherRoleStepTypeParams) => {
  if (radioButtonSelection === ASSIGN_TO_AN_EXISTING_ROLE_BUTTON_TEXT) {
    setActiveStep(REASSIGN_PERSONNEL_STEP_INDEX);
  } else if (radioButtonSelection === ASSIGN_TO_A_NEW_ROLE_BUTTON_TEXT) {
    setReassignProjectAndRole({
      project: {} as Project,
      role: { person: reassignProjectAndRole?.role?.person as Person } as ProjectRole,
    });
    setActiveStep(REASSIGN_TO_LEAVE_OR_NEW_ROLE_STEP_INDEX);
  } else if (radioButtonSelection === LEAVE_AVAILABLE_BUTTON_TEXT) {
    setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
  }
  setRadioButtonSelection('');
};
