const TRAINING_DIALOG_VIEWS = {
  VIEW_TRAINING: 'viewTraining',
  EDIT_TRAINING: 'editTraining',
  ADD_TRAINING: 'addTraining',
  VIEW_PDF: 'viewPdfAttachment',
  VIEW_IMAGE: 'viewImageAttachment',
  CONFIRM_DELETE: 'confirmDelete',
  CONFIRM_CANCEL: 'confirmCancel',
  NO_DOC_PREVIEW: 'noDocPreview',
  NO_EXCEL_PREVIEW: 'noExcelPreview',
};

export default TRAINING_DIALOG_VIEWS;
