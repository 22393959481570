import ErrorIcon from '@mui/icons-material/Error';
import ImageIcon from '@mui/icons-material/Image';
import { Grid, SxProps, Theme } from '@mui/material';

const imgStyle: SxProps<Theme> = {
  display: 'block',
  maxWidth: '90%',
};

const fullSizeScaleStyle: SxProps<Theme> = {
  width: '50%',
  height: '50%',
  alignSelf: 'center',
};

const FileDisplayImage = ({ file, isFullSizePreview = false }: { file: any; isFullSizePreview?: boolean }) => {
  if (file) {
    if (file.preview && !file.cachedUrl) {
      return (
        <Grid sx={imgStyle}>
          <img data-testid="image-preview" src={file.preview} alt={`Training-${file.name}`} />
        </Grid>
      );
    }

    if (file.cachedUrl && !file.isMalware) {
      return (
        <Grid sx={imgStyle}>
          <img data-testid="image-cachedUrl" src={file.cachedUrl} alt={`${file.name}`} />
        </Grid>
      );
    }

    if (file.isMalware) {
      return (
        <Grid sx={isFullSizePreview ? fullSizeScaleStyle : undefined}>
          <ErrorIcon data-testid="malware-icon" />
        </Grid>
      );
    }
  }

  return (
    <>
      <ImageIcon data-testid="image-icon" sx={fullSizeScaleStyle} />
    </>
  );
};

export default FileDisplayImage;
