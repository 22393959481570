import { Column, SortComparer } from '@syncfusion/ej2-react-grids';
import { DateTime } from 'luxon';

/**
 * A value is considered nullish if it is null, undefined, empty string, or a non-empty object/array containing only nullish values
 */
export const isNullish: { (value: any): boolean } = (value) => {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value.every((item) => isNullish(item));
  }

  if (typeof value === 'object') {
    const nestedValues = Object.values(value);
    return nestedValues.length > 0 && nestedValues.every((nested) => isNullish(nested));
  }

  return false;
};

export const generateTransactionKey = () => {
  return Date.now() + (Math.random() * 100000).toFixed();
};

// Serializes a Date conforming to ISO 8601 compliant date-string
// in the format MM/DD/YYYY.
export const serializeDate = (date: Date) => {
  return DateTime.fromJSDate(date).toISODate();
};

export const convertDateToUseableISOString = (date: Date) => {
  return date.toISOString().split('T')[0];
};

export const convertUTCDateToLocalDate = (date: any) => {
  return DateTime.fromISO(date).toLocal();
};

export const isImageFile = (file?: any) => {
  return file?.contentType?.includes('image');
};

export const isPdfFile = (file?: any) => {
  return file?.contentType === 'application/pdf';
};

const getCachedBlob = async (url?: string) => {
  if (url) {
    return fetch(url)
      .then(function (response) {
        if (response.status === 200) {
          return response.blob();
        }
        return null;
      })
      .catch(() => {
        return null;
      });
  }
  return null;
};

export const getCachedUrl = async (url?: string) => {
  return getCachedBlob(url).then((blob) => {
    return blob ? URL.createObjectURL(blob) : null;
  });
};

export const formatAddress = (
  streetAddressOne: string | null,
  streetAddressTwo: string | null,
  city: string | null,
  state: string | null,
  zip: string | null,
) => {
  return `${streetAddressOne ? streetAddressOne + ', ' : ''} ${streetAddressTwo ? streetAddressTwo + ', ' : ''} ${
    city ? city + ', ' : ''
  } ${state ? state : ''} ${zip ? zip : ''}`;
};

export const calculateDuration = (StartDate: Date, EndDate: Date) => {
  const i1 = DateTime.fromJSDate(StartDate),
    i2 = DateTime.fromJSDate(EndDate);

  return StartDate || EndDate ? i2.diff(i1, 'days').toObject().days ?? 0 : 0;
};

export const graphqlDateToJsDate = (date: string) => {
  return graphqlDateToDateTime(date).toUTC().toJSDate();
};

export const graphqlDateToSyncfusionDate = (date: string) => {
  // Set the day to 2, because Syncfusion seems to have trouble with dates.
  // This way, if it is off by one, it will at least be in the correct month.
  return graphqlDateToDateTime(date).toJSDate();
};

export const graphqlDateToSyncfusionEndDate = (date: string) => {
  // Set the end date to the end of the month, because Syncfusion seems to have trouble with dates.
  return graphqlDateToDateTime(date).endOf('month').toJSDate();
};

export const startDateTemplate = (props: any) => {
  if (props.level === 0 && props.childRecords.length > 0) {
    const firstIndex = 0;
    const earliestStartRoleDate = props.childRecords.map((record: any) => record.startDate).sort()[firstIndex];
    return DateTime.fromJSDate(earliestStartRoleDate).toFormat(`MM/dd/yyyy`);
  } else {
    return props.startDate ? DateTime.fromJSDate(props.startDate).toFormat(`MM/dd/yyyy`) : '';
  }
};

export const endDateTemplate = (props: any) => {
  if (props.level === 0 && props.childRecords.length > 0) {
    const firstIndex = 0;
    const latestEndRoleDate = props.childRecords.map((record: any) => record.endDate).sort()[firstIndex];
    return DateTime.fromJSDate(latestEndRoleDate).toFormat(`MM/dd/yyyy`);
  } else {
    return props.endDate ? DateTime.fromJSDate(props.endDate).toFormat(`MM/dd/yyyy`) : '';
  }
};

export const GRAPHQL_DATE_FORMAT = 'yyyy-MM-dd';
export const graphqlDateToDateTime = (date: string) => DateTime.fromFormat(date, GRAPHQL_DATE_FORMAT);

export const graphqlDateToDateTimeMonthFirstFormat = (date: string) => DateTime.fromFormat(date, 'MM/dd/yyyy');

export const formatDateWithOffset = (date: string) => {
  const timezoneOffset = DateTime.fromISO(date).offset / 60;
  return DateTime.fromISO(date).minus({ hours: timezoneOffset }).toJSDate().toLocaleDateString();
};

export const convertSyncfusionDatePickerToValidDate = (date: Date) => {
  return DateTime.fromJSDate(date).set({ hour: 0 }).toJSDate();
};

export const millisecondsToString = (milliseconds: number) => {
  const months = Math.floor(milliseconds / 1000 / 60 / 60 / 24 / 30.5) % 12;
  const years = Math.floor(milliseconds / 1000 / 60 / 60 / 24 / 365);
  return years + 'yrs ' + months + 'mth';
};

export const snapTaskbarStartOfMonth = (date: Date) => {
  return DateTime.fromJSDate(date).startOf('month').toJSDate();
};

export const snapTaskbarEndOfMonth = (date: Date) => {
  return DateTime.fromJSDate(date).endOf('month').toJSDate();
};

// Copied from sample code at https://www.syncfusion.com/blogs/post/5-different-ways-to-deep-compare-javascript-objects.aspx
export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

const isObject = (object: any) => {
  return object != null && typeof object === 'object';
};

export const lengthOfTimeInYearsSortComparer: SortComparer = (a = '', b = '') => {
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    if (Number(a.replace('y', '').trim()) > Number(b.replace('y', '').trim())) {
      return -1;
    } else if (Number(a.replace('y', '').trim()) === Number(b.replace('y', '').trim())) {
      return 0;
    } else {
      return 1;
    }
  }
  return 0;
};

const makeExportColumn = (field: string, headerText: string) => {
  return { field, headerText, allowSorting: true } as Partial<Column>;
};

export const createExportColumns = (visibleColumns: Column[]) => {
  return visibleColumns.map((column) => {
    return makeExportColumn(column.field, column.headerText);
  }) as Column[];
};
