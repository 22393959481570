import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { ROLES } from 'constants/roles';
import { DateTime } from 'luxon';
import {
  AddToLeaveType,
  newAssignmentType,
  preservedRolesType,
  reassignmentType,
  ReassignRoleAssignmentsType,
  ReassignRoleSummaryReassignmentsType,
} from 'types';
import { Person, Project, ProjectRole, ProjectRoleUpsertInput } from 'types/generated/graphql';
import { convertDateToUseableISOString, generateTransactionKey } from 'utils/general';

export const getRoleUpsertInputWithReassignment = (
  reassignment: ReassignRoleAssignmentsType,
  transactionKey: string,
  projectId?: string | null,
): ProjectRoleUpsertInput => ({
  transactionKey,
  projectId: projectId ?? '',
  roleName: reassignment?.newAssignment?.role?.roleName ?? '',
  startDate: convertDateToUseableISOString(new Date(reassignment?.newAssignment?.startDate ?? '')),
  endDate: convertDateToUseableISOString(new Date(reassignment?.newAssignment?.endDate ?? '')),
  personId: reassignment?.person?.id,
  focusArea: '',
});

type GetNewRoleUpsertInputTypeParams = {
  roleName?: string;
  startDate?: string | null;
  endDate?: string | null;
  personId?: string | null;
  transactionKey: string;
  projectId?: string | null;
};

export const getNewRoleUpsertInput = ({
  roleName,
  startDate,
  endDate,
  personId,
  transactionKey,
  projectId,
}: GetNewRoleUpsertInputTypeParams): ProjectRoleUpsertInput => ({
  transactionKey,
  projectId: projectId ?? '',
  roleName: roleName ?? '',
  startDate: convertDateToUseableISOString(new Date(startDate ?? '')),
  endDate: convertDateToUseableISOString(new Date(endDate ?? '')),
  personId: personId,
  focusArea: '',
});

type GetRoleUpsertInputTypeParams = {
  roleName?: string;
  startDate?: string | null;
  endDate?: string | null;
  personId?: string | null;
  projectId?: string | null;
  id?: string | null;
};

export const getRoleUpsertInput = ({
  roleName,
  startDate,
  endDate,
  personId,
  projectId,
  id,
}: GetRoleUpsertInputTypeParams): ProjectRoleUpsertInput => ({
  id: id ?? '',
  projectId: projectId ?? '',
  roleName: roleName ?? '',
  startDate: convertDateToUseableISOString(new Date(startDate ?? '')),
  endDate: convertDateToUseableISOString(new Date(endDate ?? '')),
  personId: personId,
  focusArea: '',
});

type CreateReassignmentTypeParams = {
  person: Person;
  newProject: Project;
  newRole: ProjectRole;
  newStartDate?: string | null;
  newEndDate?: string | null;
  oldProject?: Project;
  oldRole?: ProjectRole;
  oldStartDate?: string | null;
  oldEndDate?: string | null;
};

export const createReassignment = ({
  person,
  newProject,
  newRole,
  newStartDate,
  newEndDate,
  oldProject,
  oldRole,
  oldStartDate,
  oldEndDate,
}: CreateReassignmentTypeParams) => {
  const reassignment: ReassignRoleAssignmentsType = {
    person: person ?? undefined,
    newAssignment: {
      project: newProject ?? undefined,
      role: newRole ?? undefined,
      startDate: newStartDate ?? '',
      endDate: newEndDate ?? '',
    },
    oldAssignment: {
      project: oldProject ?? undefined,
      role: oldRole ?? undefined,
      startDate: oldStartDate ?? '',
      endDate: oldEndDate ?? '',
    },
  };
  return reassignment;
};

type CreateNewReassignmentTypeParams = {
  projectName?: string | null;
  roleName?: string | null;
  personnelBeingReassigned?: string | null;
  personnelReassignedToRole?: string | null;
  personnelReassignedToRoleStartDate?: string | null;
  personnelReassignedToRoleEndDate?: string | null;
  notes?: string;
};

export const createNewReassignment = ({
  projectName,
  roleName,
  personnelBeingReassigned,
  personnelReassignedToRole,
  personnelReassignedToRoleStartDate,
  personnelReassignedToRoleEndDate,
  notes,
}: CreateNewReassignmentTypeParams): reassignmentType => {
  const reassignment: reassignmentType = {
    projectName,
    roleName,
    personnelBeingReassigned,
    personnelReassignedToRole,
    personnelReassignedToRoleStartDate,
    personnelReassignedToRoleEndDate,
    notes,
  };

  return reassignment;
};

type CreatePreservedRolesTypeParams = {
  projectName?: string | null;
  personnel?: string | null;
  roleName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
};

export const createPreservedRoles = ({
  projectName,
  personnel,
  roleName,
  startDate,
  endDate,
}: CreatePreservedRolesTypeParams): preservedRolesType => {
  const preservedRole: preservedRolesType = {
    projectName,
    personnel,
    roleName,
    startDate,
    endDate,
  };

  return preservedRole;
};

type CreateNewAssignmentTypeParams = {
  projectName?: string | null;
  personnel?: string | null;
  roleName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
};

export const createNewAssignment = ({
  projectName,
  personnel,
  roleName,
  startDate,
  endDate,
}: CreateNewAssignmentTypeParams): newAssignmentType => {
  const newAssignment: newAssignmentType = {
    projectName,
    personnel,
    roleName,
    startDate,
    endDate,
  };

  return newAssignment;
};

type CreateAddToLeaveTypeParams = {
  personnel?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  notes?: string;
};

export const createAddToLeave = ({
  personnel,
  startDate,
  endDate,
  notes,
}: CreateAddToLeaveTypeParams): AddToLeaveType => {
  const addToLeave: AddToLeaveType = {
    personnel,
    startDate,
    endDate,
    notes,
  };

  return addToLeave;
};

type CreateUnfilledProjectRoleAndAddToReassignmentSummaryTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  startDateWatch: DateTime | null;
  transactionKey: string;
  setTransactionKey: (value: React.SetStateAction<string>) => void;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setReassignmentProjectRoleMutations: (value: React.SetStateAction<ProjectRoleUpsertInput[]>) => void;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  setReassignRoleSummaryReassignments: (value: React.SetStateAction<ReassignRoleSummaryReassignmentsType>) => void;
};

export const createUnfilledProjectRoleAndAddToReassignmentSummary = ({
  reassignProjectAndRole,
  startDateWatch,
  transactionKey,
  setTransactionKey,
  reassignmentProjectRoleMutations,
  setReassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  setReassignRoleSummaryReassignments,
}: CreateUnfilledProjectRoleAndAddToReassignmentSummaryTypeParams) => {
  const newUnfilledProjectRole = getNewRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: reassignProjectAndRole?.role?.endDate,
    personId: null,
    transactionKey,
    projectId: reassignProjectAndRole?.project?.id,
  });

  setTransactionKey(generateTransactionKey());

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    newUnfilledProjectRole,
  ];
  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const newUnfilledAssignment = createNewAssignment({
    projectName: reassignProjectAndRole?.project?.name,
    personnel: null,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    startDate: startDateWatch?.toISODate(),
    endDate: reassignProjectAndRole?.role?.endDate,
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    newAssignments:
      reassignRoleSummaryReassignments.newAssignments && reassignRoleSummaryReassignments.newAssignments.length > 0
        ? [...reassignRoleSummaryReassignments.newAssignments, newUnfilledAssignment]
        : [newUnfilledAssignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

export const getRoleFullDisplayNameFromPersonnelWatch = (personnelWatch: { person?: Person; role?: ProjectRole }) => {
  return personnelWatch?.role?.roleFullDisplayName
    ? personnelWatch?.role?.roleFullDisplayName
    : personnelWatch?.role?.roleAbbreviation
    ? ROLES.find((role) => role.abbreviation === personnelWatch?.role?.roleAbbreviation)?.fullDisplayName
    : ROLES.find((role) => role.roleName === personnelWatch?.role?.roleName)?.fullDisplayName;
};
