import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import StyledDatePicker from 'components/shared/KeyboardDatePicker';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { ReassignRoleFormValues } from 'types';
import { Project, ProjectRole } from 'types/generated/graphql';

import { Autocomplete, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type ReassignRoleProps = {
  control: Control<ReassignRoleFormValues, any>;
  setValue: UseFormSetValue<ReassignRoleFormValues>;
  currentProjectAndRoleListFromDatasource: ProjectsGridRowData[];
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  endDateWatch: DateTime | null;
  projectWatch: Project | undefined;
};

type ReassignRoleListType = {
  roleOptionLabel: string;
  role: ProjectRole | undefined;
  rank: number;
};

const reassignRoleHeaderContainer: SxProps<Theme> = {
  marginBottom: '20px',
};

const filterAndSortRoleList = (
  reassignProjectAndRole: ProjectsGridRowData | undefined,
  currentProjectAndRoleListFromDatasource: ProjectsGridRowData[],
  projectWatch: Project | undefined,
) => {
  return currentProjectAndRoleListFromDatasource
    .filter((data) => data.project.name === projectWatch?.name)
    .filter((data) => data.role?.id !== reassignProjectAndRole?.role?.id)
    .map((data) => {
      if (data.role?.person) {
        if (data.role.roleFullDisplayName === reassignProjectAndRole?.role?.roleFullDisplayName) {
          return {
            roleOptionLabel: `${data.role.roleAbbreviation} - ${data.role.person.name?.lastCommaFirst} (${data.role.startDate} - ${data.role.endDate})`,
            role: data.role as ProjectRole,
            rank: 2,
          };
        } else {
          return {
            roleOptionLabel: `${data.role.roleAbbreviation} - ${data.role.person.name?.lastCommaFirst} (${data.role.startDate} - ${data.role.endDate})`,
            role: data.role as ProjectRole,
            rank: 4,
          };
        }
      } else {
        if (data.role?.roleFullDisplayName === reassignProjectAndRole?.role?.roleFullDisplayName) {
          return {
            roleOptionLabel: `${data.role?.roleAbbreviation} - Unassigned (${data.role?.startDate} - ${data.role?.endDate})`,
            role: data.role as ProjectRole,
            rank: 1,
          };
        } else {
          return {
            roleOptionLabel: `${data.role?.roleAbbreviation} - Unassigned (${data.role?.startDate} - ${data.role?.endDate})`,
            role: data.role as ProjectRole,
            rank: 3,
          };
        }
      }
    })
    .sort((a, b) => {
      if (a.rank === b.rank) {
        return a.role.roleFullDisplayName &&
          b.role.roleFullDisplayName &&
          a.role.roleFullDisplayName < b.role.roleFullDisplayName
          ? -1
          : 1;
      } else {
        return a.rank < b.rank ? -1 : 1;
      }
    });
};

const createUniqueListOfProjects = (currentProjectAndRoleListFromDatasource: ProjectsGridRowData[]) => {
  const filteredList = new Map(
    currentProjectAndRoleListFromDatasource.map((data: ProjectsGridRowData) => [
      data.project.name,
      data.project as Project,
    ]),
  );
  const filteredListValues: Project[] = [...filteredList.values()];
  return filteredListValues;
};

const ReassignPersonnel: FC<ReassignRoleProps> = ({
  control,
  currentProjectAndRoleListFromDatasource,
  setValue,
  reassignProjectAndRole,
  endDateWatch,
  projectWatch,
}) => {
  const [rolesList, setRolesList] = useState<ReassignRoleListType[]>(
    filterAndSortRoleList(reassignProjectAndRole, currentProjectAndRoleListFromDatasource, projectWatch),
  );

  const [projectList, setProjectList] = useState<Project[]>(
    createUniqueListOfProjects(currentProjectAndRoleListFromDatasource),
  );

  const shouldDisableDate = (date?: DateTime) => {
    return (endDateWatch && date && date > endDateWatch) ?? false;
  };

  const shouldDisableMonth = (date?: DateTime) => {
    const monthOfDate = date?.month;
    const yearOfDate = date?.year;

    const monthOfEndDateToCheckAgainst = endDateWatch?.month;
    const yearOfEndDateToCheckAgainst = endDateWatch?.year;

    if (
      endDateWatch &&
      date &&
      monthOfDate &&
      yearOfDate &&
      monthOfEndDateToCheckAgainst &&
      yearOfEndDateToCheckAgainst
    ) {
      return monthOfDate > monthOfEndDateToCheckAgainst && yearOfDate > yearOfEndDateToCheckAgainst;
    } else {
      return false;
    }
  };

  const shouldDisableYear = (date?: DateTime) => {
    const yearOfDate = date?.year;

    const yearOfEndDateToCheckAgainst = endDateWatch?.year;
    if (endDateWatch && date && yearOfDate && yearOfEndDateToCheckAgainst) {
      return yearOfDate > yearOfEndDateToCheckAgainst;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setRolesList(filterAndSortRoleList(reassignProjectAndRole, currentProjectAndRoleListFromDatasource, projectWatch));
    setProjectList(createUniqueListOfProjects(currentProjectAndRoleListFromDatasource));
  }, [currentProjectAndRoleListFromDatasource, projectWatch, reassignProjectAndRole]);

  useEffect(() => {
    const startDateValue =
      DateTime.fromISO(reassignProjectAndRole?.role?.startDate) < DateTime.now()
        ? DateTime.now()
        : DateTime.fromISO(reassignProjectAndRole?.role?.startDate);

    setValue('startDate', startDateValue);
    setValue('endDate', DateTime.fromISO(reassignProjectAndRole?.role?.endDate));
  }, [reassignProjectAndRole?.role?.endDate, reassignProjectAndRole?.role?.startDate, setValue]);

  return (
    <>
      <Grid container sx={reassignRoleHeaderContainer}>
        <Grid item xs={12}>
          <Typography align={'center'} variant="h5">
            {reassignProjectAndRole?.role?.person?.name?.lastCommaFirst ?? 'Unfilled'}'s Assignment
          </Typography>
        </Grid>
      </Grid>
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Project
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="project"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="projects"
                    size="small"
                    options={projectList}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      setValue('project', newValue ?? undefined);
                      if (newValue) {
                        setValue('startDate', DateTime.fromISO(newValue.startDate));
                        setValue('endDate', DateTime.fromISO(newValue.endDate));
                        setRolesList(
                          filterAndSortRoleList(
                            reassignProjectAndRole,
                            currentProjectAndRoleListFromDatasource,
                            projectWatch,
                          ),
                        );
                      }
                    }}
                    value={value}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Role
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="roles"
                    size="small"
                    options={rolesList}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.roleOptionLabel}
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      setValue('role', newValue?.role);
                    }}
                    value={rolesList.find((role) => role.role?.id === value?.id) ?? null}
                    isOptionEqualToValue={(option, value) => {
                      return option?.role?.id === value?.role?.id;
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.role?.id}>
                          {option.roleOptionLabel}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Start Date
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Grid item>
                    <DatePicker
                      shouldDisableDate={shouldDisableDate}
                      shouldDisableMonth={shouldDisableMonth}
                      shouldDisableYear={shouldDisableYear}
                      value={value}
                      onChange={(date: DateTime | null) => {
                        onChange(date);
                        setValue('startDate', date);
                      }}
                      format="D"
                      slotProps={{
                        actionBar: {
                          actions: ['clear', 'cancel', 'today'],
                        },
                        textField: { fullWidth: true },
                      }}
                    />
                  </Grid>
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              End Date
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledDatePicker
                    value={value}
                    onChange={(newDate) => {
                      onChange(newDate);
                      setValue('endDate', newDate);
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ReassignPersonnel;
