export const PROJECT_GRID_ALL_ROLES_ID = 'ProjectsGrid';
export const PROJECT_GRID_UNFILLED_ROLES_ID = 'ProjectsUnfilledGrid';

export const PERSONNEL_GRID_ID = 'PersonnelGrid';
export const PERSONNEL_UNASSIGNED_GRID_ID = 'PersonnelUnassignedGrid';

export const CONSULTANT_GRID_ID = 'ConsultantGrid';

export const PROJECT_GANTT_ID = 'ProjectsGantt';
export const PERSONNEL_GANTT_ID = 'PersonnelGantt';
