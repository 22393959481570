import { Grid, SxProps, Theme } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledTable from 'components/shared/Table';
import { VISTA_SOURCE } from 'constants/tooltips';
import { DateTime } from 'luxon';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC } from 'react';
import { GetSinglePersonQuery } from 'types/generated/graphql';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const leftAlignedHeaderStyle: SxProps<Theme> = {
  '& span': {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
};

type PersonnelPayrollProjectProps = {
  personnel?: GetSinglePersonQuery['person'];
};

export const PersonnelPayrollProject: FC<PersonnelPayrollProjectProps> = ({ personnel }) => {
  const personnelPayrollProjectData = personnel?.prProjectHistory ?? [];

  const sortedPayrollProjectData = [...personnelPayrollProjectData]?.sort((a, b) =>
    DateTime.fromISO(a.lastDateOnJob) > DateTime.fromISO(b.lastDateOnJob) ? -1 : 1,
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'job',
      label: 'Job Number',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'jobName',
      label: 'Job Name',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'description',
      label: 'Last Title On Project',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'lastDateOnJob',
      label: 'Last Date',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    pagination: false,
    sortOrder: {
      name: 'lastDateOnJob',
      direction: 'desc',
    },
  };

  return (
    <>
      <Grid container sx={containerStyle} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledTable
            title={<HelpTooltip tooltipText={VISTA_SOURCE} titleText="Payroll Project Info" />}
            columns={columns}
            data={sortedPayrollProjectData ?? []}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonnelPayrollProject;
