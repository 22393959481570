import { Button, SxProps, Theme } from '@mui/material';
import React from 'react';

const buttonStyle: SxProps<Theme> = (theme: Theme) => ({
  borderRadius: 16,
  minWidth: theme.spacing(10),
  '&:hover': { backgroundColor: 'none !important' },
});

type StyledButtonSecondaryProps = {
  label: string | React.ReactNode;
  color?: 'inherit' | 'primary' | 'secondary';
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  icon?: React.ReactNode;
};

type Props = StyledButtonSecondaryProps & Record<string, any>;

const StyledButtonSecondary = React.forwardRef<HTMLButtonElement, Props>(
  ({ label, onClick, className, icon, ...rest }, ref) => {
    return (
      <Button
        tabIndex={-1}
        ref={ref}
        data-testid="button-secondary"
        onClick={onClick}
        sx={buttonStyle}
        startIcon={icon ?? undefined}
        {...rest}
      >
        {label}
      </Button>
    );
  },
);

export default StyledButtonSecondary;
