import { Grid, SxProps, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(1),
});

type StepProps = {
  title: string;
  children: React.ReactNode;
};

const StyledStep: FC<StepProps> = ({ title, children }) => {
  return (
    <Grid container direction="column">
      <Grid item sx={containerStyle}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {children}
    </Grid>
  );
};

export default StyledStep;
