import { Grid, SxProps, Theme } from '@mui/material';
import { personnelIsConsultant } from 'constants/consultant';
import { FC } from 'react';
import { Person } from 'types/generated/graphql';
import PersonnelTrainingsCard from '../Certifications/PersonnelCertificationsCard';
import PersonnelEmploymentHistoryCard from '../PersonnelEmploymentHistoryCard';
import PersonnelPayrollProject from '../PersonnelPayrollProject';
import PersonnelProjectAssignments from '../PersonnelProjectAssignments';
import PersonnelProjectHistoryCard from '../PersonnelProjectHistoryCard';

type PersonnelScrollingMiddleSectionProps = {
  personnel: Person;
};

const PersonnelScrollingMiddleSectionStyles: SxProps<Theme> = {
  maxHeight: '90vh',
  overflowY: 'auto',
  display: 'flex',
  position: 'sticky',
};

export const PersonnelScrollingMiddleSection: FC<PersonnelScrollingMiddleSectionProps> = ({ personnel }) => {
  return (
    <Grid item xs={7} sx={PersonnelScrollingMiddleSectionStyles}>
      <Grid container>
        <Grid item xs={12}>
          <PersonnelProjectAssignments personnel={personnel} />
        </Grid>
        <Grid item xs={12}>
          <PersonnelPayrollProject personnel={personnel} />
        </Grid>
        {!personnelIsConsultant(personnel) && (
          <Grid item xs={12}>
            <PersonnelProjectHistoryCard personnel={personnel} />
          </Grid>
        )}
        <Grid item xs={12}>
          <PersonnelEmploymentHistoryCard personnel={personnel} />
        </Grid>
        {!personnelIsConsultant(personnel) && (
          <Grid item xs={12}>
            <PersonnelTrainingsCard personnel={personnel} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PersonnelScrollingMiddleSection;
