import { Autocomplete, Checkbox, Grid, InputLabel, SxProps, TextField, Theme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import HelpTooltip from 'components/shared/HelpTooltip';
import { personnelIsConsultant } from 'constants/consultant';
import { PERSONNEL_STATUS } from 'constants/personnelStatus';
import { STATES } from 'constants/states';
import { UKG_SOURCE, USER_INPUT_SOURCE } from 'constants/tooltips';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { MuiTelInput } from 'mui-tel-input';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Person, useUpdatePersonMutation } from 'types/generated/graphql';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

const justifyContentSpaceBetweenStyles: SxProps<Theme> = {
  display: 'flex',
};

type LeftSectionEditableConsultantFieldsFormProps = {
  setIsEditing: (x: boolean) => void;
  personnel: Person;
};

type PersonnelFormValues = {
  isActive?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  emailAddress?: string;
  notes?: string;
  notesFlag?: boolean;
  preferredName?: string;
  company?: string;
  anticipatedSeparationDate?: string | null;
  city?: string;
  state?: string;
  zip?: string;
};

export const LeftSectionEditableConsultantFieldsForm: FC<LeftSectionEditableConsultantFieldsFormProps> = ({
  setIsEditing,
  personnel,
}) => {
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [updatePerson, { loading: isLoading }] = useUpdatePersonMutation({});

  const defaultValues = {
    isActive: personnel.isActive ?? '',
    primaryPhone: personnel.contactInfo?.primaryPhone?.number ?? '',
    secondaryPhone: personnel.contactInfo?.secondaryPhone?.number ?? '',
    emailAddress: personnel.contactInfo?.personalEmailAddress?.email ?? '',
    notes: personnel.notes ?? '',
    notesFlag: personnel.notesFlag ?? false,
    preferredName: personnel.name?.preferred ?? '',
    company: personnel.company ?? '',
    anticipatedSeparationDate: personnel.anticipatedSeparationDate ?? null,
    city: personnel.location?.city ?? '',
    state: personnel.location?.stateOrProvince ?? '',
    zip: personnel.location?.zipOrPostalCode ?? '',
  };

  const { handleSubmit, formState, control, reset } = useForm<PersonnelFormValues>({ defaultValues });
  const { isDirty } = formState;

  const handleClose = () => {
    setIsEditing(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  const onSubmit: SubmitHandler<PersonnelFormValues> = (data) => {
    updatePerson({
      variables: {
        input: {
          transactionKey,
          id: personnel.id,
          isActive: data.isActive ?? '',
          anticipatedSeparationDate: DateTime.fromISO(data.anticipatedSeparationDate ?? '').toISODate() ?? null,
          location: {
            city: data.city ?? '',
            stateOrProvince: data.state ?? '',
            zipOrPostalCode: data.zip ?? '',
          },
          contactInfo: {
            primaryPhone: {
              number: data.primaryPhone ?? '',
            },
            secondaryPhone: {
              number: data.secondaryPhone ?? '',
            },
            personalEmailAddress: {
              email: data.emailAddress ?? '',
            },
          },
          notes: data.notes ?? null,
          notesFlag: data.notesFlag ?? false,
          name: {
            preferred: data.preferredName ?? '',
          },
          company: data.company ?? '',
        },
      },
    })
      .then(() => {
        displayToast('The personnel was updated successfully', 'success');
        setIsEditing(false);
      })
      .catch((error: any) => {
        console.error('Update personnel failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to update the personnel. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {personnelIsConsultant(personnel) && (
        <>
          <Grid item xs={12}>
            <Controller
              name="isActive"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Status" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        disableClearable={false}
                        id="isActive"
                        options={PERSONNEL_STATUS}
                        filterSelectedOptions
                        onChange={(_event, newValue) => onChange(newValue)}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        value={value || null}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="City" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="state"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="State" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        disableClearable={false}
                        id="state"
                        options={STATES}
                        filterSelectedOptions
                        onChange={(_event, newValue) => onChange(newValue)}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        value={value || null}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="zip"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Zip" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="primaryPhone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Cell Number" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiTelInput
                        defaultCountry="US"
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disableDropdown
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="secondaryPhone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Phone Number" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiTelInput
                        defaultCountry="US"
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disableDropdown
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="emailAddress"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Email" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          {!personnel.name?.preferred && (
            <Grid item xs={12}>
              <Controller
                name="preferredName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Grid container sx={justifyContentSpaceBetweenStyles}>
                      <Grid item xs={6}>
                        <InputLabel>
                          <HelpTooltip tooltipText={UKG_SOURCE} titleText="Preferred Name" />
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          multiline
                          onChange={onChange}
                          value={value}
                          variant="outlined"
                          fullWidth
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="company"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Company" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="anticipatedSeparationDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Anticipated Separation Date" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        value={value ? convertUTCDateToLocalDate(value) : null}
                        onChange={(date: DateTime | null) => onChange(date)}
                        format="D"
                        slotProps={{
                          actionBar: {
                            actions: ['clear', 'cancel', 'today'],
                          },
                          textField: { size: 'small' },
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="notesFlag"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Notes Flag" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="notes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Notes" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField multiline onChange={onChange} value={value} variant="outlined" fullWidth />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
        </>
      )}

      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center" direction="row" spacing={1} justifyContent="flex-end">
            <Grid item>
              <StyledButtonSecondary label="Cancel" onClick={handleClose} disabled={isLoading} />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                disabled={!isDirty || isLoading}
                type="submit"
                label="Submit"
                onSubmit={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
