import { Grid, SxProps, Theme } from '@mui/material';
import { personnelIsConsultant } from 'constants/consultant';
import { FC, useState } from 'react';
import { Person } from 'types/generated/graphql';
import { LeftSectionEditableFields } from './LeftSectionEditableFields';
import { LeftSectionNonEditableFields } from './LeftSectionNonEditableFields';
import { LeftSectionHeader } from './PersonnelStaticLeftSectionHeader';

const displayFlexStyles: SxProps<Theme> = {
  display: 'flex',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'sticky',
};

type PersonnelStaticLeftSectionProps = {
  personnel: Person;
  isOnlyCraft: boolean;
};

export const PersonnelStaticLeftSection: FC<PersonnelStaticLeftSectionProps> = ({ personnel, isOnlyCraft }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <Grid item xs={3} sx={displayFlexStyles}>
      <Grid container rowSpacing={1}>
        <LeftSectionHeader
          name={personnel?.name?.lastCommaFirst ?? ''}
          title={personnel?.prJobTitle ?? ''}
          setIsEditing={setIsEditing}
          personnel={personnel}
          isOnlyCraft={isOnlyCraft}
        />
        <LeftSectionNonEditableFields isEditing={isEditing} setIsEditing={setIsEditing} personnel={personnel} />
        {isEditing && personnelIsConsultant(personnel) ? null : (
          <LeftSectionEditableFields isEditing={isEditing} setIsEditing={setIsEditing} personnel={personnel} />
        )}
      </Grid>
    </Grid>
  );
};

export default PersonnelStaticLeftSection;
