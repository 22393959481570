// hpRed & Variants = '#a6192e';
export const hpRedLightened = '#dd5157';
export const hpRedLightest = '#f0c9d0';
export const hpRedDarkened = '#700006';

// hpYellow & Variants = '#f2a900';
export const hpYellowLightened = '#ffda4a';
export const hpYellowLightest = '#f5d898';
export const hpYellowDarkened = '#ba7a00';

// App-wide
export const brightWhite = '#ffffff';
export const trueBlack = '#000000';
export const transparent = 'rgba(0,0,0,0)';
export const mediumDarkGray = '#564e4e';

// RGBAs
export const overlayLight = 'rgba(219,219,219,.7)';
export const overlayDark = 'rgba(0, 0, 0,.9)';
export const overlayGray = 'rgba(33, 33, 33,.7)';
export const overlayHpRed = 'rgba(112, 0, 6,0.9)';
export const overlayHpYellow = 'rgba(242, 169, 0, 0.95)';
