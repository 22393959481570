import { FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import { FC, Fragment } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { ConsultantFormValues, formHelperErrorTextStyle } from './CreateConsultantDialog';

type ConsultantTextFieldProps = {
  label: string;
  formControlValue: string;
  xsSize: number;
  isRequired: boolean;
  setValue: UseFormSetValue<ConsultantFormValues>;
  control: Control<ConsultantFormValues, any>;
};

export const ConsultantTextField: FC<ConsultantTextFieldProps> = ({
  label,
  formControlValue,
  xsSize,
  isRequired,
  setValue,
  control,
}) => {
  return (
    <Grid item xs={xsSize}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Fragment>
            <TextField
              error={isRequired ? !value : false}
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              onChange={(newValue) => {
                onChange(newValue.target.value);
                setValue(formControlValue as keyof ConsultantFormValues, newValue.target.value ?? '');
              }}
              value={value}
              type={'string'}
            />
            {isRequired && !value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
          </Fragment>
        )}
        name={formControlValue as keyof ConsultantFormValues}
        control={control}
      />
    </Grid>
  );
};
