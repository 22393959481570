import { Client, ResponseType } from '@microsoft/microsoft-graph-client';

const getAuthenticatedClient = async (accessToken: string) => {
  return Client.init({
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });
};

const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (_) => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
};

export const getMyPhoto = async (accessToken: string | undefined) => {
  if (!accessToken) {
    return;
  }
  const client = await getAuthenticatedClient(accessToken);
  const response = (await client.api(`/me/photo/$value`).responseType(ResponseType.RAW).get()) as Response;
  if (response.status === 404 || !response.ok) {
    return;
  }
  const base64Image = await blobToBase64(await response.blob());
  return base64Image;
};

export const getMe = async (accessToken: string | undefined) => {
  if (!accessToken) {
    return null;
  }
  const client = await getAuthenticatedClient(accessToken);
  const user = await client.api('/me').version('beta').get();
  return user;
};

export const getAdUserInfo = async (accessToken: string | null) => {
  if (!accessToken) {
    return null;
  }
  const me = await getMe(accessToken);
  const displayName = me.displayName;
  const userPhoto = await getMyPhoto(accessToken);
  const homeDistrict = Number.parseInt(me.extension_08cfd51d0e4a41128e563e87bd341c37_sPDistrictNumber);
  const id = me.id;
  return { displayName, userPhoto, homeDistrict, id };
};

const userQuery = async (path: string, filter = '', client: Client) => {
  const result = await client.api(path).version('beta').count(true).top(50).filter(filter).get();
  return result;
};

export const getAdUserByHpEmployeeNumber = async (accessToken: string | undefined, hpEmployeeNumber: number) => {
  if (!accessToken) {
    return null;
  }
  const client = await getAuthenticatedClient(accessToken);
  const matchingUsers: any[] = await userQuery(
    '/users',
    `extension_08cfd51d0e4a41128e563e87bd341c37_hPemployeeNumber eq ${hpEmployeeNumber}`,
    client,
  ).then((result) => result.value);

  return matchingUsers?.[0];
};

export const getAdUserImage = async (accessToken: string | null, upn: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  const thumbnailUrl: any = await fetch(`https://graph.microsoft.com/v1.0/users/${upn}/photo/$value`, options)
    .then(function (response: any) {
      if (response.status !== 200) {
        throw new Error();
      } else {
        return response.blob();
      }
    })
    .then((blob: Blob | MediaSource) => {
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch(() => {});

  return thumbnailUrl;
};
