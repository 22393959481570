import Consultants from 'pages/ConsultantsPage/Consultants';
import Personnel from 'pages/PersonnelPage';
import PersonnelDetailsPage from 'pages/PersonnelPage/PersonnelDetailsPage';
import ProjectDetails from 'pages/ProjectDetails';
import Projects from 'pages/ProjectsPage';
import Reporting from 'pages/Reporting';
import StaffingPlan from 'pages/StaffingPlan';
// TODO: Implement lazy loading! https://reactjs.org/docs/code-splitting.html

type AppRoute = {
  exact: boolean;
  Component: React.FC;
  path: string;
};

export const allRoutes: AppRoute[] = [
  {
    exact: true,
    Component: Reporting,
    path: '/reporting',
  },
  {
    exact: true,
    Component: Projects,
    path: '/projects',
  },
  {
    exact: true,
    Component: ProjectDetails,
    path: '/projects/:projectId',
  },
  {
    exact: true,
    Component: Personnel,
    path: '/personnel',
  },
  {
    exact: true,
    Component: PersonnelDetailsPage,
    path: '/personnel/:personnelId',
  },
  {
    exact: true,
    Component: Consultants,
    path: '/consultants',
  },
  {
    exact: true,
    Component: StaffingPlan,
    path: '/staffing-plan',
  },
];
