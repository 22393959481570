import { cloneDeep } from '@apollo/client/utilities';
import { DateTime } from 'luxon';
import { PersonTraining } from 'types/generated/graphql';

export const getTrainingDisplayName = (name: string, otherName: string | null | undefined) => {
  if (otherName && name === 'Other') {
    return otherName;
  }
  return name;
};

// helper function to determine if the training is expired
export const getRowStatus = (expirationDate: string) => {
  if (expirationDate === null || expirationDate === 'Never Expires') {
    return {
      isExpired: false,
      isExpiringSoon: false,
      numberOfDaysUntilExpiration: null,
    };
  }

  const todaysDate = DateTime.now().toISODate() ?? '';
  const formattedExpirationDate = DateTime.fromISO(expirationDate).toISODate() ?? '';

  const isExpired = formattedExpirationDate <= todaysDate;

  const numberOfDaysUntilExpiration = Math.ceil(
    DateTime.fromISO(formattedExpirationDate).diff(DateTime.now(), 'days').toObject().days ?? 0,
  );

  const isExpiringSoon = numberOfDaysUntilExpiration < 30 && numberOfDaysUntilExpiration > 0;

  return {
    isExpired,
    isExpiringSoon,
    numberOfDaysUntilExpiration,
  };
};

// helper function to get row classname based on status
export const getStatusClassName = (expirationDate: string, classes: any) => {
  const rowStatus = getRowStatus(expirationDate);

  if (rowStatus.isExpired) {
    return classes.expiredRow;
  } else if (rowStatus.isExpiringSoon) {
    return classes.expiringSoon;
  }
  return;
};

// helper function to pre-format table data to match column structure in order to make sorting easier
export const getColumnFormattedTrainingData = (trainingData: PersonTraining[]) => {
  const personnelCopy = cloneDeep(trainingData ?? []);
  if (personnelCopy.length > 0) {
    return personnelCopy
      .map((training: PersonTraining) => {
        const displayName = getTrainingDisplayName(training.name, training.otherName);
        const otherName = training.otherName;
        const name = training.name === 'Other' ? otherName : training.name.replace(/([a-z])([A-Z])/g, '$1 $2');
        const status = getRowStatus(training.expirationDate);
        const statusLabel = status.isExpired
          ? 'Expired'
          : !status.isExpired && !status.isExpiringSoon
          ? 'Current'
          : status.isExpiringSoon
          ? 'Expiring Soon'
          : '';
        const expirationDate = training.expirationDate === null ? 'Never Expires' : training.expirationDate;

        return {
          id: training.id,
          name,
          displayName,
          otherName,
          awardedDate: training.awardedDate,
          expirationDate,
          status,
          statusLabel,
          files: training.files,
        };
      })
      .sort((a, b) => {
        if (a.statusLabel === b.statusLabel) {
          return a.awardedDate < b.awardedDate ? -1 : 1;
        } else {
          return a.statusLabel < b.statusLabel ? -1 : 1;
        }
      });
  } else {
    return personnelCopy;
  }
};

export const openTrainingDialog = async (
  rowData: any,
  setPersonnelTrainingData: any,
  toggleTrainingDialog: (x: boolean) => void,
  setTrainingDialogView: (x: any) => void,
  actionType: string,
) => {
  if (rowData.length > 0) {
    const [id, status, name, awardedDate, expirationDate, , files, fileObjects] = rowData;

    const chooseExpirationDate = () => {
      if (expirationDate === 'Never Expires') {
        return null;
      }
      return expirationDate;
    };
    setPersonnelTrainingData({
      id,
      name: name,
      awardedDate: awardedDate,
      expirationDate: chooseExpirationDate(),
      status: status,
      files: files,
      updatedFiles: fileObjects,
    });
  } else {
    setPersonnelTrainingData(null);
  }
  setTrainingDialogView(actionType);
  toggleTrainingDialog(true);
};

export const closeTrainingDialog = (
  toggleTrainingDialog: (x: boolean) => void,
  setTrainingDialogView: (x: string | null | undefined) => void,
) => {
  toggleTrainingDialog(false);
  setTrainingDialogView(null);
};
