import { Role, ROLES_BY_FULL_DISPLAY_NAME } from 'constants/roles';

// Sorts by role rank, then alphabetically by abbreviation
export const roleSort = (a: Role | null | undefined, b: Role | null | undefined) => {
  const aRank = a?.rank ?? (a ? Number.MAX_SAFE_INTEGER : 0);
  const bRank = b?.rank ?? (b ? Number.MAX_SAFE_INTEGER : 0);
  const rankDifference = aRank - bRank;
  if (rankDifference === 0) {
    return (a?.abbreviation ?? '').localeCompare(b?.abbreviation ?? '');
  } else {
    return rankDifference;
  }
};

export const roleFullDisplayNameSortComparer = (a: any, b: any) => {
  if (!a) {
    a = '';
  }
  if (!b) {
    b = '';
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return roleSort(ROLES_BY_FULL_DISPLAY_NAME[a], ROLES_BY_FULL_DISPLAY_NAME[b]);
  } else {
    console.log(`Unexpected type while sorting roles. (${a}, ${b})`);
    return 0;
  }
};
