export const DISTRICT_NUMBERS = [
  '100',
  '200',
  '250',
  '300',
  '350',
  '400',
  '500',
  '590',
  '600',
  '650',
  '700',
  '710',
  '800',
  '850',
  '900',
];

interface District {
  number: string;
  name: string;
}
export const DISTRICTS: District[] = [
  { number: '100', name: 'Corporate' },
  { number: '200', name: 'Mid Atlantic' },
  { number: '250', name: 'Mid South' },
  { number: '300', name: 'Rocky Mountain' },
  { number: '350', name: 'Water' },
  { number: '400', name: 'SoCal' },
  { number: '500', name: 'NorCal' },
  { number: '590', name: 'Southwest' },
  { number: '600', name: 'Pacific' },
  { number: '650', name: 'PNW' },
  { number: '700', name: 'Southcentral' },
  { number: '710', name: 'Services' },
  { number: '800', name: 'Southeast' },
  { number: '850', name: 'MCIS' },
  { number: '900', name: 'Development' },
];
