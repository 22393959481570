import React from 'react';

import { Grid } from '@mui/material';

import ButtonPrimary from '../ButtonPrimary';

type StyledButtonGroupProps = {
  iconAltAction?: React.ReactNode;
  onClickAltAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  labelAltAction: string;
  disabledAltAction?: any;
  iconMainAction?: React.ReactNode;
  onClickMainAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  labelMainAction: string;
  disabledMainAction?: any;
};

const StyledButtonGroup: React.FC<StyledButtonGroupProps & Record<string, any>> = ({
  iconAltAction,
  onClickAltAction,
  labelAltAction,
  disabledAltAction,
  iconMainAction,
  onClickMainAction,
  labelMainAction,
  disabledMainAction,
}) => {
  if (iconAltAction && iconMainAction) {
    return (
      <Grid container justifyContent="flex-end" direction="row" spacing={1}>
        <Grid item>
          <ButtonPrimary
            variant="contained"
            color="primary"
            icon={iconAltAction}
            onClick={onClickAltAction}
            label={labelAltAction}
            disabled={disabledAltAction}
          />
        </Grid>
        <Grid item>
          <ButtonPrimary
            variant="contained"
            color="primary"
            icon={iconMainAction}
            onClick={onClickMainAction}
            label={labelMainAction}
            disabled={disabledMainAction}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="flex-end" direction="row" spacing={1}>
        <Grid item>
          <ButtonPrimary
            variant="contained"
            color="primary"
            onClick={onClickAltAction}
            label={labelAltAction}
            disabled={disabledAltAction}
          />
        </Grid>
        <Grid item>
          <ButtonPrimary
            variant="contained"
            color="primary"
            onClick={onClickMainAction}
            label={labelMainAction}
            disabled={disabledMainAction}
          />
        </Grid>
      </Grid>
    );
  }
};

export default StyledButtonGroup;
