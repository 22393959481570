import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { LEAVE_PROJECT_ID } from 'constants/leaveProject';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { ReassignRoleAssignmentsType, ReassignRoleFormValues, ReassignRoleSummaryReassignmentsType } from 'types';
import {
  Person,
  Project,
  ProjectRoleUpsertInput,
  usePersonListResultQuery,
  useUpsertProjectRolesMutation,
} from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { Divider, Grid, Typography } from '@mui/material';

import ButtonGroup from '../shared/ButtonGroup';
import ReassignPersonnel from './ReassignPersonnel';
import ReassignRole from './ReassignRole';
import { reassignPersonnelStep } from './ReassignStepFunctions.ts/ReassignPersonnelStep';
import { reassignRoleStep } from './ReassignStepFunctions.ts/ReassignRoleStep';
import { reassignToAnotherRoleStep } from './ReassignStepFunctions.ts/ReassignToAnotherRoleStep';
import { reassignToLeaveOrNewRoleStep } from './ReassignStepFunctions.ts/ReassignToLeaveOrNewRoleStep';
import ReassignSummary from './ReassignSummary';
import ReassignToAnotherRole from './ReassignToAnotherRole';
import ReassignToLeaveOrNewRole from './ReassignToLeaveOrNewRole';
import ReassignToOldRole from './ReassignToOldRole';
import { createUnfilledProjectRoleAndAddToReassignmentSummary } from './reassignUtils';

type ReassignRoleDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  dataSource: ProjectsGridRowData[];
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  setReassignProjectAndRole: (x: ProjectsGridRowData) => void;
  startingReassignmentProjectAndRole: ProjectsGridRowData | undefined;
  startingStepIndex: number;
  startingPersonnel?: Person;
  selectedEmployeeType: string | null;
  onDataChanged?: () => Promise<void>;
  selectedDistricts?: string[];
};

export const REASSIGN_ROLE_STEP_INDEX = 0;
export const REASSIGN_PERSONNEL_STEP_INDEX = 1;
export const REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX = 2;
export const REASSIGN_TO_OLD_ROLE_STEP_INDEX = 3;
export const REASSIGN_SUMMARY_STEP_INDEX = 4;
export const REASSIGN_TO_LEAVE_OR_NEW_ROLE_STEP_INDEX = 5;

export const ASSIGN_TO_AN_EXISTING_ROLE_BUTTON_TEXT = 'Assign to an existing role';
export const ASSIGN_TO_A_NEW_ROLE_BUTTON_TEXT = 'Assign to a new role';
export const LEAVE_AVAILABLE_BUTTON_TEXT = 'Leave Available';

const ReassignRoleDialog: FC<ReassignRoleDialogProps> = ({
  isOpen,
  setIsOpen,
  dataSource,
  reassignProjectAndRole,
  setReassignProjectAndRole,
  startingReassignmentProjectAndRole,
  startingStepIndex,
  startingPersonnel,
  selectedEmployeeType,
  onDataChanged,
  selectedDistricts,
}) => {
  const { displayToast } = useToast();
  const [isCancelConfirmationRendered, setIsCancelConfirmationRendered] = useState(false);
  const [activeStepIndex, setActiveStep] = useState(startingStepIndex);
  const [radioButtonSelection, setRadioButtonSelection] = useState('');
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [currentProjectAndRoleListFromDatasource, setCurrentProjectAndRoleListFromDatasource] = useState<
    ProjectsGridRowData[]
  >(JSON.parse(JSON.stringify(dataSource)));
  const [currentPersonnelList, setCurrentPersonnelList] = useState<Person[]>();
  const [reassignRoleAssignments, setReassignRoleAssignments] = useState<ReassignRoleAssignmentsType[]>([]);
  const [reassignRoleSummaryReassignments, setReassignRoleSummaryReassignments] =
    useState<ReassignRoleSummaryReassignmentsType>({});
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [reassignmentProjectRoleMutations, setReassignmentProjectRoleMutations] = useState<ProjectRoleUpsertInput[]>(
    [],
  );
  const [shouldShowAfterLeaveDropdown, setShouldShowAfterLeaveDropdown] = useState<boolean>(false);
  const [addProjectRoles, { loading: isLoading }] = useUpsertProjectRolesMutation();

  const defaultValues: ReassignRoleFormValues = {
    project: (reassignProjectAndRole?.project as Project) ?? undefined,
    leaveNewRoleProject:
      (dataSource.find((data) => data.project.id === LEAVE_PROJECT_ID)?.project as Project) ?? undefined,
    role: undefined,
    personnel: { person: undefined, role: undefined },
    payrollTitle: '',
    startDate: DateTime.now(),
    endDate: DateTime.now(),
    afterLeave: '',
    reassignFromCurrentRole: '',
  };

  const { data: personnelData, refetch } = usePersonListResultQuery({
    fetchPolicy: 'no-cache',
    variables: {
      isOnlyCraft: selectedEmployeeType === 'Craft',
      isActive: ['Active', 'Awaiting Hire'],
      districts: selectedDistricts,
    },
  });
  const personnelListForReassignmentDropdown = useMemo(
    () => personnelData?.personListResult.items ?? [],
    [personnelData],
  );

  const reassignRoleDialogRefetch = async () => {
    if (onDataChanged) {
      await Promise.all([onDataChanged(), refetch()]);
    }
  };

  const { handleSubmit, reset, setValue, control } = useForm({ defaultValues });

  const [
    projectWatch,
    leaveNewRoleProjectWatch,
    roleWatch,
    personnelWatch,
    payrollTitleWatch,
    startDateWatch,
    endDateWatch,
    reassignFromCurrentRoleWatch,
    afterLeaveWatch,
  ] = useWatch({
    control,
    name: [
      'project',
      'leaveNewRoleProject',
      'role',
      'personnel',
      'payrollTitle',
      'startDate',
      'endDate',
      'reassignFromCurrentRole',
      'afterLeave',
    ],
  });

  const callAddProjectRoles = () => {
    addProjectRoles({ variables: { input: reassignmentProjectRoleMutations } })
      .then(() => {
        handleClose();
        reassignRoleDialogRefetch();
        displayToast('The project roles were added successfully', 'success');
      })
      .catch(() => {
        handleClose();
        displayToast(
          'Error: Something went wrong while trying to add the project roles. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const onSubmitSuccess: SubmitHandler<ReassignRoleFormValues> = () => {
    callAddProjectRoles();
  };

  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
    setActiveStep(startingStepIndex);
    setIsCancelConfirmationRendered(false);
    setReassignRoleAssignments([]);
    setReassignRoleSummaryReassignments({});
    setReassignmentProjectRoleMutations([]);
    setTransactionKey(generateTransactionKey());
  };

  const handleShouldClose = () => {
    if (activeStepIndex < REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX) {
      handleClose();
    } else {
      setIsCancelConfirmationRendered(true);
    }
  };

  const handleNext = () => {
    if (activeStepIndex === REASSIGN_PERSONNEL_STEP_INDEX) {
      reassignPersonnelStep({
        projectWatch,
        reassignProjectAndRole,
        roleWatch,
        startDateWatch,
        endDateWatch,
        transactionKey,
        dataSource,
        reassignRoleSummaryReassignments,
        reassignmentProjectRoleMutations,
        defaultValues,
        setReassignRoleSummaryReassignments,
        setTransactionKey,
        setReassignmentProjectRoleMutations,
        setReassignProjectAndRole,
        setActiveStep,
        reset,
      });
    } else if (activeStepIndex === REASSIGN_ROLE_STEP_INDEX) {
      reassignRoleStep({
        reassignProjectAndRole,
        personnelWatch,
        reassignFromCurrentRoleWatch,
        startDateWatch,
        endDateWatch,
        reassignRoleSummaryReassignments,
        reassignmentProjectRoleMutations,
        transactionKey,
        defaultValues,
        dataSource,
        startingReassignmentProjectAndRole,
        setReassignRoleSummaryReassignments,
        setTransactionKey,
        setReassignmentProjectRoleMutations,
        setReassignProjectAndRole,
        setActiveStep,
        reset,
      });
    } else if (activeStepIndex === REASSIGN_TO_LEAVE_OR_NEW_ROLE_STEP_INDEX) {
      reassignToLeaveOrNewRoleStep({
        afterLeaveWatch,
        payrollTitleWatch,
        startDateWatch,
        endDateWatch,
        reassignProjectAndRole,
        transactionKey,
        leaveNewRoleProjectWatch,
        startingPersonnel,
        reassignRoleSummaryReassignments,
        reassignmentProjectRoleMutations,
        dataSource,
        startingReassignmentProjectAndRole,
        defaultValues,
        setReassignRoleSummaryReassignments,
        setTransactionKey,
        setReassignmentProjectRoleMutations,
        setReassignProjectAndRole,
        setActiveStep,
        reset,
      });
    } else if (activeStepIndex === REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX) {
      reassignToAnotherRoleStep({
        radioButtonSelection,
        setActiveStep,
        setReassignProjectAndRole,
        reassignProjectAndRole,
        setRadioButtonSelection,
      });
    }
  };

  useEffect(() => {
    setCurrentProjectAndRoleListFromDatasource(JSON.parse(JSON.stringify(dataSource)));
  }, [dataSource]);

  useEffect(() => {
    setCurrentPersonnelList(JSON.parse(JSON.stringify(personnelListForReassignmentDropdown)));
  }, [personnelListForReassignmentDropdown]);

  useEffect(() => {
    if (activeStepIndex === REASSIGN_ROLE_STEP_INDEX) {
      if (personnelWatch.role) {
        startDateWatch && endDateWatch && personnelWatch.person && reassignFromCurrentRoleWatch
          ? setCanContinue(true)
          : setCanContinue(false);
      } else {
        startDateWatch && endDateWatch && personnelWatch.person ? setCanContinue(true) : setCanContinue(false);
      }
    } else if (activeStepIndex === REASSIGN_PERSONNEL_STEP_INDEX) {
      startDateWatch && endDateWatch && projectWatch && roleWatch ? setCanContinue(true) : setCanContinue(false);
    } else if (activeStepIndex === REASSIGN_TO_LEAVE_OR_NEW_ROLE_STEP_INDEX) {
      if (!shouldShowAfterLeaveDropdown) {
        startDateWatch && endDateWatch && leaveNewRoleProjectWatch && payrollTitleWatch
          ? setCanContinue(true)
          : setCanContinue(false);
      } else {
        startDateWatch && endDateWatch && leaveNewRoleProjectWatch && payrollTitleWatch && afterLeaveWatch
          ? setCanContinue(true)
          : setCanContinue(false);
      }
    } else if (activeStepIndex === REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX) {
      radioButtonSelection ? setCanContinue(true) : setCanContinue(false);
    }
  }, [
    activeStepIndex,
    afterLeaveWatch,
    endDateWatch,
    payrollTitleWatch,
    personnelWatch,
    projectWatch,
    leaveNewRoleProjectWatch,
    radioButtonSelection,
    reassignFromCurrentRoleWatch,
    roleWatch,
    startDateWatch,
    shouldShowAfterLeaveDropdown,
  ]);

  return (
    <StyledDialog
      maxWidth={'xs'}
      title={!isCancelConfirmationRendered ? 'Personnel Reassignment' : 'Cancel Personnel Reassignment'}
      content={
        <form>
          {isCancelConfirmationRendered && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>{'Are you sure you want to cancel? All information will be lost.'}</Typography>
              </Grid>
              <Grid item>
                <ButtonGroup
                  onClickAltAction={() => setIsCancelConfirmationRendered(false)}
                  labelAltAction={'Back'}
                  onClickMainAction={() => {
                    handleClose();
                  }}
                  labelMainAction={'Discard'}
                />
              </Grid>
            </Grid>
          )}
          {!isCancelConfirmationRendered && (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                {activeStepIndex === REASSIGN_ROLE_STEP_INDEX && (
                  <ReassignRole
                    control={control}
                    reassignProjectAndRole={reassignProjectAndRole}
                    setValue={setValue}
                    personnelList={currentPersonnelList || ([] as Person[])}
                    personnelWatch={personnelWatch}
                    startDateWatch={startDateWatch}
                    endDateWatch={endDateWatch}
                  />
                )}
                {activeStepIndex === REASSIGN_PERSONNEL_STEP_INDEX && (
                  <ReassignPersonnel
                    control={control}
                    currentProjectAndRoleListFromDatasource={currentProjectAndRoleListFromDatasource}
                    reassignProjectAndRole={reassignProjectAndRole}
                    setValue={setValue}
                    endDateWatch={endDateWatch}
                    projectWatch={projectWatch}
                  />
                )}
                {activeStepIndex === REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX && (
                  <ReassignToAnotherRole
                    reassignProjectAndRole={reassignProjectAndRole}
                    setRadioButtonSelection={setRadioButtonSelection}
                  />
                )}
                {activeStepIndex === REASSIGN_TO_LEAVE_OR_NEW_ROLE_STEP_INDEX && (
                  <ReassignToLeaveOrNewRole
                    control={control}
                    currentProjectAndRoleListFromDatasource={currentProjectAndRoleListFromDatasource}
                    reassignProjectAndRole={reassignProjectAndRole}
                    setValue={setValue}
                    reassignRoleAssignments={reassignRoleAssignments}
                    startDateWatch={startDateWatch}
                    endDateWatch={endDateWatch}
                    startingPersonnel={startingPersonnel as Person}
                    leaveNewRoleProjectWatch={leaveNewRoleProjectWatch}
                    shouldShowAfterLeaveDropdown={shouldShowAfterLeaveDropdown}
                    setShouldShowAfterLeaveDropdown={setShouldShowAfterLeaveDropdown}
                    selectedEmployeeType={selectedEmployeeType}
                  />
                )}
                {activeStepIndex === REASSIGN_TO_OLD_ROLE_STEP_INDEX && (
                  <ReassignToOldRole startingReassignmentProjectAndRole={reassignProjectAndRole} />
                )}
                {activeStepIndex === REASSIGN_SUMMARY_STEP_INDEX && (
                  <ReassignSummary
                    reassignRoleAssignments={reassignRoleAssignments}
                    reassignRoleSummaryReassignments={reassignRoleSummaryReassignments}
                  />
                )}
                {activeStepIndex !== REASSIGN_SUMMARY_STEP_INDEX && (
                  <>
                    <Divider />
                    <Typography component="div">
                      <ReassignSummary
                        reassignRoleAssignments={reassignRoleAssignments}
                        reassignRoleSummaryReassignments={reassignRoleSummaryReassignments}
                      />
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </form>
      }
      actions={
        !isCancelConfirmationRendered && (
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <StyledButtonSecondary disabled={isLoading} label={'Cancel'} onClick={handleShouldClose} />
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end" direction="row" spacing={2}>
                    <Grid item>
                      {activeStepIndex === REASSIGN_TO_OLD_ROLE_STEP_INDEX && (
                        <ButtonGroup
                          onClickAltAction={() => {
                            setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
                            createUnfilledProjectRoleAndAddToReassignmentSummary({
                              reassignProjectAndRole,
                              startDateWatch,
                              transactionKey,
                              setTransactionKey,
                              reassignmentProjectRoleMutations,
                              setReassignmentProjectRoleMutations,
                              reassignRoleSummaryReassignments,
                              setReassignRoleSummaryReassignments,
                            });
                          }}
                          labelAltAction={'No'}
                          onClickMainAction={() => {
                            setActiveStep(REASSIGN_ROLE_STEP_INDEX);
                          }}
                          labelMainAction={'Yes'}
                        />
                      )}
                      {activeStepIndex !== REASSIGN_TO_OLD_ROLE_STEP_INDEX &&
                        activeStepIndex !== REASSIGN_SUMMARY_STEP_INDEX && (
                          <StyledButtonPrimary label={'Next'} disabled={!canContinue} onClick={handleNext} />
                        )}
                      {activeStepIndex !== REASSIGN_TO_OLD_ROLE_STEP_INDEX &&
                        activeStepIndex === REASSIGN_SUMMARY_STEP_INDEX && (
                          <StyledButtonPrimary
                            disabled={isLoading}
                            label={'Finalize'}
                            onClick={handleSubmit(onSubmitSuccess)}
                          />
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleShouldClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default ReassignRoleDialog;
