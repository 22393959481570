import { Grid, Link, SxProps, Theme, Typography } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledTable from 'components/shared/Table';
import { PEOPLE360_SOURCE } from 'constants/tooltips';
import { DateTime } from 'luxon';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC } from 'react';
import { GetSinglePersonQuery, Project } from 'types/generated/graphql';
import { formatDateWithOffset } from 'utils/general';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const leftAlignedHeaderStyle: SxProps<Theme> = {
  '& span': {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
};

const conflictingProjectDatesStyle: SxProps<Theme> = {
  color: 'red',
};

type PersonnelProjectAssignmentsProps = {
  personnel?: GetSinglePersonQuery['person'];
};

export const PersonnelProjectAssignments: FC<PersonnelProjectAssignmentsProps> = ({ personnel }) => {
  const personnelProjectRoles =
    personnel?.roles.filter((role) => DateTime.fromISO(role.project.endDate) > DateTime.now()) ?? [];

  const conflictingStartDates = (
    personnelProjectRoles: any[],
    projectInRow: { __typename?: 'Project' | undefined } & Pick<
      Project,
      'id' | 'districts' | 'verticalMarkets' | 'startDate' | 'endDate' | 'name' | 'contractType'
    >,
    startDate: DateTime,
  ) => {
    return personnelProjectRoles
      ?.filter((projectRole) => projectRole.project.id !== projectInRow?.id)
      .find((projectRole) => {
        return (
          (startDate > DateTime.fromISO(projectRole.startDate) && startDate < DateTime.fromISO(projectRole.endDate)) ||
          DateTime.fromISO(projectRole.startDate).equals(startDate) ||
          DateTime.fromISO(projectRole.endDate).equals(startDate)
        );
      })
      ? true
      : false;
  };

  const conflictingEndDates = (
    personnelProjectRoles: any[],
    projectInRow: { __typename?: 'Project' | undefined } & Pick<
      Project,
      'id' | 'districts' | 'verticalMarkets' | 'startDate' | 'endDate' | 'name' | 'contractType'
    >,
    endDate: DateTime,
  ) => {
    return personnelProjectRoles
      ?.filter((projectRole) => projectRole.project.id !== projectInRow?.id)
      .find((projectRole) => {
        return (
          (endDate > DateTime.fromISO(projectRole.startDate) && endDate < DateTime.fromISO(projectRole.endDate)) ||
          DateTime.fromISO(projectRole.startDate).equals(endDate) ||
          DateTime.fromISO(projectRole.endDate).equals(endDate)
        );
      })
      ? true
      : false;
  };
  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        searchable: true,
        display: 'excluded',
      },
    },
    {
      name: 'project.name',
      label: 'Project',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <Link href={`/projects/${personnelProjectRoles[dataIndex].id}`} target="_blank">
              <Typography noWrap>{personnelProjectRoles[dataIndex].project.name}</Typography>
            </Link>
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'roleName',
      label: 'Role',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{personnelProjectRoles[dataIndex].roleName ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'project.districts',
      label: 'Region',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          const districtString = personnelProjectRoles[dataIndex].project.districts.join(', ');
          return <Typography>{districtString ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'project.verticalMarkets',
      label: 'Vertical Markets',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          const verticalMarketString = personnelProjectRoles[dataIndex].project.verticalMarkets.join(', ');
          return <Typography>{verticalMarketString ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },

    {
      name: 'project.contractType',
      label: 'Contract Types',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          const contractType = personnelProjectRoles[dataIndex].project.contractType;
          return <Typography>{contractType ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'startDate',
      label: 'Start Date on Project',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        sortDescFirst: true,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          const projectInRow = personnelProjectRoles[dataIndex].project;
          const startDate = DateTime.fromISO(personnelProjectRoles[dataIndex].startDate);
          const conflictingStartDate = conflictingStartDates(personnelProjectRoles, projectInRow, startDate);
          return (
            personnelProjectRoles[dataIndex].startDate && (
              <Typography sx={conflictingStartDate ? { color: 'red' } : null}>
                {formatDateWithOffset(personnelProjectRoles[dataIndex].startDate ?? '')}
              </Typography>
            )
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'endDate',
      label: 'End Date on Project',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          const projectInRow = personnelProjectRoles[dataIndex].project;
          const endDate = DateTime.fromISO(personnelProjectRoles[dataIndex].endDate);
          const conflictingEndDate = conflictingEndDates(personnelProjectRoles, projectInRow, endDate);
          return (
            personnelProjectRoles[dataIndex].endDate && (
              <Typography sx={conflictingEndDate ? conflictingProjectDatesStyle : null}>
                {formatDateWithOffset(personnelProjectRoles[dataIndex].endDate ?? '')}
              </Typography>
            )
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    pagination: false,
    sortOrder: {
      name: 'endDate',
      direction: 'desc',
    },
  };

  return (
    <>
      <Grid container sx={containerStyle}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledTable
            title={<HelpTooltip tooltipText={PEOPLE360_SOURCE} titleText="Project Assignments" />}
            columns={columns}
            data={personnelProjectRoles ?? []}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonnelProjectAssignments;
