import { useApolloClient } from '@apollo/client';
import { Box, Checkbox, FormHelperText, Grid, InputLabel, SxProps, TextField, Theme } from '@mui/material';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { DISTRICTS } from 'constants/districts';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useUpdatePersonMutation } from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';
import { ConsultantAutoComplete } from './ConsultantAutoComplete';
import { ConsultantTextField } from './ConsultantTextField';

type CreateConsultantDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
};

export type ConsultantFormValues = {
  firstName: string;
  lastName: string;
  status: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  cellNumber: string;
  phoneNumber: string;
  email: string;
  company: string;
  notes: string;
  notesFlag: boolean;
  region: string;
  anticipatedSeparationDate?: DateTime;
};

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1),
});

const formStyle: SxProps<Theme> = {
  width: '100%',
};

const inputLabelStyle: SxProps<Theme> = () => ({
  marginBottom: '10px',
});

const formRowStyle: SxProps<Theme> = {
  flexDirection: 'row',
  display: 'flex',
};

export const formHelperErrorTextStyle: SxProps<Theme> = {
  color: '#cd0000',
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginLeft: '14px',
};

const CreateConsultantDialog: FC<CreateConsultantDialogProps> = ({ isOpen, setIsOpen }) => {
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const apolloClient = useApolloClient();
  const [updatePerson, { loading: isLoading }] = useUpdatePersonMutation();
  const defaultValues = {
    firstName: '',
    lastName: '',
    status: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    cellNumber: '',
    phoneNumber: '',
    email: '',
    company: '',
    notes: '',
    notesFlag: false,
    region: '',
    anticipatedSeparationDate: undefined,
  };
  const { handleSubmit, control, reset, setValue } = useForm<ConsultantFormValues>({ defaultValues });

  const [firstName, lastName, email, status, region, anticipatedSeparationDate] = useWatch({
    control,
    name: ['firstName', 'lastName', 'email', 'status', 'region', 'anticipatedSeparationDate'],
  });

  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  const onSubmit: SubmitHandler<ConsultantFormValues> = (data) => {
    const firstIndex = 0;
    const region = data.region.split(' - ')[firstIndex] ?? '';
    updatePerson({
      variables: {
        input: {
          transactionKey,
          isActive: data.status ?? '',
          prDistrict: region ?? '',
          prJobTitle: 'Consultant',
          contactInfo: {
            personalEmailAddress: {
              email: data.email ?? '',
              type: 'Personal',
            },
            primaryPhone: {
              number: data.cellNumber ?? '',
              type: 'Cell Phone',
            },
            secondaryPhone: {
              number: data.phoneNumber ?? '',
              type: 'Home Phone',
            },
          },
          location: {
            streetAndNumber: data.address ?? '',
            city: data.city ?? '',
            stateOrProvince: data.state ?? '',
            zipOrPostalCode: data.zip ?? '',
          },
          name: {
            firstName: data.firstName ?? '',
            lastName: data.lastName ?? '',
          },
          anticipatedSeparationDate: data.anticipatedSeparationDate?.toISODate() ?? null,
          notes: data.notes ?? '',
          notesFlag: data.notesFlag ?? false,
          company: data.company ?? '',
        },
      },
    })
      .then(() => {
        apolloClient.reFetchObservableQueries();
        setTransactionKey(generateTransactionKey());
        displayToast('The consultant was added successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to add a consultant. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  useEffect(() => {
    firstName && lastName && email && status && region && anticipatedSeparationDate?.isValid
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [firstName, lastName, email, status, region, anticipatedSeparationDate]);

  return (
    <StyledDialog
      title={'Create Consultant'}
      isLoading={isLoading}
      content={
        <Grid container sx={containerStyle}>
          <Box component="form" sx={formStyle}>
            <Grid container spacing={3} sx={formRowStyle}>
              <ConsultantTextField
                label="First Name"
                formControlValue="firstName"
                xsSize={4}
                isRequired={true}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="Last Name"
                formControlValue="lastName"
                xsSize={4}
                isRequired={true}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="Cell Number"
                formControlValue="cellNumber"
                xsSize={2}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="Phone Number"
                formControlValue="phoneNumber"
                xsSize={2}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
            </Grid>
            <Grid container spacing={3} sx={formRowStyle}>
              <ConsultantTextField
                label="Address"
                formControlValue="address"
                xsSize={4}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="City"
                formControlValue="city"
                xsSize={4}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="State"
                formControlValue="state"
                xsSize={2}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="Zip Code"
                formControlValue="zip"
                xsSize={2}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
            </Grid>
            <Grid container spacing={3} sx={formRowStyle}>
              <ConsultantTextField
                label="Email"
                formControlValue="email"
                xsSize={4}
                isRequired={true}
                setValue={setValue}
                control={control}
              />
              <ConsultantTextField
                label="Notes"
                formControlValue="notes"
                xsSize={6}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
              <Grid item xs={2}>
                <InputLabel>Notes Flag </InputLabel>
                <Controller
                  name="notesFlag"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={formRowStyle}>
              <ConsultantTextField
                label="Company"
                formControlValue="company"
                xsSize={3}
                isRequired={false}
                setValue={setValue}
                control={control}
              />
              <ConsultantAutoComplete
                label="Status"
                formControlValue="status"
                xsSize={3}
                sxStyle={inputLabelStyle}
                isRequired={true}
                options={['Active', 'Inactive']}
                setValue={setValue}
                control={control}
              />
              <ConsultantAutoComplete
                label="Region"
                formControlValue="region"
                xsSize={3}
                sxStyle={inputLabelStyle}
                isRequired={true}
                options={DISTRICTS.map((district) => {
                  return `${district.number} - ${district.name}`;
                })}
                setValue={setValue}
                control={control}
              />
              <Grid item xs={3}>
                <InputLabel>Anticipated Separation Date</InputLabel>
                <Controller
                  render={({ field: { onChange } }) => (
                    <Fragment>
                      <TextField
                        error={!anticipatedSeparationDate?.isValid}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        onChange={(event) => {
                          const value = event.target.value;
                          onChange(value);
                          setValue('anticipatedSeparationDate', DateTime.fromISO(value));
                        }}
                        type={'date'}
                      />
                      {!anticipatedSeparationDate?.isValid && (
                        <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                      )}
                    </Fragment>
                  )}
                  name="anticipatedSeparationDate"
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      }
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'Submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!canSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default CreateConsultantDialog;
