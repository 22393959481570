import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { DateTime } from 'luxon';
import { UseFormReset } from 'react-hook-form';
import { ReassignRoleFormValues, ReassignRoleSummaryReassignmentsType, reassignmentType } from 'types';
import { Person, Project, ProjectRole, ProjectRoleUpsertInput } from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';
import {
  REASSIGN_SUMMARY_STEP_INDEX,
  REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX,
  REASSIGN_TO_OLD_ROLE_STEP_INDEX,
} from '../ReassignRoleDialog';
import {
  createNewAssignment,
  createNewReassignment,
  createPreservedRoles,
  getNewRoleUpsertInput,
  getRoleFullDisplayNameFromPersonnelWatch,
  getRoleUpsertInput,
} from '../reassignUtils';

type LeaveExistingRolesWithOverlapOnlyRoleToReassignIsNotActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  newReassignment: reassignmentType;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const leaveExistingRolesWithOverlapOnlyRoleToReassignIsNotActive = ({
  reassignProjectAndRole,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  newReassignment,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: LeaveExistingRolesWithOverlapOnlyRoleToReassignIsNotActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, newReassignment]
        : [newReassignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type LeaveExistingRolesWithOverlapOnlyRoleToReassignIsActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  dateTimeNow: DateTime;
  transactionKey: string;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  newReassignment: reassignmentType;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
};

const leaveExistingRolesWithOverlapOnlyRoleToReassignIsActive = ({
  reassignProjectAndRole,
  dateTimeNow,
  transactionKey,
  reassignRoleSummaryReassignments,
  newReassignment,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  reassignmentProjectRoleMutations,
  setTransactionKey,
  setReassignRoleSummaryReassignments,
  setReassignmentProjectRoleMutations,
}: LeaveExistingRolesWithOverlapOnlyRoleToReassignIsActiveTypeParams) => {
  const preserveProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    transactionKey,
    projectId: reassignProjectAndRole?.role?.id,
  });

  setTransactionKey(generateTransactionKey());

  const newPreserveRole = createPreservedRoles({
    projectName: reassignProjectAndRole?.project?.name,
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, newReassignment]
        : [newReassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRole]
        : [newPreserveRole],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    preserveProjectRoleUpsert,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);
};

type LeaveExistingRolesWithNoOverLapRoleToReassignIsActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  dateTimeNow: DateTime;
  transactionKey: string;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  newReassignment: reassignmentType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
};

const leaveExistingRolesWithNoOverLapRoleToReassignIsActive = ({
  reassignProjectAndRole,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  dateTimeNow,
  transactionKey,
  reassignRoleSummaryReassignments,
  newReassignment,
  reassignmentProjectRoleMutations,
  setTransactionKey,
  setReassignRoleSummaryReassignments,
  setReassignmentProjectRoleMutations,
}: LeaveExistingRolesWithNoOverLapRoleToReassignIsActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const preserveHistoryProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    transactionKey,
    projectId: reassignProjectAndRole?.project?.id,
  });

  setTransactionKey(generateTransactionKey());

  const newPreserveRole = createPreservedRoles({
    projectName: reassignProjectAndRole?.project?.name,
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, newReassignment]
        : [newReassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRole]
        : [newPreserveRole],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
    preserveHistoryProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);
};

type LeaveExistingRolesWithNoOverLapRoleToReassignIsNotActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  newReassignment: reassignmentType;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const leaveExistingRolesWithNoOverLapRoleToReassignIsNotActive = ({
  reassignProjectAndRole,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  newReassignment,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: LeaveExistingRolesWithNoOverLapRoleToReassignIsNotActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, newReassignment]
        : [newReassignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type ReassignRoleWithAvailablePersonnelRoleToReassignIsActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  dateTimeNow: DateTime;
  transactionKey: string;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
};

const reassignRoleWithAvailablePersonnelRoleToReassignIsActive = ({
  reassignProjectAndRole,
  dateTimeNow,
  transactionKey,
  reassignRoleSummaryReassignments,
  reassignment,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  reassignmentProjectRoleMutations,
  setTransactionKey,
  setReassignRoleSummaryReassignments,
  setReassignmentProjectRoleMutations,
}: ReassignRoleWithAvailablePersonnelRoleToReassignIsActiveTypeParams) => {
  //Preserve the history
  const preserveProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    transactionKey,
    projectId: reassignProjectAndRole?.project?.id,
  });

  setTransactionKey(generateTransactionKey());

  const newPreserveRole = createPreservedRoles({
    projectName: reassignProjectAndRole?.project?.name,
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRole]
        : [newPreserveRole],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    preserveProjectRoleUpsert,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);
};

type ReassignRoleWithAvailablePersonnelRoleToReassignIsNotActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const reassignRoleWithAvailablePersonnelRoleToReassignIsNotActive = ({
  reassignProjectAndRole,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  reassignment,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: ReassignRoleWithAvailablePersonnelRoleToReassignIsNotActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type ReassignFromCurrentRoleWithOnlyRoleToReassignIsNotActiveTypeParams = {
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignProjectAndRole?: ProjectsGridRowData;
  transactionKey: string;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const reassignFromCurrentRoleWithOnlyRoleToReassignIsNotActive = ({
  personnelWatch,
  reassignProjectAndRole,
  transactionKey,
  startDateWatch,
  endDateWatch,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  reassignment,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: ReassignFromCurrentRoleWithOnlyRoleToReassignIsNotActiveTypeParams) => {
  // Preserve  Person A's role
  // Person A's role end date now has Role B's start Date
  const preserveProjectRoleUpsert = getRoleUpsertInput({
    roleName: personnelWatch?.role?.roleName,
    startDate: personnelWatch?.role?.startDate,
    endDate: reassignProjectAndRole?.role?.startDate,
    personId: personnelWatch?.person?.id,
    projectId: personnelWatch?.role?.project?.id,
    id: personnelWatch?.role?.id,
  });

  // Create new Role on Person'A old project that is unfilled. Start on Role B's start date and ends on Role A end Date
  const newUnfilledProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: personnelWatch?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: personnelWatch?.role?.endDate,
    personId: null,
    transactionKey,
    projectId: personnelWatch?.role?.project?.id,
  });

  setTransactionKey(generateTransactionKey());

  // Assign Person A to Role B
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    preserveProjectRoleUpsert,
    newUnfilledProjectRoleUpsert,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const newAssignment = createNewAssignment({
    projectName: personnelWatch?.role?.project?.name,
    personnel: null,
    roleName: getRoleFullDisplayNameFromPersonnelWatch(personnelWatch),
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: personnelWatch?.role?.endDate,
  });

  const newPreserveRole = createPreservedRoles({
    projectName: personnelWatch?.role?.project?.name,
    personnel: personnelWatch?.person?.name?.lastCommaFirst,
    roleName: getRoleFullDisplayNameFromPersonnelWatch(personnelWatch),
    startDate: personnelWatch?.role?.startDate,
    endDate: reassignProjectAndRole?.role?.startDate,
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRole]
        : [newPreserveRole],
    newAssignments:
      reassignRoleSummaryReassignments.newAssignments && reassignRoleSummaryReassignments.newAssignments.length > 0
        ? [...reassignRoleSummaryReassignments.newAssignments, newAssignment]
        : [newAssignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type ReassignFromCurrentRoleWithBothRolesActiveTypeParams = {
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  dateTimeNow: DateTime;
  reassignProjectAndRole?: ProjectsGridRowData;
  transactionKey: string;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const reassignFromCurrentRoleWithBothRolesActive = ({
  personnelWatch,
  dateTimeNow,
  reassignProjectAndRole,
  transactionKey,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  reassignment,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: ReassignFromCurrentRoleWithBothRolesActiveTypeParams) => {
  // Preserve Person A's and B's role
  // Role A now ends at dateTimeNow
  const preserveRoleAProjectRoleUpsert = getRoleUpsertInput({
    roleName: personnelWatch?.role?.roleName,
    startDate: personnelWatch?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: personnelWatch?.role?.project?.id,
    id: personnelWatch?.role?.id,
  });

  // Role B now ends at dateTimeNow
  const preserveRoleBProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  // new Role with Role B's role name starts at dateTimeNow and ends when Role B ended with Person A
  const newfilledRoleCProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: dateTimeNow?.toISODate(),
    endDate: reassignProjectAndRole?.role?.endDate,
    personId: personnelWatch?.person?.id,
    transactionKey,
    projectId: reassignProjectAndRole?.project?.id,
  });
  setTransactionKey(generateTransactionKey());

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    preserveRoleAProjectRoleUpsert,
    preserveRoleBProjectRoleUpsert,
    newfilledRoleCProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const newPreserveRoleA = createPreservedRoles({
    projectName: personnelWatch?.role?.project?.name,
    personnel: personnelWatch?.person?.name?.lastCommaFirst,
    roleName: getRoleFullDisplayNameFromPersonnelWatch(personnelWatch),
    startDate: personnelWatch?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const newPreserveRoleB = createPreservedRoles({
    projectName: reassignProjectAndRole?.project?.name,
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRoleA, newPreserveRoleB]
        : [newPreserveRoleA, newPreserveRoleB],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type ReassignFromCurrentRoleWithOnlyRoleToReassignIsActiveTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  dateTimeNow: DateTime;
  transactionKey: string;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const reassignFromCurrentRoleWithOnlyRoleToReassignIsActive = ({
  reassignProjectAndRole,
  startDateWatch,
  endDateWatch,
  personnelWatch,
  dateTimeNow,
  transactionKey,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  reassignment,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: ReassignFromCurrentRoleWithOnlyRoleToReassignIsActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: personnelWatch?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const preserveProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    transactionKey,
    projectId: reassignProjectAndRole?.project?.id,
  });

  setTransactionKey(generateTransactionKey());

  const oldProjectRoleUpsert = getRoleUpsertInput({
    roleName: personnelWatch?.role?.roleName,
    startDate: personnelWatch?.role?.startDate,
    endDate: personnelWatch?.role?.endDate,
    personId: null,
    projectId: personnelWatch?.role?.project?.id,
    id: personnelWatch?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
    preserveProjectRoleUpsert,
    oldProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const newPreserveRole = createPreservedRoles({
    projectName: reassignProjectAndRole?.project?.name,
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRole]
        : [newPreserveRole],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

// This step is the reassign role step (Thing 1)
// The purpose of this step is to assign a new personnel to a role
// To enter this step you must click on the edit icon on the project grid page next to the personnel name assigned to the role
// or when making a choice from the ReassignToOldRole step.
// First the reassignment is made. This will be made up of the personnel you have selected to be on that role and the start and end dates
// that may be set for that role.
// The user can select if the personnel you are moving onto this role should keep their existing role or be moved off that role
// If you wish to keep their existing roles the role you are editing just has the person, start and end dates changed. This will cause a
// double booking
// If you wish to move them off their existing role. The role you are editing has the person, start and end dates changed and the person you
// are moving onto that role has their role set as unfilled

type ReassignRoleStepTypeParams = {
  reassignProjectAndRole?: ProjectsGridRowData;
  personnelWatch: {
    person?: Person;
    role?: ProjectRole;
  };
  reassignFromCurrentRoleWatch: string;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  transactionKey: string;
  defaultValues: ReassignRoleFormValues;
  dataSource: ProjectsGridRowData[];
  startingReassignmentProjectAndRole?: ProjectsGridRowData;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignProjectAndRole: (x: ProjectsGridRowData) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  reset: UseFormReset<ReassignRoleFormValues>;
};

export const reassignRoleStep = ({
  reassignProjectAndRole,
  personnelWatch,
  reassignFromCurrentRoleWatch,
  startDateWatch,
  endDateWatch,
  reassignRoleSummaryReassignments,
  reassignmentProjectRoleMutations,
  transactionKey,
  defaultValues,
  dataSource,
  startingReassignmentProjectAndRole,
  setReassignRoleSummaryReassignments,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignProjectAndRole,
  setActiveStep,
  reset,
}: ReassignRoleStepTypeParams) => {
  const dateTimeNow = DateTime.now();
  const rolesOverlap =
    reassignProjectAndRole?.role?.startDate < personnelWatch?.role?.endDate &&
    personnelWatch?.role?.startDate < reassignProjectAndRole?.role?.endDate;
  const roleToMoveOffOfIsActive =
    dateTimeNow > DateTime.fromISO(personnelWatch?.role?.startDate) &&
    dateTimeNow < DateTime.fromISO(personnelWatch?.role?.endDate);
  const roleToReassignIsActive =
    dateTimeNow > DateTime.fromISO(reassignProjectAndRole?.role?.startDate) &&
    dateTimeNow < DateTime.fromISO(reassignProjectAndRole?.role?.endDate);

  if (reassignFromCurrentRoleWatch === 'Leave existing roles unchanged') {
    const newReassignment = createNewReassignment({
      projectName: reassignProjectAndRole?.project?.name,
      roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
      personnelBeingReassigned: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
      personnelReassignedToRole: personnelWatch?.person?.name?.lastCommaFirst,
      personnelReassignedToRoleStartDate: startDateWatch?.toISODate(),
      personnelReassignedToRoleEndDate: endDateWatch?.toISODate(),
      notes: 'Double Booked',
    });

    if (rolesOverlap) {
      if (roleToMoveOffOfIsActive && !roleToReassignIsActive) {
        leaveExistingRolesWithOverlapOnlyRoleToReassignIsNotActive({
          reassignProjectAndRole,
          startDateWatch,
          endDateWatch,
          personnelWatch,
          reassignmentProjectRoleMutations,
          reassignRoleSummaryReassignments,
          newReassignment,
          setReassignmentProjectRoleMutations,
          setReassignRoleSummaryReassignments,
        });
      } else {
        leaveExistingRolesWithOverlapOnlyRoleToReassignIsActive({
          reassignProjectAndRole,
          dateTimeNow,
          transactionKey,
          reassignRoleSummaryReassignments,
          newReassignment,
          startDateWatch,
          endDateWatch,
          personnelWatch,
          reassignmentProjectRoleMutations,
          setTransactionKey,
          setReassignRoleSummaryReassignments,
          setReassignmentProjectRoleMutations,
        });
      }
    } else {
      // IF the user is leaving the existing roles unchanged, meaning to double book, but both roles are active and overlap, we need to preserve the history of the role
      // being reassigned as a new role with the original start date, person, role, and project but with the end date as datetime now.
      if (roleToReassignIsActive) {
        leaveExistingRolesWithNoOverLapRoleToReassignIsActive({
          reassignProjectAndRole,
          startDateWatch,
          endDateWatch,
          personnelWatch,
          dateTimeNow,
          transactionKey,
          reassignRoleSummaryReassignments,
          newReassignment,
          reassignmentProjectRoleMutations,
          setTransactionKey,
          setReassignRoleSummaryReassignments,
          setReassignmentProjectRoleMutations,
        });
      } else {
        leaveExistingRolesWithNoOverLapRoleToReassignIsNotActive({
          reassignProjectAndRole,
          startDateWatch,
          endDateWatch,
          personnelWatch,
          reassignmentProjectRoleMutations,
          reassignRoleSummaryReassignments,
          newReassignment,
          setReassignmentProjectRoleMutations,
          setReassignRoleSummaryReassignments,
        });
      }
    }
    if (startingReassignmentProjectAndRole?.role?.person) {
      if (
        reassignRoleSummaryReassignments.addToLeave &&
        reassignRoleSummaryReassignments?.addToLeave?.find(
          (leave) =>
            leave.personnel === startingReassignmentProjectAndRole.role?.person?.name?.lastCommaFirst &&
            leave.notes === 'Will be available after leave',
        )
      ) {
        setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
      } else {
        setReassignProjectAndRole({
          project: startingReassignmentProjectAndRole.project as Project,
          role: {
            ...startingReassignmentProjectAndRole.role,
            startDate: startDateWatch?.toISODate(),
            endDate: endDateWatch?.toISODate(),
          } as ProjectRole,
        });
        setActiveStep(REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX);
      }
    } else {
      setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
    }
    reset(defaultValues);
  }
  //If the user wants to reassign from a personnel that is available
  else if (reassignFromCurrentRoleWatch === '') {
    const reassignment = createNewReassignment({
      projectName: reassignProjectAndRole?.project?.name,
      roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
      personnelBeingReassigned: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
      personnelReassignedToRole: personnelWatch?.person?.name?.lastCommaFirst,
      personnelReassignedToRoleStartDate: startDateWatch?.toISODate(),
      personnelReassignedToRoleEndDate: endDateWatch?.toISODate(),
    });

    if (roleToReassignIsActive && reassignProjectAndRole?.role?.person) {
      reassignRoleWithAvailablePersonnelRoleToReassignIsActive({
        reassignProjectAndRole,
        dateTimeNow,
        transactionKey,
        reassignRoleSummaryReassignments,
        reassignment,
        startDateWatch,
        endDateWatch,
        personnelWatch,
        reassignmentProjectRoleMutations,
        setTransactionKey,
        setReassignRoleSummaryReassignments,
        setReassignmentProjectRoleMutations,
      });
    } else if (!roleToReassignIsActive || !reassignProjectAndRole?.role?.person) {
      reassignRoleWithAvailablePersonnelRoleToReassignIsNotActive({
        reassignProjectAndRole,
        startDateWatch,
        endDateWatch,
        personnelWatch,
        reassignmentProjectRoleMutations,
        reassignRoleSummaryReassignments,
        reassignment,
        setReassignmentProjectRoleMutations,
        setReassignRoleSummaryReassignments,
      });
    }

    if (startingReassignmentProjectAndRole?.role?.person) {
      if (
        reassignRoleSummaryReassignments.addToLeave &&
        reassignRoleSummaryReassignments?.addToLeave?.find(
          (leave) =>
            leave.personnel === startingReassignmentProjectAndRole.role?.person?.name?.lastCommaFirst &&
            leave.notes === 'Will be available after leave',
        )
      ) {
        setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
      } else {
        setReassignProjectAndRole({
          project: startingReassignmentProjectAndRole?.project as Project,
          role: startingReassignmentProjectAndRole.role as ProjectRole,
        });
        setActiveStep(REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX);
      }
    } else {
      setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
    }
    reset(defaultValues);
  }
  // If the user wants to reassign from current role
  else {
    const reassignment = createNewReassignment({
      projectName: reassignProjectAndRole?.project?.name,
      roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
      personnelBeingReassigned: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
      personnelReassignedToRole: personnelWatch?.person?.name?.lastCommaFirst,
      personnelReassignedToRoleStartDate: startDateWatch?.toISODate(),
      personnelReassignedToRoleEndDate: endDateWatch?.toISODate(),
    });

    const projectAndRole = dataSource.find((data) => data.role?.id === personnelWatch.role?.id);

    //  If the current date falls into Person A's start and end date and not Person B's start and end date
    if (roleToMoveOffOfIsActive && !roleToReassignIsActive) {
      reassignFromCurrentRoleWithOnlyRoleToReassignIsNotActive({
        personnelWatch,
        reassignProjectAndRole,
        transactionKey,
        startDateWatch,
        endDateWatch,
        reassignmentProjectRoleMutations,
        reassignRoleSummaryReassignments,
        reassignment,
        setTransactionKey,
        setReassignmentProjectRoleMutations,
        setReassignRoleSummaryReassignments,
      });
    }
    //  If the current date falls into Person A's and B's start and end date
    else if (roleToMoveOffOfIsActive && roleToReassignIsActive) {
      reassignFromCurrentRoleWithBothRolesActive({
        personnelWatch,
        dateTimeNow,
        reassignProjectAndRole,
        transactionKey,
        reassignmentProjectRoleMutations,
        reassignRoleSummaryReassignments,
        reassignment,
        setTransactionKey,
        setReassignmentProjectRoleMutations,
        setReassignRoleSummaryReassignments,
      });
    } else if (!roleToMoveOffOfIsActive && roleToReassignIsActive) {
      reassignFromCurrentRoleWithOnlyRoleToReassignIsActive({
        reassignProjectAndRole,
        startDateWatch,
        endDateWatch,
        personnelWatch,
        dateTimeNow,
        transactionKey,
        reassignmentProjectRoleMutations,
        reassignRoleSummaryReassignments,
        reassignment,
        setTransactionKey,
        setReassignmentProjectRoleMutations,
        setReassignRoleSummaryReassignments,
      });
    }

    setReassignProjectAndRole({
      project: projectAndRole?.project as Project,
      role: {
        ...projectAndRole?.role,
        startDate: startDateWatch?.toISODate(),
        endDate: endDateWatch?.toISODate(),
      } as ProjectRole,
    });

    reset(defaultValues);
    setActiveStep(REASSIGN_TO_OLD_ROLE_STEP_INDEX);
  }
};
