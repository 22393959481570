import { HelpOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const HelpTooltip = ({ tooltipText = '', titleText = '' }) => {
  return (
    <>
      {titleText}
      <Tooltip title={tooltipText}>
        <HelpOutline color="disabled" fontSize="inherit" />
      </Tooltip>
    </>
  );
};

export default HelpTooltip;
