// placeholder until the api side is finished
export type UploadedFile = {
  __typename?: 'UploadedFile';
  cachedUrl?: string;
  contentType: string;
  created: string;
  id: string;
  isMalware?: string;
  isScanned?: string;
  lastModified: string;
  name: string;
  sizeInBytes?: string;
  url?: string;
};

export const isImageFile = (file?: UploadedFile) => {
  return file?.contentType?.includes('image');
};

export const isPdfFile = (file?: UploadedFile) => {
  return file?.contentType === 'application/pdf';
};

export const isDocFile = (file?: UploadedFile) => {
  return file?.contentType === 'application/msword';
};

export const isXlsFile = (file?: UploadedFile) => {
  return file?.contentType === 'application/vnd.ms-excel';
};
