import { atom, AtomEffect } from 'recoil';

// null is used here to indicate that the user's default region should be used
const defaultState: string[] | null = null;
//TODO: Uncomment when craft feature is ready to be pushed to prod & remove duplicate variable
// const defaultEmployeeTypeState: string | null = null;
const defaultEmployeeTypeState: string = 'Salary';

function localStorageEffect<T>(key: string): AtomEffect<T> {
  return ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset ? sessionStorage.removeItem(key) : sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };
}

export const selectedDistrictsState = atom({
  key: 'selectedDistrictState',
  default: defaultState,
  effects: [localStorageEffect<string[] | null>('selected_district')],
});

export const selectedEmployeeTypeState = atom({
  key: 'selectedEmployeeTypeState',
  default: defaultEmployeeTypeState,
  effects: [localStorageEffect<string | null>('selected_employee_type')],
});
