import { CRAFT, DEFAULT, DM, ENTERPRISE_ADMIN, MARKETING, STOCKHOLDER, SUPPORT } from 'constants/appRoles';
import { useEffect, useState } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

type IdTokenClaims = {
  roles: string[];
};

const hasRole = (roles: string[], role: string): boolean => {
  const downgradedRolesValue = window.sessionStorage.getItem('downgradedRoles') ?? '';
  const downgradedRoles = downgradedRolesValue.split(',');
  return roles?.includes(role) && !downgradedRoles.includes(role);
};

const useRoles = () => {
  const { instance, inProgress } = useMsal();
  const [roles, setRoles] = useState<string[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(inProgress === InteractionStatus.None);
  }, [inProgress]);

  useEffect(() => {
    const idTokenClaims = instance.getActiveAccount()?.idTokenClaims as IdTokenClaims;
    const roles = idTokenClaims?.roles ?? [];
    setRoles(roles);
  }, [instance]);

  const isEnterpriseAdmin = hasRole(roles, ENTERPRISE_ADMIN);
  const isStockholder = hasRole(roles, STOCKHOLDER);
  const isDM = hasRole(roles, DM);
  const isMarketing = hasRole(roles, MARKETING);
  const isCraft = hasRole(roles, CRAFT);
  const isDefaultRole = hasRole(roles, DEFAULT);
  const isSupportRole = hasRole(roles, SUPPORT);
  const isSupportRoleForTesting = roles?.includes(SUPPORT) ?? false;
  const isOnlyCraft = hasRole(roles, CRAFT) && roles.length === 1;

  return {
    isEnterpriseAdmin,
    isStockholder,
    isDM,
    isMarketing,
    isCraft,
    isDefaultRole,
    isSupportRole,
    isSupportRoleForTesting,
    roles,
    isAuthenticated,
    isOnlyCraft,
  };
};

export default useRoles;
