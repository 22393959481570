import { Grid, SxProps, Theme } from '@mui/material';
import DeleteProjectDialog from 'components/ProjectDetails/DeleteProjectDialog';
import EditProjectDialog from 'components/ProjectDetails/EditProjectDialog';
import ProjectDetailsHeader from 'components/ProjectDetails/ProjectDetailsHeader';
import ProjectDetailsInfoFields from 'components/ProjectDetails/ProjectDetailsInfoFields';
import ProjectDetailsMap from 'components/ProjectDetails/ProjectDetailsMap';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Project, useGetSingleProjectQuery } from 'types/generated/graphql';

const displayFlexStyles: SxProps<Theme> = {
  height: '100%',
  display: 'flex',
};

type useParamsProps = { projectId: string };

export const ProjectDetails: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] = useState(false);
  const { projectId } = useParams<useParamsProps>();

  const { data, loading: isProjectDetailsLoading } = useGetSingleProjectQuery({
    variables: {
      id: projectId as string,
    },
  });
  const project = data?.project;
  return (
    <>
      {isProjectDetailsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container sx={displayFlexStyles}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6} columnSpacing={1}>
                <ProjectDetailsHeader
                  setIsEditing={setIsEditing}
                  setIsDeleteProjectDialogOpen={setIsDeleteProjectDialogOpen}
                  project={project}
                />
                <ProjectDetailsInfoFields project={project} />
              </Grid>
              <ProjectDetailsMap project={project} />
            </Grid>
          </Grid>
          <DeleteProjectDialog
            isOpen={isDeleteProjectDialogOpen}
            setIsOpen={setIsDeleteProjectDialogOpen}
            project={project as Project}
          />
          <EditProjectDialog isOpen={isEditing} setIsOpen={setIsEditing} project={project} />
        </>
      )}
    </>
  );
};

export default ProjectDetails;
