import { TrainingOption } from 'types/generated/graphql';

export const TRAINING_OPTIONS = [
  { value: TrainingOption.AerialLift, label: 'Aerial Lift' },
  { value: TrainingOption.BoomLift, label: 'Boom Lift' },
  { value: TrainingOption.ConfinedSpace, label: 'Confined Space' },
  { value: TrainingOption.Cranes, label: 'Cranes' },
  { value: TrainingOption.Excavations, label: 'Excavations' },
  { value: TrainingOption.FallProtection, label: 'Fall Protection' },
  { value: TrainingOption.ForkLift, label: 'Fork Lift' },
  { value: TrainingOption.Osha10, label: 'OSHA10' },
  { value: TrainingOption.Osha30, label: 'OSHA30' },
  { value: TrainingOption.Other, label: 'Other' },
  { value: TrainingOption.Person, label: 'Person' },
  { value: TrainingOption.Rigging, label: 'Rigging' },
  { value: TrainingOption.Scaffolds, label: 'Scaffolds' },
  { value: TrainingOption.ScissorLift, label: 'Scissor Lift' },
  { value: TrainingOption.Signal, label: 'Signal' },
  { value: TrainingOption.Silica, label: 'Silica' },
];
