import { ROLES_BY_NAME } from 'constants/roles';
import { Person } from 'types/generated/graphql';
import { graphqlDateToJsDate } from 'utils/general';

import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Person {
    prJobTitleAbbreviation: String @requires(fields: "prJobTitle")
    prJobTitleFullDisplayName: String @requires(fields: "prJobTitle")
    hireJsDate: Date @requires(fields: "hireDate")
  }
`;

// Resolvers
export const resolvers = {
  Person: {
    prJobTitleAbbreviation: (person: Person) => {
      return ROLES_BY_NAME[person.prJobTitle ?? '']?.abbreviation ?? '';
    },
    prJobTitleFullDisplayName: (person: Person) => {
      return ROLES_BY_NAME[person.prJobTitle ?? '']?.fullDisplayName ?? '';
    },
    hireJsDate: (person: Person) => {
      return person.hireDate ? graphqlDateToJsDate(person.hireDate) : null;
    },
  },
};

export const CONNECTED_PROJECT_ROLES = gql`
  query ConnectedProjectRoles($fromPersonId: ID!, $toRoleIds: [ID!]!) {
    connectedProjectRoles(fromPersonId: $fromPersonId, toRoleIds: $toRoleIds) {
      id
      person {
        id
        name {
          lastCommaFirst
          firstName
          lastName
          middleName
          display
        }
      }
      id
      roleName
      project {
        id
        name
      }
    }
  }
`;

export const PERSON_LIST_RESULT = gql`
  query PersonListResult(
    $isOnlyCraft: Boolean!
    $first: Int
    $order: PersonListOrderInput
    $search: String
    $skip: Int
    $isEmployee: Boolean
    $districts: [String!]
    $isActive: [String!]
  ) {
    personListResult(
      isOnlyCraft: $isOnlyCraft
      first: $first
      order: $order
      search: $search
      skip: $skip
      isEmployee: $isEmployee
      districts: $districts
      isActive: $isActive
    ) {
      items {
        id
        name {
          lastCommaFirst
          firstName
          lastName
          middleName
          display
        }
        contactInfo {
          primaryPhone {
            number
          }
          secondaryPhone {
            number
          }
          personalEmailAddress {
            email
          }
          hpEmailAddress {
            email
          }
        }
        employeeNumber
        employeePrNumber
        prDistrict
        roles {
          id
          roleName
          startDate
          endDate
          project {
            id
            districts
            name
            startDate
            endDate
          }
        }
        recentTitleChange
        titleChanges {
          id
          oldTitle
          newTitle
          dateAcquired
        }
        prJobTitleAbbreviation @client
        prJobTitleFullDisplayName @client
        prJobTitle
        isActive
        hireDate
        hireJsDate @client
        industryStartYear
        industryLengthOfExperience
        personTenure {
          id
          tenureYears
        }
        personTenureYear
        anticipatedSeparationDate
        possiblePromotionTitle
        possiblePromotionDate
        earliestDateAvailable
        notes
        notesFlag
        isCraft
      }
    }
  }
`;

export const PERSON_LIST_RESULT_FOR_GANTT = gql`
  query PersonListResultForGantt(
    $isOnlyCraft: Boolean!
    $first: Int
    $order: PersonListOrderInput
    $search: String
    $skip: Int
    $isEmployee: Boolean
    $districts: [String!]
    $isActive: [String!]
  ) {
    personListResult(
      isOnlyCraft: $isOnlyCraft
      first: $first
      order: $order
      search: $search
      skip: $skip
      isEmployee: $isEmployee
      districts: $districts
      isActive: $isActive
    ) {
      items {
        id
        prJobTitle
        prJobTitleAbbreviation @client
        prJobTitleFullDisplayName @client
        isActive
        hireDate
        personTenure {
          id
          tenureYears
        }
        personTenureYear
        earliestDateAvailable
        possiblePromotionTitle
        possiblePromotionDate
        anticipatedSeparationDate
        prDistrict
        sharedWithDistricts
        name {
          lastCommaFirst
          firstName
          lastName
          middleName
          display
          preferred
        }
        roles {
          id
          roleName
          roleAbbreviation @client
          roleFullDisplayName @client
          startDate
          endDate
          project {
            id
            districts
            name
            startDate
            endDate
          }
        }
        recentTitleChange
        titleChanges {
          id
          oldTitle
          newTitle
          dateAcquired
        }
        industryStartYear
        industryLengthOfExperience
        notes
        notesFlag
        isCraft
      }
    }
  }
`;

export const PERSON_LIST_RESULT_FOR_ROLE_ASSIGNMENT_SELECT = gql`
  query PersonListResultForRoleAssignmentSelect($isOnlyCraft: Boolean!, $districts: [String!]) {
    personListResult(isOnlyCraft: $isOnlyCraft, isActive: ["Active", "Awaiting Hire"], districts: $districts) {
      items {
        id
        prJobTitle
        prJobTitleAbbreviation @client
        prJobTitleFullDisplayName @client
        hireDate
        hireJsDate @client
        industryStartYear
        industryLengthOfExperience
        anticipatedSeparationDate
        personTenure {
          id
          tenureYears
        }
        personTenureYear
        possiblePromotionTitle
        possiblePromotionDate
        notes
        notesFlag
        isCraft
        roles {
          id
          startDate
          endDate
          roleName
          project {
            id
            name
          }
        }
        recentTitleChange
        titleChanges {
          id
          oldTitle
          newTitle
          dateAcquired
        }
        name {
          lastCommaFirst
          firstName
          lastName
          middleName
          display
        }
      }
    }
  }
`;

export const GET_SINGLE_PERSON = gql`
  query GetSinglePerson($id: ID!) {
    person(id: $id) {
      id
      name {
        firstName
        lastName
        middleName
        lastCommaFirst
        display
        preferred
      }
      employeeNumber
      contactInfo {
        primaryPhone {
          number
        }
        secondaryPhone {
          number
        }
        personalEmailAddress {
          email
        }
        hpEmailAddress {
          email
        }
      }
      location {
        cityAndStateDisplay
        city
        stateOrProvince
        streetAndNumber
        zipOrPostalCode
        streetDetail
        cityStateAndZipDisplay
      }
      prJobTitleAbbreviation @client
      prJobTitleFullDisplayName @client
      prJobTitle
      isActive
      districts
      prDistrict
      sharedWithDistricts
      contractTypes
      personVerticalMarkets {
        id
        verticalMarket
        verticalMarketYears
      }
      lastEvalDate
      hireDate
      industryStartYear
      industryLengthOfExperience
      personTenure {
        id
        tenureYears
      }
      personTenureYear
      possiblePromotionTitle
      possiblePromotionDate
      anticipatedSeparationDate
      notes
      notesFlag
      recentTitleChange
      titleChanges {
        id
        oldTitle
        newTitle
        dateAcquired
      }
      roles {
        id
        roleName
        startDate
        endDate
        project {
          id
          name
          districts
          verticalMarkets
          contractType
          startDate
          endDate
        }
      }
      dashProjectHistory {
        id
        projectName
        dashProjectId
        personTitleOnProject
        projectVerticalMarket
        projectDistricts
        projectContractType
        startDate
        endDate
      }
      prProjectHistory {
        id
        job
        jobName
        description
        lastDateOnJob
      }
      trainings {
        id
        name
        otherName
        awardedDate
        expirationDate
        files {
          id
          name
          url
          contentType
          isScanned
          isMalware
          sizeInBytes
          url
          cachedUrl @client
        }
        isFromLinkApp
      }
      company
      isCraft
    }
  }
`;

export const UPDATE_PERSON = gql`
  mutation UpdatePerson($input: PersonCreateInput!) {
    updatePerson(input: $input) {
      id
      name {
        preferred
      }
      possiblePromotionTitle
      possiblePromotionDate
      anticipatedSeparationDate
      sharedWithDistricts
      notes
      notesFlag
      titleChanges {
        id
        oldTitle
        newTitle
        dateAcquired
      }
      company
    }
  }
`;

export const DELETE_PERSON = gql`
  mutation DeletePerson($id: ID!) {
    deletePerson(id: $id) {
      id
    }
  }
`;

export const PERSON_SUBSCRIPTION = gql`
  subscription UpdatedPerson($personId: ID!) {
    person(id: $personId) {
      id
      name {
        lastCommaFirst
        firstName
        lastName
        middleName
        display
      }
      contactInfo {
        primaryPhone {
          number
        }
        secondaryPhone {
          number
        }
        personalEmailAddress {
          email
        }
        hpEmailAddress {
          email
        }
      }
      location {
        cityStateAndZipDisplay
      }
      districts
      prJobTitleAbbreviation @client
      prJobTitleFullDisplayName @client
      prJobTitle
      isActive
      prDistrict
      sharedWithDistricts
      possiblePromotionTitle
      possiblePromotionDate
      anticipatedSeparationDate
      notes
      notesFlag
      isCraft
      roles {
        roleName
        project {
          id
          name
          districts
          verticalMarkets
          startDate
          endDate
        }
      }
      titleChanges {
        oldTitle
        newTitle
        dateAcquired
      }
    }
  }
`;

export const DELETED_PERSON_SUBSCRIPTION = gql`
  subscription DeletedPerson {
    deletedPerson {
      id
    }
  }
`;
