import { useApolloClient } from '@apollo/client';
import { Autocomplete, Box, FormHelperText, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { ROLES_BY_ABBREVIATION, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Project, ProjectRole, useUpsertProjectRoleMutation } from 'types/generated/graphql';
import { convertDateToUseableISOString, generateTransactionKey } from 'utils/general';

type TransferPersonnelDialogProps = {
  isTransferPersonnelOpen: boolean;
  setIsTransferPersonnelOpen: (x: boolean) => void;
  projectListResult: Project[] | undefined;
  transferPersonnelRole: ProjectRole;
  selectedEmployeeType: string | null;
};

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1),
});

const formStyle: SxProps<Theme> = {
  width: '100%',
};

const formHelperErrorTextStyle: SxProps<Theme> = {
  color: '#cd0000',
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginLeft: '14px',
};

const formRowStyle: SxProps<Theme> = {
  flexDirection: 'row',
  display: 'flex',
  marginBottom: '20px',
};

type TransferPersonnelFormValues = {
  project: {
    name: string;
    id: string;
  };
  role: string;
  personnel: string;
  startDate: Date;
  endDate: Date;
};

const TransferPersonnelDialog: FC<TransferPersonnelDialogProps> = ({
  isTransferPersonnelOpen,
  setIsTransferPersonnelOpen,
  projectListResult,
  transferPersonnelRole,
  selectedEmployeeType,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const startDate = DateTime.fromJSDate(new Date(transferPersonnelRole?.endDate)).plus({ days: 1 });
  const [addProjectRole, { loading: isLoading }] = useUpsertProjectRoleMutation();

  const defaultValues = {
    project: { id: transferPersonnelRole?.project?.id ?? '', name: transferPersonnelRole?.project?.name ?? '' },
    role: transferPersonnelRole?.roleName ?? '',
    personnel: transferPersonnelRole?.person?.name?.lastCommaFirst ?? '',
  };
  const { handleSubmit, control, reset, setValue } = useForm<TransferPersonnelFormValues>({ defaultValues });

  const [project, role, endDate] = useWatch({
    control,
    name: ['project', 'role', 'endDate'],
  });

  const handleClose = () => {
    if (setIsTransferPersonnelOpen) {
      setIsTransferPersonnelOpen(false);
    }
    reset({ project: { name: '', id: '' }, role: '', personnel: '' });
    setTransactionKey(generateTransactionKey());
  };

  const onSubmit: SubmitHandler<TransferPersonnelFormValues> = (data) => {
    const roleStartDate = startDate.toJSDate();
    const roleEndDate = new Date(new Date(data.endDate).toUTCString());
    const personId = transferPersonnelRole?.person?.id;
    const roleName = data.role.includes('-')
      ? data.role.split(' - ')[1]
      : (ROLES_BY_ABBREVIATION[data.role]?.roleName as string);
    const projectId = data.project.id;

    addProjectRole({
      variables: {
        input: {
          transactionKey,
          startDate: convertDateToUseableISOString(roleStartDate) ?? undefined,
          endDate: convertDateToUseableISOString(roleEndDate) ?? undefined,
          roleName,
          personId,
          projectId,
        },
      },
    })
      .then(() => {
        apolloClient.reFetchObservableQueries();
        displayToast('The personnel was transferred successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to transfer the personnel. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  useEffect(() => {
    const isEndDateInvalid = isNaN(new Date(endDate).getTime());
    project.id && project.name && role && !isEndDateInvalid ? setCanSubmit(true) : setCanSubmit(false);
  }, [endDate, project, role]);

  return (
    <StyledDialog
      title={`Transfer Personnel`}
      isLoading={isLoading}
      content={
        <Grid container sx={containerStyle}>
          <Box component="form" sx={formStyle}>
            <Grid container spacing={12} sx={formRowStyle}>
              <Grid item xs={6}>
                <Typography>Personnel</Typography>
                <TextField
                  size="small"
                  disabled
                  fullWidth
                  variant="outlined"
                  value={transferPersonnelRole?.person?.name?.lastCommaFirst}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Project</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Fragment>
                        <Autocomplete
                          id="contentType"
                          size="small"
                          fullWidth
                          options={
                            projectListResult
                              ?.filter((project) => transferPersonnelRole.project.id !== project.id)
                              ?.map((project) => {
                                return { id: project.id, name: project.name };
                              }) ?? []
                          }
                          getOptionLabel={(value) => value.name}
                          filterSelectedOptions
                          onChange={(_event, newValue) => {
                            onChange(newValue);
                          }}
                          value={value}
                          isOptionEqualToValue={(
                            option: { id: string; name: string },
                            value: { id: string; name: string },
                          ) => {
                            return option.id === value.id;
                          }}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={props.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
                        />
                        {!value.id && !value.name && (
                          <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                        )}
                      </Fragment>
                    );
                  }}
                  name="project"
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container spacing={12} sx={formRowStyle}>
              <Grid item xs={4}>
                <Typography>Role</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Fragment>
                      <Autocomplete
                        id="contentType"
                        size="small"
                        options={ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft')}
                        getOptionLabel={(value) => value}
                        filterSelectedOptions
                        onChange={(_event, newValue) => {
                          onChange(newValue);
                          setValue('role', newValue ?? '');
                        }}
                        value={value}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return option === value;
                        }}
                        renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
                      />
                      {!value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
                    </Fragment>
                  )}
                  name="role"
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>Start Date</Typography>
                <TextField
                  size="small"
                  disabled
                  fullWidth
                  variant="outlined"
                  value={startDate?.plus({ days: 1 }).toFormat('MM/dd/yyyy')}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>End Date</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={!value}
                      helperText={!value ? 'Field Required' : ''}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: startDate?.plus({ days: 1 }).toFormat('yyyy-MM-dd'),
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        onChange(value);
                        setValue('endDate', new Date(value));
                      }}
                      type={'date'}
                    />
                  )}
                  name="endDate"
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      }
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'Submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!canSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isTransferPersonnelOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default TransferPersonnelDialog;
