import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

type StyledDialogProps = {
  title: string | any;
  titleMenu?: React.ReactNode;
  content: React.ReactNode;
  contentGroupHeader?: string;
  actions: React.ReactNode;
  isOpen: boolean;
  handleClose: any;
  fullWidth?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  fullScreen?: boolean;
  isLoading?: boolean;
  shouldShowCloseIconButton?: boolean;
  shouldCenterDialogTitle?: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  shouldShowerHeaderTitle?: boolean;
};

const dialogTitleStyle: SxProps<Theme> = (theme: Theme) => ({
  zIndex: 1500,
  [theme.breakpoints.down('md')]: { padding: theme.spacing(2) },
});

const titleStyle: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '1.5rem',
  whiteSpace: 'nowrap',
  alignSelf: 'center',
  maxWidth: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('md')]: { fontSize: '1.25rem' },
});

const dialogContentStyle: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: { padding: theme.spacing(1, 2) },
});

const dialogContentNoHeaderStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: '0px !important',
  [theme.breakpoints.down('md')]: { padding: theme.spacing(1, 2) },
});

const closeIconStyle: SxProps<Theme> = {
  fontSize: '1.25rem',
};

const StyledDialog: React.FC<StyledDialogProps> = ({
  title,
  titleMenu,
  content,
  contentGroupHeader,
  actions,
  isOpen,
  handleClose,
  fullWidth = true,
  maxWidth = 'md',
  fullScreen = false,
  isLoading,
  shouldShowCloseIconButton = true,
  shouldCenterDialogTitle = false,
  disableBackdropClick = true,
  disableEscapeKeyDown = true,
  shouldShowerHeaderTitle = true,
  ...rest
}) => {
  return (
    <Dialog
      open={isOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...rest}
    >
      {isLoading && <LinearProgress color="primary" />}
      {shouldShowerHeaderTitle && (
        <DialogTitle sx={dialogTitleStyle} id="non-printable">
          {!shouldCenterDialogTitle && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  {titleMenu && <Grid item>{titleMenu}</Grid>}
                  <Grid item>
                    <Tooltip title={title}>
                      <Typography color="textPrimary" sx={titleStyle}>
                        {title}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              {shouldShowCloseIconButton && (
                <Grid item>
                  <IconButton data-testid="iconButton" disabled={isLoading} onClick={handleClose} size="large">
                    <CloseIcon sx={closeIconStyle} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )}
          {shouldCenterDialogTitle && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  {titleMenu && <Grid item>{titleMenu}</Grid>}
                  <Grid item>
                    <Tooltip title={title}>
                      <Typography color="textPrimary" sx={titleStyle}>
                        {title}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogTitle>
      )}
      <Divider></Divider>
      <DialogContent sx={shouldShowerHeaderTitle ? dialogContentStyle : dialogContentNoHeaderStyle} id="printable">
        {contentGroupHeader && (
          <>
            <Typography color="textPrimary">{contentGroupHeader}</Typography>
          </>
        )}
        {content}
      </DialogContent>
      <DialogActions id="non-printable">{actions}</DialogActions>
    </Dialog>
  );
};

export default StyledDialog;
