export const EDW_SOURCE = 'Sourced from the Enterprise Data Warehouse';
export const EMPLOYEE_MASTER_SOURCE = 'Sourced from Employee Master';
export const UKG_SOURCE = 'Sourced from UKG';
export const VISTA_SOURCE = 'Sourced from Vista';
export const LINK_SOURCE = 'Sourced from Link';
export const DASH_SOURCE = 'Sourced from DASH';
export const PEOPLE360_SOURCE = 'Sourced from People360';
export const USER_INPUT_SOURCE = 'Supplied by user input';
export const PROJECT_SUMMARY_SOURCE = 'Summarized from project experience';
export const HIRE_DATE_CALC_SOURCE = 'Calculated from hire date';
export const PEOPLE360_CALC_SOURCE = 'Calculated from People360 data';
