import { createUploadLink } from 'apollo-upload-client';
import { getCachedUrl } from 'utils/general';

import { ApolloClient, from, InMemoryCache, makeVar, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { PublicClientApplication } from '@azure/msal-browser';

import { APP_ENVIRONMENTS, ENV } from '../constants';
import { resolvers, typeDefs } from '../graphql';
import { acquireIdToken } from './msalConfig';

const url = new URL(process.env.REACT_APP_API_URL as string);
const isLocal = ENV === APP_ENVIRONMENTS.local;

const webSocketUrl = `${isLocal ? 'ws' : 'wss'}://${url.hostname}:${isLocal ? url.port : ''}/ws`;

const httpLink = createUploadLink({
  uri: url.origin,
});

const downgradedRolesLink = setContext(async (_, { headers }) => {
  const downgradedRoles = window.sessionStorage.getItem('downgradedRoles');
  if (!downgradedRoles) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      'X-Downgraded-Roles': downgradedRoles,
    },
  };
});

export const getApolloClient = (msalInstance: PublicClientApplication, onWsError: { (error: any): void }) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await acquireIdToken(msalInstance);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const wsLink = new WebSocketLink({
    uri: webSocketUrl,
    options: {
      reconnect: true,
      connectionParams: async () => {
        const token = await acquireIdToken(msalInstance);
        return {
          authorization: `Bearer ${token}`,
        };
      },
      connectionCallback: (error: any) => {
        if (error) {
          // In practice the actual type doesn't seem to match up with the one given.
          onWsError(error);
        }
      },
    },
  });

  const retryLink = new RetryLink();

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(downgradedRolesLink).concat(retryLink).concat(httpLink),
  );

  return new ApolloClient({
    link: from([splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        UploadedFile: {
          fields: {
            cachedUrl: {
              read(_, { storage, readField }) {
                if (!storage.cachedUrl) {
                  storage.cachedUrl = makeVar(null);
                  const url: string | undefined = readField('url');
                  getCachedUrl(url).then((blobUrl) => storage.cachedUrl(blobUrl));
                }
                return storage.cachedUrl();
              },
            },
          },
        },
        Query: {
          fields: {
            personListResult: {
              keyArgs: false,
            },
          },
        },
      },
    }),
    typeDefs,
    resolvers,
    assumeImmutableResults: true,
  });
};
