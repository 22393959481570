import { Grid, IconButton, SxProps, Theme, Tooltip } from '@mui/material';
import { FC } from 'react';

type IconButtonProps = {
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const StyledIconButton: FC<IconButtonProps> = ({ title, onClick, icon, sx }) => {
  return (
    <Grid item sx={sx}>
      <Tooltip title={title}>
        <span>
          <IconButton size="small" sx={{ minHeight: 0, minWidth: 0, padding: 0 }} onClick={onClick}>
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default StyledIconButton;
