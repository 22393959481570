import { RoleSummary } from 'types';

export const ROLES_SUMMARY_DICTIONARY: RoleSummary[] = [
  {
    name: 'rolesSummary.employeeName',
    label: 'Employee Name',
    required: false,
    key: 'Employee Id',
    xs: 6,
  },
  {
    name: 'rolesSummary.position',
    label: 'Position',
    required: true,
    key: 'Role Position',
    xs: 6,
  },
  {
    name: 'rolesSummary.startDate',
    label: 'Start Date',
    required: true,
    key: 'Role Begin Date',
    xs: 6,
  },
  {
    name: 'rolesSummary.endDate',
    label: 'End Date',
    required: true,
    key: 'Role End Date',
    xs: 6,
  },
];
