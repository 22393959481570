import LoadingSpinner from 'components/shared/LoadingSpinner';
import { DISTRICTS } from 'constants/districts';
import { ROLES, ROLES_BY_FULL_DISPLAY_NAME } from 'constants/roles';
import { TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX } from 'constants/themes/dimensions';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDistrictsState } from 'recoil/selectedDistrict/atom';
import {
  RollingAttritionListResultQueryResult,
  StaffingPlanListResultQueryResult,
  UpsertStaffingPlanMutationResult,
  useRollingAttritionListResultQuery,
  useStaffingPlanListResultQuery,
  useUpsertRollingAttritionMutation,
  useUpsertStaffingPlanMutation,
} from 'types/generated/graphql';
import { roleSort } from 'utils/roles';

import { Alert, Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

type StaffingPlanFromListQuery = Exclude<
  StaffingPlanListResultQueryResult['data'],
  undefined
>['staffingPlanListResult']['items'][number];
type StaffingPlanFromUpsert = Exclude<UpsertStaffingPlanMutationResult['data'], null | undefined>['upsertStaffingPlan'];
type VirtualStaffingPlan = Omit<StaffingPlanFromListQuery, 'id' | '__typename'> & { region: string };
type StaffingPlanForGrid = StaffingPlanFromListQuery | StaffingPlanFromUpsert | VirtualStaffingPlan;
type RollingWindow = Record<string, StaffingPlanForGrid>;

type RollingAttritionFromListQuery = Exclude<
  RollingAttritionListResultQueryResult['data'],
  undefined
>['rollingAttritionListResult']['items'][number];
type RollingAttritionFromUpsert = Exclude<
  UpsertStaffingPlanMutationResult['data'],
  null | undefined
>['upsertStaffingPlan'];
type VirtualRollingAttrition = Omit<RollingAttritionFromListQuery, 'id' | '__typename'> & { region: string };
type RollingAttritionForGrid = RollingAttritionFromListQuery | RollingAttritionFromUpsert | VirtualRollingAttrition;
type RollingAttritionWindow = Record<string, RollingAttritionForGrid>;

const ROW_HEIGHT_PX = 20;

const gridContainerStyle: SxProps<Theme> = {
  width: '100%',
  height: `calc(100vh - ${TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX}px)`,
  zIndex: 100,
};

const ExportToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

export const StaffingPlan: FC = () => {
  const { displayToast } = useToast();
  const selectedDistricts = useRecoilValue(selectedDistrictsState);
  const region = selectedDistricts && selectedDistricts.length === 1 ? selectedDistricts[0] : '';
  const regionName = DISTRICTS.find((district) => district.number === region)?.name ?? 'Unknown Region';
  const filteredRoles = ROLES.filter((role) => role.type !== 'CRAFT');

  const { data, loading } = useStaffingPlanListResultQuery({
    variables: {
      region: region,
    },
    skip: !region,
  });
  const staffingPlans = data?.staffingPlanListResult?.items ?? [];

  const { data: rollingAttritionData } = useRollingAttritionListResultQuery({
    variables: {
      region: region,
    },
    skip: !region,
  });

  const rollingAttrition = rollingAttritionData?.rollingAttritionListResult?.items ?? [];

  const [upsertStaffingPlan] = useUpsertStaffingPlanMutation({
    refetchQueries: ['StaffingPlanListResult'],
    awaitRefetchQueries: true,
  });

  const [upsertRollingAttrition] = useUpsertRollingAttritionMutation({
    refetchQueries: ['RollingAttritionListResult'],
    awaitRefetchQueries: true,
  });

  const startOfCurrentYear = DateTime.now().startOf('year');
  const rollingWindow: RollingWindow = {};
  for (let i = 0; i < 36; i++) {
    const month = startOfCurrentYear.plus({ months: i }).toISODate();
    if (month) {
      rollingWindow[month] = { region, monthOf: month, roleName: '', hiringTarget: 0 };
    } else {
      console.error(`Failed to determine month ${i} in rolling window.`);
    }
  }

  const rollingAttritionWindow: RollingAttritionWindow = {};
  for (let i = 0; i < filteredRoles.length; i++) {
    const roleName = filteredRoles[i].roleName;
    const attrition = rollingAttrition.find((rollingAttr) => rollingAttr.jobTitle === roleName)?.attrition ?? 0;
    if (roleName) {
      rollingAttritionWindow[roleName] = {
        region,
        jobTitle: roleName,
        customAttrition: '0',
        attrition: attrition.toString(),
      };
    } else {
      console.error(`Failed to determine role ${i} in rolling attrition window.`);
    }
  }

  const staffingPlansByRoleNameAndMonth = filteredRoles.reduce<Record<string, RollingWindow>>((accumulator, role) => {
    accumulator[role.fullDisplayName] = {
      ...Object.entries(rollingWindow).reduce<RollingWindow>((accumulator, [month, staffingPlan]) => {
        accumulator[month] = { ...staffingPlan, roleName: role.fullDisplayName };
        return accumulator;
      }, {}),
    };
    return accumulator;
  }, {});
  staffingPlans.forEach((staffingPlan) => {
    if (staffingPlan.roleName in staffingPlansByRoleNameAndMonth) {
      if (staffingPlan.monthOf in staffingPlansByRoleNameAndMonth[staffingPlan.roleName]) {
        staffingPlansByRoleNameAndMonth[staffingPlan.roleName][staffingPlan.monthOf] = staffingPlan;
      }
    } else {
      console.warn(`staffing plan rejected due to unexpected role "${staffingPlan.roleName}"`);
    }
  });

  const columns: GridColDef<any>[] = [
    { field: 'id' },
    {
      field: 'roleName',
      headerName: 'Role',
      width: 290,
      hideable: false,
      sortComparator: (a, b) => roleSort(ROLES_BY_FULL_DISPLAY_NAME[a], ROLES_BY_FULL_DISPLAY_NAME[b]),
    },
    {
      field: 'actualAttrition',
      headerName: 'Actual Attr.',
      hideable: false,
      type: 'string',
      valueGetter: (_, row) => {
        const secondIndex = 1;
        return (
          rollingAttrition.find((rollingAttr) => rollingAttr.jobTitle === row.roleName.split(' - ')[secondIndex])
            ?.attrition ?? '0'
        );
      },
      valueFormatter: (value) => `${(value * 100).toFixed(2)}%`,
    },
    {
      field: 'customAttrition',
      headerName: 'Custom Attr.',
      hideable: false,
      editable: true,
      type: 'string',
      valueGetter: (_, row) => {
        const secondIndex = 1;
        return (
          rollingAttrition.find((rollingAttr) => rollingAttr.jobTitle === row.roleName.split(' - ')[secondIndex])
            ?.customAttrition ?? '0'
        );
      },
      valueFormatter: (value) => {
        return `${Number(value).toFixed(2)}%`;
      },
      valueSetter: (value, row) => {
        return { ...row, customAttrition: value };
      },
    },
    ...Object.entries(rollingWindow).reduce<GridColDef<any>[]>((accumulator, [month]) => {
      const monthHeaderName = month.slice(0, -3);
      accumulator.push({
        field: month,
        headerName: `${monthHeaderName}`,
        type: 'number',
        editable: true,
        valueGetter: (_, row) => row[month]?.hiringTarget ?? 0,
        valueSetter: (value, row) =>
          Object.fromEntries(
            Object.entries(row).map(([columnKey, cellValue]) => {
              if (columnKey === month) {
                return [columnKey, { ...(cellValue as StaffingPlanForGrid), hiringTarget: value ?? 0 }];
              } else {
                return [columnKey, cellValue];
              }
            }),
          ),
      });
      return accumulator;
    }, []),
    {
      field: 'cumulativeFirstYear',
      headerName: 'Cumul. 1st Year',
      width: 130,
      hideable: false,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(0, 11)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return <div key={params.id}>{cumulativeValue}</div>;
      },
    },
    {
      field: 'cumulativeSecondYear',
      headerName: 'Cumul. 2nd Year',
      width: 130,
      hideable: false,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(12, 23)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return <div key={params.id}>{cumulativeValue}</div>;
      },
    },
    {
      field: 'cumulativeThirdYear',
      headerName: 'Cumul. 3rd Year',
      width: 130,
      hideable: false,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(24, 35)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return <div key={params.id}>{cumulativeValue}</div>;
      },
    },
  ];

  const onProcessRowUpdate = async (newRow: any, oldRow: any) => {
    Object.entries<StaffingPlanForGrid | string>(newRow).forEach(([columnKey, newCellValue]) => {
      if (typeof newCellValue !== 'string' && oldRow[columnKey]?.hiringTarget !== newCellValue.hiringTarget) {
        return upsertStaffingPlan({
          variables: {
            input:
              'id' in newCellValue
                ? { id: newCellValue.id, hiringTarget: newCellValue.hiringTarget }
                : {
                    region,
                    roleName: newCellValue.roleName,
                    monthOf: newCellValue.monthOf,
                    hiringTarget: newCellValue.hiringTarget,
                  },
          },
        }).catch((error) => {
          displayToast(
            'Error: Something went wrong while trying to update the staffing plan. Please try again. If the problem persists, please contact support.',
            'error',
          );
          console.error(error);
        });
      }
    });
    if (oldRow?.customAttrition !== newRow.customAttrition || !('customAttrition' in oldRow)) {
      const secondIndex = 1;
      const roleName = newRow.roleName?.split(' - ')[secondIndex] ?? oldRow?.rowName?.split(' - ')[secondIndex];
      return upsertRollingAttrition({
        variables: {
          input: {
            region,
            regionRoleKey: region + '_' + roleName,
            jobTitle: roleName,
            customAttrition: newRow.customAttrition,
          },
        },
      }).catch((error: any) => {
        displayToast(
          'Error: Something went wrong while trying to update the staffing plan. Please try again. If the problem persists, please contact support.',
          'error',
        );
        console.error(error);
      });
    }
    return newRow;
  };

  const onProcessRowUpdateError = (error: any) => {
    displayToast(
      'Error: Something went wrong while trying to update the staffing plan. Please try again. If the problem persists, please contact support.',
      'error',
    );
    console.error(error);
  };

  return (
    <Box sx={gridContainerStyle}>
      {!region ? (
        <Alert severity="warning">Please select exactly one region above to load that region's staffing plan.</Alert>
      ) : loading ? (
        <LoadingSpinner />
      ) : !data ? (
        <Alert severity="error">Failed to access staffing plan. If the problem persists, please contact support.</Alert>
      ) : (
        <Stack spacing={1} sx={{ height: '100%' }}>
          <Typography>Staffing Plan for {regionName}</Typography>
          <DataGrid
            rows={Object.entries(staffingPlansByRoleNameAndMonth).map(([roleName, staffingPlansInRollingWindow]) => ({
              roleName,
              ...staffingPlansInRollingWindow,
            }))}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            slots={{ toolbar: ExportToolbar }}
            columns={columns}
            getRowId={(row) => row.roleName + row.id}
            pageSizeOptions={[filteredRoles.length]}
            rowHeight={ROW_HEIGHT_PX}
            columnHeaderHeight={ROW_HEIGHT_PX}
            autoHeight={false}
            hideFooter={true}
            showCellVerticalBorder
            disableMultipleRowSelection
            processRowUpdate={onProcessRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
          />
        </Stack>
      )}
    </Box>
  );
};

export default StaffingPlan;
