export const APP_ENVIRONMENTS = {
  standalone: 'standalone',
  test: 'test',
  local: 'local',
  dev: 'dev',
  uat: 'uat',
  prod: 'prod',
};

export const ENV = process.env.REACT_APP_ENV;
