import ProjectsGrid from 'components/ProjectsGrid';
import { ProjectsGridRowData, ToolbarItemId } from 'components/ProjectsGrid/ProjectsGrid';
import { useGlobalSearchParams } from 'components/SearchParamsProvider/SearchParamsProvider';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ProjectGantt from 'components/shared/ProjectGantt';
import TabPanel from 'components/shared/TabPanel';
import { LEAVE_PROJECT_ID } from 'constants/leaveProject';
import { ACTIVE_STATUS, ANTICIPATED_STATUS, ARCHIVED_STATUS } from 'constants/projectStatus';
import { PROJECT_GRID_ALL_ROLES_ID, PROJECT_GRID_UNFILLED_ROLES_ID } from 'constants/syncfusionComponentIds';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import { FC, Fragment, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDistrictsState, selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import { SortOrderOption, usePersonListResultQuery, useProjectListResultQuery } from 'types/generated/graphql';

import { Box, Card, Grid, Tab, Tabs } from '@mui/material';

const LOCAL_STORAGE_TAB_KEY = 'projectsTab';

export const Projects: FC = () => {
  const [searchParams, setSearchParams] = useGlobalSearchParams();
  const { isOnlyCraft } = useRoles();
  const selectedTabSearchParam = searchParams.get('tab');
  const selectedTabLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_TAB_KEY);
  const [selectedTab, setSelectedTab] = useState(
    selectedTabSearchParam
      ? Number(selectedTabSearchParam)
      : selectedTabLocalStorage
      ? Number(selectedTabLocalStorage)
      : 0,
  );

  const selectedDistricts = useRecoilValue(selectedDistrictsState);
  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;

  const projectListResultQueryVariables = {
    districts: selectedDistricts,
    statuses: [...ACTIVE_STATUS, ...ANTICIPATED_STATUS, ...ARCHIVED_STATUS],
  };
  const {
    data: projectListData,
    loading: isProjectListLoading,
    refetch: refetchProjectList,
  } = useProjectListResultQuery({
    variables: projectListResultQueryVariables,
    fetchPolicy: 'no-cache',
  });
  const projectList = projectListData?.projectListResult?.items ?? [];

  const dataSource: ProjectsGridRowData[] = projectList.flatMap((project) => {
    if (!project.roles.length) {
      return [{ project }];
    }
    if (project.id === LEAVE_PROJECT_ID) {
      project.startDate = DateTime.now().minus({ month: 1 }).toISODate();
      project.endDate = DateTime.now().minus({ month: 1 }).plus({ year: 3 }).toISODate();
      project.startJsDate = DateTime.now().minus({ month: 1 }).toJSDate();
      project.endJsDate = DateTime.now().minus({ month: 1 }).plus({ year: 3 }).toJSDate();
    }
    return project.roles.map((role) => ({
      project,
      role,
    }));
  });

  const personnelListResultQueryVariables = {
    isOnlyCraft: selectedEmployeeType === 'Craft',
    isActive: ['Active', 'Awaiting Hire'],
    districts: selectedDistricts,
    order: {
      field: 'name',
      direction: SortOrderOption.Asc,
    },
  };
  const {
    data: personListData,
    loading: isPersonnelListLoading,
    refetch: refetchPersonnelList,
  } = usePersonListResultQuery({
    variables: personnelListResultQueryVariables,
    fetchPolicy: 'no-cache',
  });

  const onDataChanged = async () => {
    await Promise.all([refetchProjectList(), refetchPersonnelList()]);
  };

  const personnelList = personListData?.personListResult.items ?? [];

  const isLoading = isProjectListLoading || isPersonnelListLoading;

  const handleTabChange = (_: any, newReportingTabSelected: number) => {
    setSelectedTab(newReportingTabSelected);
    setSearchParams((previousParams) => {
      // We need to wipe all URL params that are controlled within the tabs.
      [
        'role',
        'startDate',
        'endDate',
        'project',
        'showTargetProjects',
        'showEndedRoles',
        'showArchivedProjects',
      ].forEach((key) => previousParams.delete(key));
      previousParams.set('tab', newReportingTabSelected.toString());
      return previousParams;
    });
    window.localStorage.setItem(LOCAL_STORAGE_TAB_KEY, newReportingTabSelected.toString());
  };

  const isLoadingFirstTime = isLoading && (!projectListData || !personListData);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {isLoadingFirstTime && <LoadingSpinner />}
        {!isLoadingFirstTime && (
          <Card>
            <Fragment>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                  <Tab label="All Roles" />
                  <Tab label="Unfilled Roles" />
                  <Tab label="Gantt" />
                </Tabs>
              </Box>
              <TabPanel value={selectedTab} index={0}>
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                  <ProjectsGrid
                    gridId={PROJECT_GRID_ALL_ROLES_ID}
                    personnelList={personnelList}
                    projectList={projectList}
                    onDataChanged={onDataChanged}
                    dataSource={dataSource}
                    selectedEmployeeType={selectedEmployeeType}
                    selectedDistricts={selectedDistricts ?? []}
                  />
                )}
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                  <ProjectsGrid
                    gridId={PROJECT_GRID_UNFILLED_ROLES_ID}
                    personnelList={personnelList}
                    projectList={projectList.filter((project) => project.id !== LEAVE_PROJECT_ID)}
                    onDataChanged={onDataChanged}
                    dataSource={dataSource.filter((row) => !row.role?.person && row.project.id !== LEAVE_PROJECT_ID)}
                    disabledToolbarItems={[ToolbarItemId.ADD_PROJECT, ToolbarItemId.ADD_PROJECT_ROLE]}
                    disabledFields={[
                      'role.tdy',
                      'role.person.possiblePromotionTitle',
                      'role.person.possiblePromotionDate',
                      'role.person.anticipatedSeparationDate',
                    ]}
                    selectedEmployeeType={selectedEmployeeType}
                  />
                )}
              </TabPanel>
              <TabPanel value={selectedTab} index={2}>
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                  <ProjectGantt
                    selectedDistricts={selectedDistricts ?? []}
                    personnelListForGantt={personnelList}
                    projectListForGantt={projectList}
                    selectedEmployeeType={selectedEmployeeType}
                    onDataChanged={onDataChanged}
                  />
                )}
              </TabPanel>
            </Fragment>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default Projects;
