import PrimaryAppBar from 'components/appbar/PrimaryAppBar';
import { SearchParamsProvider } from 'components/SearchParamsProvider/SearchParamsProvider';
import RootContainer from 'components/shared/RootContainer';
import useSetCurrentUser from 'hooks/useSetCurrentUser';
import { Navigate, Route, Routes } from 'react-router-dom';
import { allRoutes } from 'routes';

const App = () => {
  useSetCurrentUser();

  return (
    <main>
      <SearchParamsProvider>
        <PrimaryAppBar />
        <RootContainer>
          <Routes>
            {allRoutes.map((route) => (
              <Route {...route} key={route.path} />
            ))}
            <Route path="*" element={<Navigate to="/projects" />} />
          </Routes>
        </RootContainer>
      </SearchParamsProvider>
    </main>
  );
};

export default App;
