import { Grid, SxProps, Theme, Typography } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledTable from 'components/shared/Table';
import { EDW_SOURCE } from 'constants/tooltips';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC } from 'react';
import { GetSinglePersonQuery } from 'types/generated/graphql';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const leftAlignedHeaderStyle: SxProps<Theme> = {
  '& span': {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
};

type PersonnelEmploymentHistoryProps = {
  personnel?: GetSinglePersonQuery['person'];
};

export const PersonnelEmploymentHistoryCard: FC<PersonnelEmploymentHistoryProps> = ({ personnel }) => {
  // const personnelEmploymentHistoryData = [personnel];

  //TODO: update logic if employmentHistory data structure is not an arr or if new employment history is added to the end of the arr
  const employmentHistoryTitleChanges = personnel?.titleChanges ?? [];

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        searchable: true,
        display: 'excluded',
      },
    },
    {
      name: 'oldTitle',
      label: 'Original Title',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{employmentHistoryTitleChanges[dataIndex].oldTitle ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'newTitle',
      label: 'New Title',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{employmentHistoryTitleChanges[dataIndex].newTitle ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'dateAcquired',
      label: 'Effective Date',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{employmentHistoryTitleChanges[dataIndex].dateAcquired ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // customToolbar: () => {
    //   return (<Grid container justify="flex-end">TODO</Grid>)
    // },
    download: false,
    pagination: false,
    sortOrder: {
      name: 'dateAcquired',
      direction: 'asc',
    },
  };

  return (
    <>
      <Grid container sx={containerStyle} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledTable
            title={<HelpTooltip tooltipText={EDW_SOURCE} titleText="Employment History" />}
            columns={columns}
            data={employmentHistoryTitleChanges ?? []}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonnelEmploymentHistoryCard;
