import { SxProps, Theme } from '@mui/material';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import { SnackbarKey, VariantType, useSnackbar } from 'notistack';

const actionButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(2),
});

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dismiss = (key: SnackbarKey) => (
    <StyledButtonPrimary
      color="primary"
      label={'Dismiss'}
      onClick={() => {
        closeSnackbar(key);
      }}
    />
  );

  const displayToast = (message: string, type: VariantType, Action?: any, dismissible: boolean = true) => {
    const customCallback = (key: SnackbarKey) => {
      if (dismissible) {
        return (
          <>
            <Action toastKey={key} />
            <StyledButtonPrimary
              color="primary"
              sx={actionButtonStyle}
              label={'Dismiss'}
              onClick={() => {
                closeSnackbar(key);
              }}
            />
          </>
        );
      } else {
        return <Action />;
      }
    };

    const getAction = () => {
      if (Action) {
        return customCallback;
      } else if (type === 'error' || dismissible) {
        return dismiss;
      } else {
        return null;
      }
    };

    enqueueSnackbar(message, {
      variant: type,
      style: { whiteSpace: 'pre-line' },
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      autoHideDuration: type === 'error' || type === 'info' ? null : 6000,
      persist: type === 'error' || type === 'warning' || !dismissible,
      action: getAction(),
      preventDuplicate: true,
    });
  };
  return { displayToast, closeSnackbar };
};

export default useToast;
