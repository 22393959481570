import { gridInstancesById as personnelGridInstancesById } from 'components/PersonnelGrid/PersonnelGrid';
import { gridInstancesById as projectGridInstancesById } from 'components/ProjectsGrid/ProjectsGrid';
import ReloadWindowButton from 'components/ReloadWindowButton';
import {
  PERSONNEL_GRID_ID,
  PROJECT_GRID_ALL_ROLES_ID,
  PROJECT_GRID_UNFILLED_ROLES_ID,
} from 'constants/syncfusionComponentIds';
import useLatestVersion from 'hooks/useLatestVersion';
import useToast from 'hooks/useToast';
import { FC, useEffect } from 'react';

import packageJson from '../../../package.json';

declare global {
  var APP_VERSION: string;
}

global.APP_VERSION = packageJson.version;

const semverGreaterThan = (latestVersion: string, currentVersion: string) => {
  if (latestVersion && currentVersion) {
    const versionsA = latestVersion.split(/\./g);

    const versionsB = currentVersion.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());

      if (a === b) continue;

      return a > b || isNaN(b);
    }
  }

  return false;
};

const Reloader = () => {
  const refreshCacheAndReload = () => {
    sessionStorage.setItem('recentlyUpdated', 'true');
    sessionStorage.setItem('showWhatsNewNotification', 'true');
    if ('caches' in window && caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    const projectGridAllRolesInstance = projectGridInstancesById[PROJECT_GRID_ALL_ROLES_ID];
    if (projectGridAllRolesInstance) {
      projectGridAllRolesInstance.enablePersistence = false;
      window.localStorage.setItem(`grid${PROJECT_GRID_ALL_ROLES_ID}`, '');
      projectGridAllRolesInstance?.destroy();
    }
    const projectGridUnfilledRolesInstance = projectGridInstancesById[PROJECT_GRID_UNFILLED_ROLES_ID];
    if (projectGridUnfilledRolesInstance) {
      projectGridUnfilledRolesInstance.enablePersistence = false;
      window.localStorage.setItem(`grid${PROJECT_GRID_UNFILLED_ROLES_ID}`, '');
      projectGridUnfilledRolesInstance?.destroy();
    }
    const personnelGridInstance = personnelGridInstancesById[PERSONNEL_GRID_ID];
    if (personnelGridInstance) {
      personnelGridInstance.enablePersistence = false;
      window.localStorage.setItem(`grid${PERSONNEL_GRID_ID}`, '');
      personnelGridInstance?.destroy();
    }
    window.location.reload();
  };
  return <ReloadWindowButton callback={refreshCacheAndReload} />;
};

export type CacheBusterProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const CacheBuster: FC<CacheBusterProps> = ({ children }) => {
  const { data, loading: versionCheckLoading } = useLatestVersion();
  const { displayToast } = useToast();

  const latestVersion = data?.version ?? null;

  useEffect(() => {
    const currentVersion = global.APP_VERSION;

    const shouldReload =
      !versionCheckLoading && latestVersion && currentVersion && semverGreaterThan(latestVersion, currentVersion);

    if (shouldReload) {
      displayToast(
        'An updated version of the app is available. Please reload the page to update.\nNote: This will also clear the search bar, filter, grouping, and columns settings on the personnel and project list page.',
        'info',
        Reloader,
        false,
      );
    }
  }, [data, displayToast, versionCheckLoading, latestVersion]);

  useEffect(() => {
    // Check if recently updated
    const recentlyUpdated = sessionStorage.getItem('recentlyUpdated');
    if (recentlyUpdated) {
      sessionStorage.removeItem('recentlyUpdated');
      displayToast('People 360 was updated successfully.', 'info');
    }
  }, [displayToast]);

  return <>{children}</>;
};

export default CacheBuster;
