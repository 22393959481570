import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Grid, IconButton, SxProps, Theme } from '@mui/material';
import React, { useState } from 'react';
import SideNavigationMenuList from '../SideNavigationMenuList';

const iconStyle: SxProps<Theme> = {
  color: '#fff',
};

const menuStyle: SxProps<Theme> = {
  display: 'block',
  float: 'left',
};

const SideNavigationMenu = () => {
  const [isOpen, toggleIsOpen] = useState<boolean>(false);

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    toggleIsOpen(isOpen);
  };

  const onClose = () => {
    toggleIsOpen(false);
  };

  return (
    <Grid sx={menuStyle} onKeyDown={toggleDrawer(false)}>
      <IconButton aria-label="open the main menu" onClick={toggleDrawer(true)} size="large">
        <MenuIcon sx={iconStyle} />
      </IconButton>
      <Drawer open={isOpen} onClose={toggleDrawer(false)}>
        <SideNavigationMenuList closeDrawer={onClose} />
      </Drawer>
    </Grid>
  );
};

export default SideNavigationMenu;
