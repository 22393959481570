import { Grid, Link, SxProps, Theme, Typography } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledTable from 'components/shared/Table';
import { DASH_SOURCE } from 'constants/tooltips';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC } from 'react';
import { GetSinglePersonQuery } from 'types/generated/graphql';
import { formatDateWithOffset } from 'utils/general';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const leftAlignedHeaderStyle: SxProps<Theme> = {
  '& span': {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
};

type PersonnelJobHistoryProps = {
  personnel?: GetSinglePersonQuery['person'];
};

export const PersonnelProjectHistoryCard: FC<PersonnelJobHistoryProps> = ({ personnel }) => {
  const personnelProjectHistoryData = personnel?.dashProjectHistory ?? [];

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        searchable: true,
        display: 'excluded',
      },
    },
    {
      name: 'projectName',
      label: 'Project',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <Link
              href={`https://dash.henselphelps.com/#/projects/${personnelProjectHistoryData[dataIndex].dashProjectId}`}
              target="_blank"
            >
              <Typography>{personnelProjectHistoryData[dataIndex].projectName}</Typography>
            </Link>
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'personTitleOnProject',
      label: 'Role',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{personnelProjectHistoryData[dataIndex].personTitleOnProject ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'projectDistricts',
      label: 'Region',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{personnelProjectHistoryData[dataIndex]?.projectDistricts?.join(', ') ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'projectVerticalMarket',
      label: 'Vertical Markets',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{personnelProjectHistoryData[dataIndex].projectVerticalMarket ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'projectContractType',
      label: 'Contract Type',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return <Typography>{personnelProjectHistoryData[dataIndex].projectContractType ?? ''}</Typography>;
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'startDate',
      label: 'Start Date on Project',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return (
            personnelProjectHistoryData[dataIndex].startDate && (
              <Typography>{formatDateWithOffset(personnelProjectHistoryData[dataIndex].startDate ?? '')}</Typography>
            )
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'endDate',
      label: 'End Date on Project',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex: number) => {
          return (
            personnelProjectHistoryData[dataIndex].endDate && (
              <Typography>{formatDateWithOffset(personnelProjectHistoryData[dataIndex].endDate ?? '')}</Typography>
            )
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // customToolbar: () => {
    //   return (<Grid container justify="flex-end">TODO</Grid>)
    // },
    download: false,
    pagination: false,
    sortOrder: {
      name: 'endDate',
      direction: 'desc',
    },
  };

  return (
    <>
      <Grid container sx={containerStyle} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledTable
            title={<HelpTooltip tooltipText={DASH_SOURCE} titleText="Project History" />}
            columns={columns}
            data={personnelProjectHistoryData ?? []}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonnelProjectHistoryCard;
