import { FC } from 'react';

import { Grid, SxProps, Theme, Typography } from '@mui/material';
import ProjectDetailsCsvExportButton from 'components/ProjectDetails/ProjectDetailsCsvExportButton';
import ButtonPrimary from 'components/shared/ButtonPrimary';
import { LEAVE_PROJECT_ID } from 'constants/leaveProject';
import { GetSingleProjectQuery } from 'types/generated/graphql';

const projectNameStyle: SxProps<Theme> = () => ({
  fontSize: '30px',
  fontWeight: 'bold',
  color: 'rgb(105, 105, 105)',
  paddingLeft: '10px',
});

const buttonGridItemStyle: SxProps<Theme> = () => ({
  display: 'flex',
  justifyContent: 'center',
});

type ProjectDetailsHeaderProps = {
  setIsEditing: (x: boolean) => void;
  setIsDeleteProjectDialogOpen: (x: boolean) => void;
  project?: GetSingleProjectQuery['project'];
};

const ProjectDetailsHeader: FC<ProjectDetailsHeaderProps> = ({
  setIsEditing,
  setIsDeleteProjectDialogOpen,
  project,
}) => {
  const handleEditProjectButtonClick = () => {
    setIsEditing(true);
  };
  const handleDeleteProjectButtonClick = () => {
    setIsDeleteProjectDialogOpen(true);
  };

  const projectCsvData = project?.roles.map((role) => {
    return {
      'Project Full Name': project.name,
      'Employee Name': role.person?.name?.lastCommaFirst ?? '',
      'Employee PR Number': role.person?.employeePrNumber ?? '',
      'Role Position': role.roleFullDisplayName ?? '',
      'Role Begin Date': role.startDate,
      'Role End Date': role.endDate,
      Notes: role.notes ?? '',
      'Focus Area': role.focusArea ?? '',
      'Project Number': project.number ?? '',
      'Project Short Name': project?.shortName ?? '',
      'Project Region': project.districts.join(', '),
      'Project Status': project.status,
      'Project Start Date': project.startDate,
      'Project End Date': project.endDate,
      'Project Description': project.description ?? '',
      'Project Street Address 1': project.location?.streetAndNumber ?? '',
      'Project Street Address 2': project.location?.streetDetail ?? '',
      'Project City': project.location?.city ?? '',
      'Project State Abbr.': project.location?.stateOrProvince ?? '',
      'Project Zip': project.location?.zipOrPostalCode ?? '',
      'Vertical Markets': project.verticalMarkets.join(', '),
      'Contract Type': project.contractType ?? '',
    };
  });

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography sx={projectNameStyle}>{project?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          {project?.id !== LEAVE_PROJECT_ID && (
            <Grid item xs={4} sx={buttonGridItemStyle}>
              <ButtonPrimary label={'Edit Project'} onClick={handleEditProjectButtonClick}></ButtonPrimary>
            </Grid>
          )}
          <Grid item xs={project?.id !== LEAVE_PROJECT_ID ? 4 : 12} sx={buttonGridItemStyle}>
            <ProjectDetailsCsvExportButton
              fileName={`${project?.name} Details`}
              bom={true}
              data={projectCsvData}
              label={'Export Project'}
            />
          </Grid>
          {project?.id !== LEAVE_PROJECT_ID && (
            <Grid item xs={4} sx={buttonGridItemStyle}>
              <ButtonPrimary label={'Delete Project'} onClick={handleDeleteProjectButtonClick}></ButtonPrimary>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectDetailsHeader;
