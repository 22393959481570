import { DateTime } from 'luxon';

export const taskbarTemplate = (props: any) => {
  return (
    <div className="e-gantt-child-taskbar-inner-div e-gantt-child-taskbar" style={{ height: '100%' }}>
      <span
        className="e-task-label"
        style={{
          position: 'absolute',
          bottom: '0px',
          top: '3px',
          left: '10px',
          color: 'white',
          fontFamily: 'Roboto',
          fontSize: '13px',
          lineHeight: '11px',
          cursor: 'move',
        }}
      >
        {props.personnel === 'Unfilled'
          ? props.personnel + ' - ' + props.taskName
          : props.personnel === props.taskName
          ? props.personnel
          : props.personnel + ' - ' + props.taskName}
      </span>
    </div>
  );
};

export const parentTaskbarTemplate = (props: any) => {
  return (
    <div
      className="e-gantt-parent-taskbar-inner-div e-gantt-parent-taskbar"
      style={{
        height: '20px',
        margin: '0px',
        width: props.ganttProperties.autoWidth + 'px',
      }}
    >
      <div
        className="e-gantt-parent-progressbar-inner-div e-row-expand e-gantt-parent-progressbar"
        style={{ width: props.ganttProperties.progressWidth + 'px', height: '100%' }}
      ></div>
      <span
        className="e-task-label"
        style={{
          position: 'absolute',
          bottom: '0px',
          top: '3px',
          left: '10px',
          color: 'white',
          fontFamily: 'Roboto',
          fontSize: '13px',
          lineHeight: '11px',
          cursor: 'move',
        }}
      >
        {props.taskName}
      </span>
    </div>
  );
};

export const projectTooltipTemplate = (props: any) => {
  const project = props.taskName;
  const personnel = props.personnel;
  const role = props.taskName;
  const startDate = DateTime.fromJSDate(props.startDate).set({ day: 1 }).toLocaleString();
  const endDate = DateTime.fromJSDate(props.endDate).toLocaleString();
  const duration = props.duration;
  const progress = props.ganttProperties?.progress;

  const projectTooltipData =
    props.level === 0
      ? [
          { Project: project },
          { 'Start Date': startDate },
          { 'End Date': endDate },
          { Duration: duration },
          { Progress: progress },
        ]
      : [
          { Personnel: personnel },
          { Role: role },
          { 'Start Date': startDate },
          { 'End Date': endDate },
          { Duration: duration },
          { Progress: progress },
        ];
  return (
    <div>
      {projectTooltipData.map((item, index) => {
        return <div key={index}>{`${Object.keys(item)} : ${Object.values(item)}`}</div>;
      })}
    </div>
  );
};
