import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { FC } from 'react';

type ReassignToOldRoleProps = {
  startingReassignmentProjectAndRole: ProjectsGridRowData | undefined;
};

const ReassignRoleContainer: SxProps<Theme> = {
  marginBottom: '25px',
};

const ReassignToOldRole: FC<ReassignToOldRoleProps> = ({ startingReassignmentProjectAndRole }) => {
  return (
    <Grid container sx={ReassignRoleContainer}>
      <Grid item xs={12}>
        <Typography>
          Would you like to assign someone to{' '}
          {startingReassignmentProjectAndRole?.role?.person?.name?.lastCommaFirst ?? 'Unfilled'}'s old role?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {startingReassignmentProjectAndRole?.project.name} -{' '}
          {startingReassignmentProjectAndRole?.role?.roleFullDisplayName} - (
          {startingReassignmentProjectAndRole?.role?.startDate} - {startingReassignmentProjectAndRole?.role?.endDate})
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ReassignToOldRole;
