import { FC } from 'react';
import { GetSingleProjectQuery } from 'types/generated/graphql';

import { Grid } from '@mui/material';

type ProjectDetailsMapProps = {
  project?: GetSingleProjectQuery['project'];
};

const ProjectDetailsMap: FC<ProjectDetailsMapProps> = ({ project }) => {
  const projectAddress = `${project?.location?.streetAndNumber ?? ''}, ${project?.location?.city ?? ''}, ${
    project?.location?.stateOrProvince ?? ''
  } ${project?.location?.zipOrPostalCode ?? ''}`;

  return (
    <Grid item xs={6}>
      <iframe
        title="Google Maps"
        width="935"
        height="310"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCHezmO-FsvZyFPVyr6FkPBz5GcvvVYD1U&q=${projectAddress}`}
      ></iframe>
    </Grid>
  );
};

export default ProjectDetailsMap;
