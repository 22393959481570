import AddIcon from '@mui/icons-material/Add';
import { Grid, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledTable from 'components/shared/Table';
import { LINK_SOURCE } from 'constants/tooltips';
import TRAINING_DIALOG_VIEWS from 'constants/trainingDialogViews';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC, useState } from 'react';
import { GetSinglePersonQuery, PersonTraining } from 'types/generated/graphql';
import { closeTrainingDialog, getColumnFormattedTrainingData, openTrainingDialog } from 'utils/trainingTables';
import TrainingDialog from '../TrainingAndCertsDialog';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const expiredRowStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.error.light,
  fontSize: '0.875rem',
});

const expiringSoonStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.warning.light,
  fontSize: '0.875rem',
});

const leftAlignedHeaderStyle: SxProps<Theme> = {
  '& span': {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
};

const statusContainerStyle: SxProps<Theme> = {
  display: 'flex',
};

const tableTextStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
};

type PersonnelTrainingProps = {
  personnel?: GetSinglePersonQuery['person'];
};

const PersonnelTrainingsCard: FC<PersonnelTrainingProps> = ({ personnel }) => {
  const [trainingDialogIsOpen, setTrainingDialogIsOpen] = useState(false);
  const [trainingData, setTrainingData] = useState([]);
  const [trainingDialogView, setTrainingDialogView] = useState<any>();

  const { VIEW_TRAINING, ADD_TRAINING } = TRAINING_DIALOG_VIEWS;

  const personTrainings = personnel?.trainings ?? ([] as PersonTraining[]);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        searchable: true,
        display: 'excluded',
        download: false,
      },
    },
    {
      name: 'status',
      label: 'status',
      options: {
        filter: false,
        searchable: false,
        display: 'excluded',
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        filterOptions: {
          fullWidth: true,
        },
        download: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'awardedDate',
      label: 'Awarded Date',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        download: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'expirationDate',
      label: 'Expiration Date',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        filterOptions: {
          fullWidth: true,
        },
        download: true,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'statusLabel',
      label: 'Status',
      options: {
        filter: true,
        searchable: false,
        sort: true,
        download: true,
        customBodyRender: (value, tableMeta) => {
          const statusRowIndex = 1;
          const statusObject = tableMeta.rowData[statusRowIndex];
          return (
            <>
              {value === 'Expiring Soon' ? (
                <Tooltip
                  data-testid="expirationCount"
                  title={
                    statusObject.numberOfDaysUntilExpiration > 1
                      ? `${statusObject.numberOfDaysUntilExpiration} days until expiration`
                      : `${statusObject.numberOfDaysUntilExpiration} day until expiration`
                  }
                >
                  <Grid sx={statusContainerStyle}>
                    <Typography sx={tableTextStyle}>{value}</Typography>
                  </Grid>
                </Tooltip>
              ) : (
                <Grid sx={statusContainerStyle}>
                  <Typography sx={tableTextStyle}>{value}</Typography>
                </Grid>
              )}
            </>
          );
        },
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'files',
      label: 'Files',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        download: true,
        customBodyRender: (value) => <> {value && value.length > 0 ? <Grid>{value?.length}</Grid> : null}</>,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
    {
      name: 'files',
      label: 'FileObjects',
      options: {
        filter: false,
        display: 'excluded',
        download: false,
        setCellHeaderProps: () => {
          return { sx: leftAlignedHeaderStyle };
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    downloadOptions: {
      filename: `${personnel?.name?.lastCommaFirst ?? ''}-Trainings.csv`,
    },
    onRowClick: (rowData: any) => {
      openTrainingDialog(rowData, setTrainingData, setTrainingDialogIsOpen, setTrainingDialogView, VIEW_TRAINING);
    },
    customToolbar: () => {
      return (
        <Tooltip title={'Add Training'}>
          <IconButton
            onClick={() =>
              openTrainingDialog([], setTrainingData, setTrainingDialogIsOpen, setTrainingDialogView, ADD_TRAINING)
            }
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    setRowProps: (row: any) => {
      const [, expirationDate, , , , , , ,] = row;
      if (expirationDate.isExpired) {
        return { sx: expiredRowStyle };
      } else if (expirationDate.isExpiringSoon) {
        return { sx: expiringSoonStyle };
      }
      return {};
    },
    sortOrder: {
      name: 'statusLabel',
      direction: 'asc',
    },
  };

  return (
    <>
      <Grid container sx={containerStyle} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledTable
            title={<HelpTooltip tooltipText={LINK_SOURCE} titleText="Trainings" />}
            columns={columns}
            data={personTrainings ? getColumnFormattedTrainingData(personTrainings as PersonTraining[]) : []}
            options={options}
          />
          {trainingDialogIsOpen && (
            <TrainingDialog
              isOpen={trainingDialogIsOpen}
              setIsOpen={setTrainingDialogIsOpen}
              handleClose={() => closeTrainingDialog(setTrainingDialogIsOpen, setTrainingDialogView)}
              trainingData={trainingData}
              trainingDialogView={trainingDialogView}
              setTrainingDialogView={setTrainingDialogView}
              personnel={personnel}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PersonnelTrainingsCard;
