import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { LEAVE_PROJECT_ID } from 'constants/leaveProject';
import { DateTime } from 'luxon';
import { UseFormReset } from 'react-hook-form';
import { ReassignRoleAssignmentsType, ReassignRoleFormValues, ReassignRoleSummaryReassignmentsType } from 'types';
import { Person, Project, ProjectRole, ProjectRoleUpsertInput } from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';
import { REASSIGN_SUMMARY_STEP_INDEX, REASSIGN_TO_OLD_ROLE_STEP_INDEX } from '../ReassignRoleDialog';
import {
  createAddToLeave,
  createNewAssignment,
  getNewRoleUpsertInput,
  getRoleUpsertInput,
  getRoleUpsertInputWithReassignment,
} from '../reassignUtils';

type AddToLeaveOrNewProjectTypeParams = {
  INDEX_OF_TITLENAME: number;
  payrollTitleWatch: string;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  startingPersonnel: Person | undefined;
  transactionKey: string;
  leaveNewRoleProjectWatch: Project | undefined;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
};

const addToLeaveOrNewProject = ({
  INDEX_OF_TITLENAME,
  payrollTitleWatch,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  startingPersonnel,
  transactionKey,
  leaveNewRoleProjectWatch,
  reassignRoleSummaryReassignments,
  reassignmentProjectRoleMutations,
  setTransactionKey,
  setReassignRoleSummaryReassignments,
  setReassignmentProjectRoleMutations,
}: AddToLeaveOrNewProjectTypeParams) => {
  const newProjectRole = getNewRoleUpsertInput({
    roleName: payrollTitleWatch.split(' - ')[INDEX_OF_TITLENAME],
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: reassignProjectAndRole ? reassignProjectAndRole?.role?.person?.id : startingPersonnel?.id,
    transactionKey,
    projectId: leaveNewRoleProjectWatch?.id,
  });

  setTransactionKey(generateTransactionKey());

  const newAssignment = createNewAssignment({
    projectName: leaveNewRoleProjectWatch?.name,
    personnel: reassignProjectAndRole
      ? reassignProjectAndRole?.role?.person?.name?.lastCommaFirst
      : startingPersonnel?.name?.lastCommaFirst,
    roleName: payrollTitleWatch,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    newAssignments:
      reassignRoleSummaryReassignments.newAssignments && reassignRoleSummaryReassignments.newAssignments.length > 0
        ? [...reassignRoleSummaryReassignments.newAssignments, newAssignment]
        : [newAssignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [...reassignmentProjectRoleMutations, newProjectRole];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);
};

type AddToLeaveWillBeAvailableTypeParams = {
  dataSource: ProjectsGridRowData[];
  payrollTitleWatch: string;
  INDEX_OF_TITLENAME: number;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  transactionKey: string;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const addToLeaveWillBeAvailable = ({
  dataSource,
  payrollTitleWatch,
  INDEX_OF_TITLENAME,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  transactionKey,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: AddToLeaveWillBeAvailableTypeParams) => {
  const leaveProject = dataSource.find((data) => data.project.id === LEAVE_PROJECT_ID)?.project;
  const newProjectRole = getNewRoleUpsertInput({
    roleName: payrollTitleWatch.split(' - ')[INDEX_OF_TITLENAME],
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    transactionKey,
    projectId: leaveProject?.id,
  });

  setTransactionKey(generateTransactionKey());

  const oldProjectRole = getRoleUpsertInput({
    roleName: reassignProjectAndRole?.role?.roleName,
    startDate: reassignProjectAndRole?.role?.startDate,
    endDate: startDateWatch?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    projectId: reassignProjectAndRole?.project?.id,
    id: reassignProjectAndRole?.role?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    newProjectRole,
    oldProjectRole,
  ];
  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const addToLeaveAssignment = createAddToLeave({
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    notes: 'Will be available after leave',
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    addToLeave:
      reassignRoleSummaryReassignments.addToLeave && reassignRoleSummaryReassignments.addToLeave.length > 0
        ? [...reassignRoleSummaryReassignments.addToLeave, addToLeaveAssignment]
        : [addToLeaveAssignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type AddToLeaveWillResumeRoleTypeParams = {
  dataSource: ProjectsGridRowData[];
  payrollTitleWatch: string;
  INDEX_OF_TITLENAME: number;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  transactionKey: string;
  leaveNewRoleProjectWatch: Project | undefined;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
};

const addToLeaveWillResumeRole = ({
  dataSource,
  payrollTitleWatch,
  INDEX_OF_TITLENAME,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  transactionKey,
  leaveNewRoleProjectWatch,
  reassignRoleSummaryReassignments,
  reassignmentProjectRoleMutations,
  setTransactionKey,
  setReassignRoleSummaryReassignments,
  setReassignmentProjectRoleMutations,
}: AddToLeaveWillResumeRoleTypeParams) => {
  const leaveProject = dataSource.find((data) => data.project.id === LEAVE_PROJECT_ID)?.project;
  const newProjectRole = getNewRoleUpsertInput({
    roleName: payrollTitleWatch.split(' - ')[INDEX_OF_TITLENAME],
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    transactionKey,
    projectId: leaveNewRoleProjectWatch?.id,
  });

  setTransactionKey(generateTransactionKey());

  const reassignment: ReassignRoleAssignmentsType = {
    person: (reassignProjectAndRole?.role?.person as Person) ?? undefined,
    newAssignment: {
      project: (leaveProject as Project) ?? undefined,
      role: newProjectRole,
      startDate: startDateWatch?.toISODate() ?? '',
      endDate: endDateWatch?.toISODate() ?? '',
    },
  };

  const addToLeaveAssignment = createAddToLeave({
    personnel: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    notes: 'Will resume role after leave',
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    addToLeave:
      reassignRoleSummaryReassignments.addToLeave && reassignRoleSummaryReassignments.addToLeave.length > 0
        ? [...reassignRoleSummaryReassignments.addToLeave, addToLeaveAssignment]
        : [addToLeaveAssignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  const newProjectRoleUpsert = getRoleUpsertInputWithReassignment(
    reassignment,
    transactionKey,
    reassignment.newAssignment?.project?.id,
  );

  setTransactionKey(generateTransactionKey());

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [...reassignmentProjectRoleMutations, newProjectRoleUpsert];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);
};

// This step is the add to leave or new role on project step
// The purpose of this step to add person to the leave project or add them to another project with a role, start and end date.
// To enter this step you can click on the add to leave button on a personnel's details page or if you would like to assign a personnel to a new
// role after removing them from their current role
// The first check is to see what the selection from the dropdown was. If no dropdown is selected then this is adding a person to a new role
// on an existing project
// If the leave watch dropdown has Mark as available selected then this logic will do three things. 1) Add the person to the leave project,
// 2) Adjust their current role to end when their leave starts, and 3) Add an unfilled role that will start when the leave starts and ends when
// the old role ends
// If the leave watch dropdown has Resume role selected then this logic will add this person to the leave project and not edit their current role
type ReassignToLeaveOrNewRoleStepTypeParams = {
  afterLeaveWatch: string;
  payrollTitleWatch: string;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  transactionKey: string;
  leaveNewRoleProjectWatch: Project | undefined;
  startingPersonnel: Person | undefined;
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  dataSource: ProjectsGridRowData[];
  startingReassignmentProjectAndRole: ProjectsGridRowData | undefined;
  defaultValues: ReassignRoleFormValues;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignProjectAndRole: (x: ProjectsGridRowData) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  reset: UseFormReset<ReassignRoleFormValues>;
};

export const reassignToLeaveOrNewRoleStep = ({
  afterLeaveWatch,
  payrollTitleWatch,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  transactionKey,
  leaveNewRoleProjectWatch,
  startingPersonnel,
  reassignRoleSummaryReassignments,
  reassignmentProjectRoleMutations,
  dataSource,
  startingReassignmentProjectAndRole,
  defaultValues,
  setReassignRoleSummaryReassignments,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignProjectAndRole,
  setActiveStep,
  reset,
}: ReassignToLeaveOrNewRoleStepTypeParams) => {
  const INDEX_OF_TITLENAME = 1;
  if (!afterLeaveWatch) {
    addToLeaveOrNewProject({
      INDEX_OF_TITLENAME,
      payrollTitleWatch,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      startingPersonnel,
      transactionKey,
      leaveNewRoleProjectWatch,
      reassignRoleSummaryReassignments,
      reassignmentProjectRoleMutations,
      setTransactionKey,
      setReassignRoleSummaryReassignments,
      setReassignmentProjectRoleMutations,
    });
  } else if (afterLeaveWatch === 'Mark as available') {
    addToLeaveWillBeAvailable({
      dataSource,
      payrollTitleWatch,
      INDEX_OF_TITLENAME,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      transactionKey,
      reassignmentProjectRoleMutations,
      reassignRoleSummaryReassignments,
      setTransactionKey,
      setReassignmentProjectRoleMutations,
      setReassignRoleSummaryReassignments,
    });
  } else if (afterLeaveWatch === 'Resume role') {
    addToLeaveWillResumeRole({
      dataSource,
      payrollTitleWatch,
      INDEX_OF_TITLENAME,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      transactionKey,
      leaveNewRoleProjectWatch,
      reassignRoleSummaryReassignments,
      reassignmentProjectRoleMutations,
      setTransactionKey,
      setReassignRoleSummaryReassignments,
      setReassignmentProjectRoleMutations,
    });
  }

  if (leaveNewRoleProjectWatch?.id === LEAVE_PROJECT_ID && afterLeaveWatch === 'Mark as available') {
    setReassignProjectAndRole({
      project: startingReassignmentProjectAndRole?.project as Project,
      role: {
        ...startingReassignmentProjectAndRole?.role,
        startDate: startDateWatch?.toISODate(),
        endDate: endDateWatch?.toISODate(),
      } as ProjectRole,
    });
    setActiveStep(REASSIGN_TO_OLD_ROLE_STEP_INDEX);
    reset(defaultValues);
  } else {
    setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
    reset(defaultValues);
  }
};
