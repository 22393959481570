import { PRIMARY_APP_BAR_HEIGHT_PX, ROOT_CONTAINER_PADDING_PX } from 'constants/themes/dimensions';
import { FC } from 'react';

import { Grid, SxProps, Theme } from '@mui/material';

type Props = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const rootStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  height: `calc(100vh - ${PRIMARY_APP_BAR_HEIGHT_PX}px)`,
  marginTop: `${PRIMARY_APP_BAR_HEIGHT_PX}px`,
  padding: `${ROOT_CONTAINER_PADDING_PX}px`,
});

export const RootContainer: FC<Props> = ({ children }) => {
  return <Grid sx={rootStyle}>{children}</Grid>;
};

export default RootContainer;
