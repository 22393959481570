import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import StyledDatePicker from 'components/shared/KeyboardDatePicker';
import { CONSULTANT_PERSONNEL_ID } from 'constants/consultant';
import { ROLES, ROLES_BY_NAME } from 'constants/roles';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { ReassignRoleFormValues } from 'types';
import { Person, ProjectRole } from 'types/generated/graphql';

import { Autocomplete, Grid, Link, SxProps, TextField, Theme, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type ReassignRoleProps = {
  control: Control<ReassignRoleFormValues, any>;
  setValue: UseFormSetValue<ReassignRoleFormValues>;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  personnelList: Person[];
  personnelWatch: {
    person: Person | undefined;
    role: ProjectRole | undefined;
  };
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
};

type ReassignRoleListType = {
  personOptionLabel: string;
  person: Person | undefined;
  role: ProjectRole | undefined;
  rank: number;
};

const reassignRoleHeaderContainer: SxProps<Theme> = {
  marginBottom: '20px',
};

const ReassignRole: FC<ReassignRoleProps> = ({
  control,
  setValue,
  reassignProjectAndRole,
  personnelList,
  personnelWatch,
  startDateWatch,
  endDateWatch,
}) => {
  const [roleStartDate, setRoleStartDate] = useState(reassignProjectAndRole?.role?.startDate);
  const [personnel, setPersonnel] = useState<Person>();

  const updatePrTitles = (personnelList: Person[]) => {
    return personnelList.map((person) => {
      let title = person.prJobTitle ?? '';
      if (person.possiblePromotionDate <= roleStartDate) {
        title = person.possiblePromotionTitle ?? title;
      }
      person.prJobTitleAbbreviation = ROLES_BY_NAME[title]?.abbreviation ?? '';
      person.prJobTitleFullDisplayName = ROLES_BY_NAME[title]?.fullDisplayName ?? '';
      return person;
    });
  };

  const filterAndSortRoleList = (
    reassignProjectAndRole: ProjectsGridRowData | undefined,
    personnelList: Person[],
    startDateWatch: DateTime | null,
    endDateWatch: DateTime | null,
  ) => {
    return updatePrTitles(personnelList)
      .filter((person) => person.id !== reassignProjectAndRole?.role?.person?.id)
      .map((person) => {
        const startDateToCheck = startDateWatch?.plus({ days: 30 });
        const endDateToCheck = endDateWatch?.minus({ days: 30 });
        const assignedRole = person.roles.find(
          (role) =>
            startDateToCheck &&
            endDateToCheck &&
            DateTime.fromISO(role.startDate) < endDateToCheck &&
            startDateToCheck < DateTime.fromISO(role.endDate),
        );

        const isAvailable = person.roles.length > 0 && assignedRole ? false : true;
        if (person.id === CONSULTANT_PERSONNEL_ID) {
          return {
            personOptionLabel: 'Consultant (available)',
            person: person,
            role: undefined,
            rank: 5,
          };
        } else if (person.prJobTitleFullDisplayName === reassignProjectAndRole?.role?.roleFullDisplayName) {
          if (isAvailable) {
            return {
              personOptionLabel: `${person.name?.lastCommaFirst} - ${person.prJobTitleAbbreviation} (available)`,
              person: person,
              role: undefined,
              rank: 1,
            };
          } else {
            return {
              personOptionLabel: `${person.name?.lastCommaFirst} - ${person.prJobTitleAbbreviation} (assigned)`,
              person: person,
              role: assignedRole,
              rank: 2,
            };
          }
        } else {
          if (isAvailable) {
            return {
              personOptionLabel: `${person.name?.lastCommaFirst} - ${person.prJobTitleAbbreviation} (available)`,
              person: person,
              role: undefined,
              rank: 3,
            };
          } else {
            return {
              personOptionLabel: `${person.name?.lastCommaFirst} - ${person.prJobTitleAbbreviation} (assigned)`,
              person: person,
              role: assignedRole,
              rank: 4,
            };
          }
        }
      })
      .sort((a, b) => {
        if (a.rank === b.rank) {
          if (
            a.person.prJobTitleFullDisplayName &&
            b.person.prJobTitleFullDisplayName &&
            a.person.prJobTitleFullDisplayName === b.person.prJobTitleFullDisplayName
          ) {
            return a.person.name?.lastCommaFirst &&
              b.person.name?.lastCommaFirst &&
              a.person.name?.lastCommaFirst < b.person.name?.lastCommaFirst
              ? -1
              : 1;
          } else {
            return a.person.prJobTitleFullDisplayName &&
              b.person.prJobTitleFullDisplayName &&
              a.person.prJobTitleFullDisplayName < b.person.prJobTitleFullDisplayName
              ? -1
              : 1;
          }
        } else {
          return a.rank < b.rank ? -1 : 1;
        }
      });
  };

  const shouldDisableDate = (date?: DateTime) => {
    return (endDateWatch && date && date > endDateWatch) ?? false;
  };

  const shouldDisableMonth = (date?: DateTime) => {
    const monthOfDate = date?.month;
    const yearOfDate = date?.year;

    const monthOfEndDateToCheckAgainst = endDateWatch?.month;
    const yearOfEndDateToCheckAgainst = endDateWatch?.year;

    if (
      endDateWatch &&
      date &&
      monthOfDate &&
      yearOfDate &&
      monthOfEndDateToCheckAgainst &&
      yearOfEndDateToCheckAgainst
    ) {
      return monthOfDate > monthOfEndDateToCheckAgainst && yearOfDate > yearOfEndDateToCheckAgainst;
    } else {
      return false;
    }
  };

  const shouldDisableYear = (date?: DateTime) => {
    const yearOfDate = date?.year;

    const yearOfEndDateToCheckAgainst = endDateWatch?.year;
    if (endDateWatch && date && yearOfDate && yearOfEndDateToCheckAgainst) {
      return yearOfDate > yearOfEndDateToCheckAgainst;
    } else {
      return false;
    }
  };

  const [personList, setPersonList] = useState<ReassignRoleListType[]>(
    filterAndSortRoleList(reassignProjectAndRole, personnelList as Person[], startDateWatch, endDateWatch),
  );

  const [reassignFromCurrentRoleList, setReassignFromCurrentRoleList] = useState<string[]>([
    'Leave existing roles unchanged',
  ]);

  useEffect(() => {
    const startDateValue =
      DateTime.fromISO(reassignProjectAndRole?.role?.startDate) < DateTime.now()
        ? DateTime.now()
        : DateTime.fromISO(reassignProjectAndRole?.role?.startDate);
    setPersonList(
      filterAndSortRoleList(reassignProjectAndRole, personnelList as Person[], startDateValue, endDateWatch),
    );
    setValue('startDate', startDateValue);
    setValue('endDate', DateTime.fromISO(reassignProjectAndRole?.role?.endDate));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelList, reassignProjectAndRole]);

  useEffect(() => {
    if (personnelWatch.role) {
      const roleAbbreviation = ROLES.find((role) => role.roleName === personnelWatch?.role?.roleName)?.abbreviation;
      setReassignFromCurrentRoleList([
        'Leave existing roles unchanged',
        `${personnelWatch?.role?.project.name} - ${roleAbbreviation} (${personnelWatch?.role?.startDate} - ${personnelWatch?.role?.endDate})`,
      ]);
    } else {
      setReassignFromCurrentRoleList(['Leave existing roles unchanged']);
    }
  }, [personnelWatch]);

  useEffect(() => {
    const personList: ReassignRoleListType[] = filterAndSortRoleList(
      reassignProjectAndRole,
      personnelList as Person[],
      startDateWatch,
      endDateWatch,
    );
    setPersonList(personList);

    const foundPerson = personList.find(
      (personList) => personList?.person && personList?.person?.id === personnelWatch?.person?.id,
    );

    setValue('personnel', {
      role: foundPerson?.role,
      person: foundPerson?.person,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateWatch, endDateWatch]);

  const foundProjectDistrictsFromProjectAndRole = reassignProjectAndRole?.project?.districts?.filter(
    (district: string) => district !== personnel?.prDistrict,
  );

  const tdyToDisplay =
    foundProjectDistrictsFromProjectAndRole && foundProjectDistrictsFromProjectAndRole.length >= 1
      ? `${personnel?.prDistrict} => ${foundProjectDistrictsFromProjectAndRole?.join(', ')}`
      : '';

  return (
    <>
      <Grid container sx={reassignRoleHeaderContainer}>
        <Grid item xs={12}>
          <Typography align={'center'} variant="h5">
            Personnel assignment {reassignProjectAndRole?.project.name} -{' '}
            {reassignProjectAndRole?.role?.roleAbbreviation}
          </Typography>
        </Grid>
      </Grid>
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item textAlign="center" xs={12}>
              <Link
                href={`personnel?${(reassignProjectAndRole?.project?.districts ?? [''])
                  .map((district) => `region=${district}`)
                  .join('&')}&tab=1&role=${reassignProjectAndRole?.role
                  ?.roleFullDisplayName}&startDate=${reassignProjectAndRole?.role
                  ?.startDate}&endDate=${reassignProjectAndRole?.role?.endDate}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go To Free Personnel Grid
              </Link>
            </Grid>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Personnel
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="personnel"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="personnels"
                    size="small"
                    options={personList}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.personOptionLabel}
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      setValue('personnel', {
                        role: newValue?.role,
                        person: newValue?.person,
                      });
                      setPersonnel(newValue?.person);
                      if (!newValue?.role || newValue?.person?.id === CONSULTANT_PERSONNEL_ID) {
                        setValue('reassignFromCurrentRole', '');
                      }
                    }}
                    value={
                      value.person === undefined
                        ? null
                        : personList.find((person) => person.person?.id === value.person?.id)
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.person?.id === value?.person?.id;
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.person?.id}>
                          {option.personOptionLabel}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        {personnel && tdyToDisplay && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item alignSelf="center" textAlign={'center'} xs={4}>
                TDY
              </Grid>
              <Grid item xs={8}>
                <Typography>{tdyToDisplay}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Start Date
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Grid item>
                    <DatePicker
                      shouldDisableDate={shouldDisableDate}
                      shouldDisableMonth={shouldDisableMonth}
                      shouldDisableYear={shouldDisableYear}
                      value={value}
                      onChange={(date: DateTime | null) => {
                        onChange(date);
                        setValue('startDate', date);
                        setRoleStartDate(date?.toSQLDate());
                      }}
                      format="D"
                      slotProps={{
                        actionBar: {
                          actions: ['clear', 'cancel', 'today'],
                        },
                        textField: { fullWidth: true },
                      }}
                    />
                  </Grid>
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              End Date
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledDatePicker
                    value={value}
                    onChange={(newDate) => {
                      onChange(newDate);
                      setValue('endDate', newDate);
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {personnelWatch.role && personnelWatch?.person?.id !== CONSULTANT_PERSONNEL_ID && (
        <Grid container>
          <Typography>Reassign from a current role?</Typography>
          <Grid item xs={12}>
            <Controller
              name="reassignFromCurrentRole"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="reassignFromCurrentRole"
                  size="small"
                  options={reassignFromCurrentRoleList}
                  filterSelectedOptions
                  getOptionLabel={(option) => option}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue('reassignFromCurrentRole', newValue ?? '');
                  }}
                  value={value ?? ''}
                  isOptionEqualToValue={(option: string, value: string) => {
                    return option === value;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={props.id + option}>
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              )}
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ReassignRole;
