import WarningIcon from '@mui/icons-material/Warning';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project, useDeleteProjectMutation } from 'types/generated/graphql';

import { FormControlLabel, Grid, Switch, SxProps, Theme, Typography } from '@mui/material';

const centerTextInGridStyles: SxProps<Theme> = {
  display: 'flex',
};

const noticeStyle: SxProps<Theme> = (theme: Theme) => ({
  borderLeft: '6px solid #B25623',
  backgroundColor: '#DF6C2C',
  padding: theme.spacing(2),
});

const dangerTitleStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

const dangerContentStyle: SxProps<Theme> = {
  fontSize: '0.75rem',
};

type DeleteProjectDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  project?: Project;
};

const DeleteProjectDialog: FC<DeleteProjectDialogProps> = ({ isOpen, setIsOpen, project }) => {
  const { displayToast } = useToast();
  const [deleteProjectMutation] = useDeleteProjectMutation();
  const [switchState, setSwitchState] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    setSwitchState(false);
  };

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };

  const onSubmitSuccess = () => {
    deleteProjectMutation({ variables: { id: project?.id ?? '' } })
      .then(() => {
        handleClose();
        displayToast('The project was deleted successfully', 'success');
        navigate(`/projects`);
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to delete the project. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      maxWidth={'xs'}
      title={'Delete Project'}
      content={`You are deleting the project ${project?.name}. This action cannot be undone.`}
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={noticeStyle} direction="column">
              <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <WarningIcon />
                  </Grid>
                  <Grid item>
                    <Typography sx={dangerTitleStyle}>Danger</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography sx={dangerContentStyle}>
                  This action <span style={{ fontWeight: 'bold' }}>permanently deletes project {project?.name}</span>{' '}
                  from the database, including all project roles associated with the project. A project should normally
                  only be deleted if it was created in error.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={centerTextInGridStyles} justifyContent={'center'}>
            <Typography>Are you sure you want to continue?</Typography>
          </Grid>
          <Grid item xs={12} sx={centerTextInGridStyles} justifyContent={'center'}>
            <FormControlLabel
              required
              control={<Switch checked={switchState} onChange={handleSwitchChange} />}
              label="I'm Sure"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary label={'Delete'} type="submit" onClick={onSubmitSuccess} disabled={!switchState} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default DeleteProjectDialog;
