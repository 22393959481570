import { Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { ClimbingBoxLoader } from 'react-spinners';

const loadingSpinnerStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  height: 'calc(100vh - 160px)',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 152px)',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 104px)',
  },
});

const fullScreenLoadingSpinnerStyle: SxProps<Theme> = (theme: Theme) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
});

function LoadingSpinner({ isFullScreen = false }) {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      data-testid="loading-spinner"
      sx={isFullScreen ? fullScreenLoadingSpinnerStyle : loadingSpinnerStyle}
    >
      <Grid item>
        <ClimbingBoxLoader color={theme.palette.primary.main} loading={true} size={10} />
      </Grid>
      <Grid item>
        <Typography color="primary">Loading...</Typography>
      </Grid>
    </Grid>
  );
}

export default LoadingSpinner;
