import { DISTRICTS } from 'constants/districts';
import { ROLES } from 'constants/roles';

import { DropDownList, DropDownTree } from '@syncfusion/ej2-dropdowns';

export const projectDropdownOptionsTemplate = (id: number, option: string) => {
  return { id: id, option: option };
};

export const projectRolesOptions = ROLES.map((roleItem, idx) =>
  projectDropdownOptionsTemplate(idx + 1, roleItem.fullDisplayName),
);

const projectDistrictOptions = DISTRICTS.map((district, idx) =>
  projectDropdownOptionsTemplate(idx + 1, district.number),
);

export const districtOptions = { dataSource: projectDistrictOptions, value: 'option', text: 'option' };

export const projectDropdownTemplate = (
  projectOptions: any,
  placeholder: string,
  showCheckBox: boolean,
  selectedOption?: string,
) => {
  let inputElem: HTMLInputElement;
  let projectDropdown: DropDownTree | DropDownList;

  return {
    create: () => {
      inputElem = document.createElement('input');
      return inputElem;
    },
    read: () => {
      return projectDropdown.value;
    },
    destroy: () => {
      projectDropdown?.destroy();
    },
    write: (args: any) => {
      showCheckBox
        ? (projectDropdown = new DropDownTree({
            fields: projectOptions,
            placeholder: placeholder,
            showCheckBox: showCheckBox,
            value: Array.isArray(args.rowData[args.column.field])
              ? args.rowData[args.column.field]
              : args.rowData[args.column.field]?.split(', '),
          }))
        : (projectDropdown = new DropDownList({
            dataSource: projectOptions,
            fields: { value: selectedOption },
            placeholder: placeholder,
            value: args.rowData[args.column.field],
            allowFiltering: true,
            filterType: 'Contains',
          }));
      projectDropdown.appendTo(inputElem);
    },
  };
};

export const projectToolbarOptions = [
  'Search',
  'ZoomIn',
  'ZoomOut',
  'ZoomToFit',
  { text: 'Go To Today', tooltipText: 'Go To Today', id: 'Today', prefixIcon: 'e-calendar' },
  'Edit',
  'Update',
  'Delete',
  'Cancel',
  'CollapseAll',
  {
    text: 'Add Project',
    tooltipText: 'Add Project',
    id: 'AddProject',
    prefixIcon: 'e-add',
  },
  { text: 'Add Project Roles', tooltipText: 'Add Project Roles', id: 'AddProjectRoles', prefixIcon: 'e-add' },
  {
    text: 'Mass Project Upload',
    tooltipText: 'Mass Project Upload',
    id: 'MassProjectUpload',
    prefixIcon: 'e-upload-1',
  },
  {
    text: 'Edit Taskbar',
    tooltipText: 'Edit Taskbar',
    id: 'EditTaskbar',
    prefixIcon: 'e-edit',
  },
  {
    text: 'Exit Edit Taskbar',
    tooltipText: 'Exit Edit Taskbar',
    id: 'ExitEditTaskbar',
    prefixIcon: 'e-cancel',
    disabled: true,
  },
  'PdfExport',
];

export const labelSettings = {
  // eslint-disable-next-line no-template-curly-in-string
  taskLabel: '${taskData.name}',
};

export const taskFields = {
  id: 'taskID',
  name: 'taskName',
  duration: 'duration',
  startDate: 'startDate',
  endDate: 'endDate',
  child: 'subtasks',
  entityType: 'entityType',
  indicators: 'indicators',
};

export const addEditDialogFields = {
  type: 'General',
  headerText: 'General',
  fields: [
    'name',
    'roles',
    'project',
    'personnel',
    'startDate',
    'endDate',
    'duration',
    'districts',
    'notes',
    'focusArea',
  ],
};
