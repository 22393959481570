import { useProjectListResultQuery } from 'types/generated/graphql';

const useProjects = (projectIds: string | string[], districts: string | string[]) => {
  const {
    data: { projectListResult: Project } = {},
    loading,
    error,
  } = useProjectListResultQuery({
    variables: {
      projectIds: projectIds,
      districts: districts,
    },
    fetchPolicy: 'no-cache',
  });

  return { projectListResult: Project, loading, error };
};

export default useProjects;
