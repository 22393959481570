import { Grid, SxProps, Theme } from '@mui/material';
import PersonnelScrollingMiddleSection from 'components/Personnel/PersonnelScrollingMiddleSection';
import PersonnelStaticLeftSection from 'components/Personnel/PersonnelStaticLeftSection';
import PersonnelStaticRightSection from 'components/Personnel/PersonnelStaticRightSection';
import UnauthorizedPageNotice from 'components/common/UnauthorizedPageNotice';
import ButtonPrimary from 'components/shared/ButtonPrimary';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useRoles from 'hooks/useRoles';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Person, useGetSinglePersonQuery } from 'types/generated/graphql';

const displayFlexStyles: SxProps<Theme> = {
  height: '100%',
  display: 'flex',
};

type useParamsProps = { personnelId: string };

export const PersonnelDetailsPage: FC = () => {
  const { personnelId } = useParams<useParamsProps>();
  const { isOnlyCraft } = useRoles();
  const navigate = useNavigate();
  const { data, loading: isPersonnelDetailsLoading } = useGetSinglePersonQuery({
    variables: {
      id: personnelId as string,
    },
  });

  const personnel = data?.person as Person;

  const canAccessPersonnelDetailsPage = (isOnlyCraft && personnel?.isCraft) || !isOnlyCraft;

  return (
    <>
      {isPersonnelDetailsLoading ? (
        <LoadingSpinner />
      ) : canAccessPersonnelDetailsPage ? (
        <>
          <Grid container sx={displayFlexStyles}>
            <Grid container sx={displayFlexStyles}>
              <PersonnelStaticLeftSection personnel={personnel} isOnlyCraft={isOnlyCraft} />
              <PersonnelScrollingMiddleSection personnel={personnel} />
              <PersonnelStaticRightSection personnel={personnel} />
            </Grid>
          </Grid>
        </>
      ) : (
        <UnauthorizedPageNotice unauthorizedMessage="You do not have access to this page. Please go to the Personnel List to search for a person.">
          <ButtonPrimary onClick={() => navigate(`/personnel`)} label={'Go to Personnel List'}></ButtonPrimary>
        </UnauthorizedPageNotice>
      )}
    </>
  );
};

export default PersonnelDetailsPage;
