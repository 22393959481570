import { loginRequest } from 'config/msalConfig';
import { useEffect, useState } from 'react';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

const useAdAccessToken = () => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    instance
      .acquireTokenSilent(loginRequest)
      .then((response) => {
        if (response) {
          setAccessToken(response.accessToken);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(loginRequest);
        }
      });
  }, [instance]);

  return { accessToken };
};

export default useAdAccessToken;
