import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import StyledDatePicker from 'components/shared/KeyboardDatePicker';
import { LEAVE_PROJECT_ID } from 'constants/leaveProject';
import { ROLES_BY_NAME, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { ReassignRoleAssignmentsType, ReassignRoleFormValues } from 'types';
import { Person, Project } from 'types/generated/graphql';

import { Autocomplete, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

type ReassignToLeaveOrNewRoleProps = {
  control: Control<ReassignRoleFormValues, any>;
  setValue: UseFormSetValue<ReassignRoleFormValues>;
  currentProjectAndRoleListFromDatasource: ProjectsGridRowData[];
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  reassignRoleAssignments: ReassignRoleAssignmentsType[];
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  startingPersonnel: Person;
  leaveNewRoleProjectWatch: Project | undefined;
  shouldShowAfterLeaveDropdown: boolean;
  setShouldShowAfterLeaveDropdown: (x: boolean) => void;
  selectedEmployeeType: string | null;
};

const reassignRoleHeaderContainer: SxProps<Theme> = {
  marginBottom: '20px',
};

const AFTER_LEAVE_DROPDOWN_LIST = ['Resume role', 'Mark as available'];

const createUniqueListOfProjects = (currentProjectAndRoleListFromDatasource: ProjectsGridRowData[]) => {
  const filteredList = new Map(
    currentProjectAndRoleListFromDatasource.map((data: ProjectsGridRowData) => [
      data.project.name,
      data.project as Project,
    ]),
  );
  const filteredListValues: Project[] = [...filteredList.values()];
  return filteredListValues;
};

const ReassignToLeaveOrNewRole: FC<ReassignToLeaveOrNewRoleProps> = ({
  control,
  currentProjectAndRoleListFromDatasource,
  setValue,
  reassignProjectAndRole,
  reassignRoleAssignments,
  startDateWatch,
  endDateWatch,
  startingPersonnel,
  leaveNewRoleProjectWatch,
  shouldShowAfterLeaveDropdown,
  setShouldShowAfterLeaveDropdown,
  selectedEmployeeType,
}) => {
  const [projectList, setProjectList] = useState<Project[]>(
    createUniqueListOfProjects(currentProjectAndRoleListFromDatasource),
  );

  const leaveProject = projectList.find((project) => project.id === LEAVE_PROJECT_ID);
  const personnelPrJobTitleIsCraft = ROLES_BY_NAME[startingPersonnel?.prJobTitle ?? '']?.type === 'CRAFT';

  const shouldDisableDate = (date?: DateTime) => {
    return (endDateWatch && date && date > endDateWatch) ?? false;
  };

  const shouldDisableMonth = (date?: DateTime) => {
    const monthOfDate = date?.month;
    const yearOfDate = date?.year;

    const monthOfEndDateToCheckAgainst = endDateWatch?.month;
    const yearOfEndDateToCheckAgainst = endDateWatch?.year;

    if (
      endDateWatch &&
      date &&
      monthOfDate &&
      yearOfDate &&
      monthOfEndDateToCheckAgainst &&
      yearOfEndDateToCheckAgainst
    ) {
      return monthOfDate > monthOfEndDateToCheckAgainst && yearOfDate > yearOfEndDateToCheckAgainst;
    } else {
      return false;
    }
  };

  const shouldDisableYear = (date?: DateTime) => {
    const yearOfDate = date?.year;

    const yearOfEndDateToCheckAgainst = endDateWatch?.year;
    if (endDateWatch && date && yearOfDate && yearOfEndDateToCheckAgainst) {
      return yearOfDate > yearOfEndDateToCheckAgainst;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setProjectList(createUniqueListOfProjects(currentProjectAndRoleListFromDatasource));
  }, [currentProjectAndRoleListFromDatasource]);

  useEffect(() => {
    setValue(
      'payrollTitle',
      reassignProjectAndRole?.role?.person?.prJobTitleFullDisplayName ??
        startingPersonnel?.prJobTitleFullDisplayName ??
        '',
    );
    setValue('startDate', DateTime.fromISO(reassignProjectAndRole?.project?.startDate ?? DateTime.now()));
    setValue('endDate', DateTime.fromISO(reassignProjectAndRole?.project?.endDate ?? DateTime.now()));
    setValue(
      'leaveNewRoleProject',
      reassignRoleAssignments.length > 0 ? (reassignProjectAndRole?.project as Project) ?? '' : leaveProject,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reassignProjectAndRole]);

  useEffect(() => {
    if (
      leaveNewRoleProjectWatch?.id === LEAVE_PROJECT_ID &&
      reassignProjectAndRole?.role?.startDate &&
      reassignProjectAndRole?.role?.endDate &&
      endDateWatch &&
      startDateWatch &&
      DateTime.fromISO(reassignProjectAndRole?.role?.startDate) < endDateWatch &&
      startDateWatch < DateTime.fromISO(reassignProjectAndRole?.role?.endDate)
    ) {
      setShouldShowAfterLeaveDropdown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reassignProjectAndRole, startDateWatch, endDateWatch]);

  return (
    <>
      <Grid container sx={reassignRoleHeaderContainer}>
        <Grid item xs={12}>
          <Typography align={'center'} variant="h5">
            {reassignProjectAndRole
              ? reassignProjectAndRole?.role?.person?.name?.lastCommaFirst
              : startingPersonnel.name?.lastCommaFirst}
            's Assignment
          </Typography>
        </Grid>
      </Grid>
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Project
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="leaveNewRoleProject"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="leaveNewRoleProject"
                    size="small"
                    defaultValue={leaveProject}
                    options={projectList}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      setValue('leaveNewRoleProject', newValue ?? undefined);
                      if (newValue) {
                        setValue('startDate', DateTime.fromISO(newValue.startDate));
                        setValue('endDate', DateTime.fromISO(newValue.endDate));
                      }
                      if (newValue?.id === LEAVE_PROJECT_ID) {
                        setShouldShowAfterLeaveDropdown(true);
                      } else {
                        setShouldShowAfterLeaveDropdown(false);
                        setValue('afterLeave', '');
                      }
                    }}
                    value={value}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Role
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="payrollTitle"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="payrollTitle"
                    size="small"
                    options={
                      startingPersonnel
                        ? ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(personnelPrJobTitleIsCraft).sort((a, b) =>
                            a < b ? -1 : 1,
                          )
                        : ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft').sort((a, b) =>
                            a < b ? -1 : 1,
                          )
                    }
                    getOptionLabel={(value) => value}
                    filterSelectedOptions
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      setValue('payrollTitle', newValue ?? '');
                    }}
                    value={value}
                    isOptionEqualToValue={(option: any, value: any) => {
                      return option === value;
                    }}
                    renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              Start Date
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    shouldDisableDate={shouldDisableDate}
                    shouldDisableMonth={shouldDisableMonth}
                    shouldDisableYear={shouldDisableYear}
                    value={value}
                    onChange={(date: DateTime | null) => {
                      onChange(date);
                      setValue('startDate', date);
                      if (
                        endDateWatch &&
                        DateTime.fromISO(reassignProjectAndRole?.role?.startDate) < endDateWatch &&
                        date &&
                        date < DateTime.fromISO(reassignProjectAndRole?.role?.endDate) &&
                        leaveNewRoleProjectWatch?.id === LEAVE_PROJECT_ID
                      ) {
                        setShouldShowAfterLeaveDropdown(true);
                      } else {
                        setShouldShowAfterLeaveDropdown(false);
                        setValue('afterLeave', '');
                      }
                    }}
                    format="D"
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'cancel', 'today'],
                      },
                      textField: { fullWidth: true },
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignSelf="center" textAlign={'center'} xs={4}>
              End Date
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledDatePicker
                    value={value}
                    onChange={(date) => {
                      onChange(date);
                      setValue('endDate', date);
                      if (
                        date &&
                        DateTime.fromISO(reassignProjectAndRole?.role?.startDate) < date &&
                        startDateWatch &&
                        startDateWatch < DateTime.fromISO(reassignProjectAndRole?.role?.endDate) &&
                        leaveNewRoleProjectWatch?.id === LEAVE_PROJECT_ID
                      ) {
                        setShouldShowAfterLeaveDropdown(true);
                      } else {
                        setShouldShowAfterLeaveDropdown(false);
                        setValue('afterLeave', '');
                      }
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        {shouldShowAfterLeaveDropdown && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item alignSelf="center" textAlign={'center'} xs={4}>
                After Leave
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="afterLeave"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="afterLeave"
                      size="small"
                      options={AFTER_LEAVE_DROPDOWN_LIST}
                      filterSelectedOptions
                      getOptionLabel={(option) => option}
                      onChange={(_event, newValue) => {
                        onChange(newValue);
                        setValue('afterLeave', newValue ?? '');
                      }}
                      value={value ?? ''}
                      isOptionEqualToValue={(option: string, value: string) => {
                        return option === value;
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={props.id + option}>
                            {option}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default ReassignToLeaveOrNewRole;
