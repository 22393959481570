import { Autocomplete, Checkbox, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { FormValues, ProjectImport } from 'types';

type SelectProjectDialogProps = {
  control: Control<FormValues>;
  listOfProjectNames?: { id: string; name: string }[];
  setValue: UseFormSetValue<FormValues>;
  handleProjectDetailsSummaryDialogInputChange: (x: string | string[] | Date, y: keyof ProjectImport) => void;
  selectedMethodValue?: string;
  shouldCreateProject?: boolean;
  setShouldCreateProject?: (x: boolean) => void;
  projectSelection?: { id: string; name: string } | null;
  setRolesFromCopiedProjectImport: (x: []) => void;
};

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const SelectProjectDialog: FC<SelectProjectDialogProps> = ({
  control,
  listOfProjectNames,
  setValue,
  handleProjectDetailsSummaryDialogInputChange,
  selectedMethodValue,
  shouldCreateProject,
  setShouldCreateProject,
  projectSelection,
  setRolesFromCopiedProjectImport,
}) => {
  const foundProject = projectSelection?.id && projectSelection.name !== '';
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Controller
          name="projectSelection"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Autocomplete
                id="projectSelection"
                size="small"
                options={listOfProjectNames ? listOfProjectNames : []}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(_event, newValue) => {
                  onChange(newValue);
                  setValue('projectSelection', newValue);
                  setShouldCreateProject && setShouldCreateProject(false);
                  handleProjectDetailsSummaryDialogInputChange([], 'Project District' as keyof ProjectImport);
                  setValue('projectDetailsSummary.districts', []);
                  setRolesFromCopiedProjectImport([]);
                }}
                value={shouldCreateProject ? { id: '', name: '' } : value ?? undefined}
                isOptionEqualToValue={(option: { id: string; name: string }, value: { id: string; name: string }) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Projects" variant="outlined" />}
                disabled={shouldCreateProject}
                disableClearable
              />
              <Grid sx={errorMessageStyle}>
                {!shouldCreateProject && !foundProject && selectedMethodValue === 'Create Blank Project'
                  ? 'Required'
                  : null}
              </Grid>
            </>
          )}
          rules={{
            required: !shouldCreateProject && !foundProject && selectedMethodValue === 'Create Blank Project',
          }}
        />
      </Grid>
      {selectedMethodValue === 'Create Blank Project' && (
        <Grid item>
          <Controller
            name="shouldCreateProject"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <>
                  <Typography>Project Not Found</Typography>
                  <Checkbox
                    checked={shouldCreateProject}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue('projectSelection', { id: '', name: '' });
                      setShouldCreateProject && setShouldCreateProject(!shouldCreateProject);
                    }}
                    defaultChecked={false}
                  />
                </>
              );
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectProjectDialog;
