// This step is from the reassign personnel step (Thing 2)
// The purpose of this step is to move the personnel to a specific role on a project selected
// To enter this step you are assigning a personnel to an exiting role choice
// First create the reassignment of the personnel you are moving to with the person, old assignment and new assignment
// edit the datasource of the role change
// Create the role upsert for the role you are editing. You are changing the start and end dates as well as the person
// assigned to the role.
// If the role you are filling is unfilled you will be taken to the finalize step.

import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { DateTime } from 'luxon';
import { UseFormReset } from 'react-hook-form';
import { ReassignRoleFormValues, ReassignRoleSummaryReassignmentsType, reassignmentType } from 'types';
import { Project, ProjectRole, ProjectRoleUpsertInput } from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';
import { REASSIGN_SUMMARY_STEP_INDEX, REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX } from '../ReassignRoleDialog';
import {
  createNewReassignment,
  createPreservedRoles,
  getNewRoleUpsertInput,
  getRoleUpsertInput,
} from '../reassignUtils';

type ReassignPersonnelStepRoleToReassignIsActiveTypeParams = {
  roleWatch: ProjectRole | undefined;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  projectWatch: Project | undefined;
  dateTimeNow: DateTime;
  transactionKey: string;
  dataSource: ProjectsGridRowData[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
};

const reassignPersonnelStepRoleToReassignIsActive = ({
  roleWatch,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  projectWatch,
  dateTimeNow,
  transactionKey,
  dataSource,
  reassignRoleSummaryReassignments,
  reassignment,
  reassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
}: ReassignPersonnelStepRoleToReassignIsActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: roleWatch?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    projectId: projectWatch?.id,
    id: roleWatch?.id,
  });

  const preserveHistoryProjectRoleUpsert = getNewRoleUpsertInput({
    roleName: roleWatch?.roleName,
    startDate: roleWatch?.startDate,
    endDate: dateTimeNow?.toISODate(),
    personId: roleWatch?.person?.id,
    transactionKey,
    projectId: roleWatch?.project
      ? roleWatch?.project?.id
      : dataSource.find((data) => data?.role?.id === roleWatch?.id)?.project.id,
  });

  const newPreserveRole = createPreservedRoles({
    projectName: roleWatch?.project
      ? roleWatch?.project?.name
      : dataSource.find((data) => data?.role?.id === roleWatch?.id)?.project.name,
    personnel: roleWatch?.person?.name?.lastCommaFirst,
    roleName: roleWatch?.roleFullDisplayName,
    startDate: roleWatch?.startDate,
    endDate: dateTimeNow?.toISODate(),
  });

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
    preservedRoles:
      reassignRoleSummaryReassignments.preservedRoles && reassignRoleSummaryReassignments.preservedRoles.length > 0
        ? [...reassignRoleSummaryReassignments.preservedRoles, newPreserveRole]
        : [newPreserveRole],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  setTransactionKey(generateTransactionKey());

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
    preserveHistoryProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);
};

type ReassignPersonnelStepRoleToReassignIsNotActiveTypeParams = {
  roleWatch: ProjectRole | undefined;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  projectWatch: Project | undefined;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
};

const reassignPersonnelStepRoleToReassignIsNotActive = ({
  roleWatch,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  projectWatch,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  reassignment,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
}: ReassignPersonnelStepRoleToReassignIsNotActiveTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: roleWatch?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    projectId: projectWatch?.id,
    id: roleWatch?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);
};

type ReassignPersonnelStepRoleToFillIsUnfilledTypeParams = {
  roleWatch: ProjectRole | undefined;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  projectWatch: Project | undefined;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const reassignPersonnelStepRoleToFillIsUnfilled = ({
  roleWatch,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  projectWatch,
  reassignmentProjectRoleMutations,
  reassignRoleSummaryReassignments,
  reassignment,
  setReassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
  setActiveStep,
}: ReassignPersonnelStepRoleToFillIsUnfilledTypeParams) => {
  const reassignProjectRoleUpsert = getRoleUpsertInput({
    roleName: roleWatch?.roleName,
    startDate: startDateWatch?.toISODate(),
    endDate: endDateWatch?.toISODate(),
    personId: reassignProjectAndRole?.role?.person?.id,
    projectId: projectWatch?.id,
    id: roleWatch?.id,
  });

  const projectRoleUpsertArray: ProjectRoleUpsertInput[] = [
    ...reassignmentProjectRoleMutations,
    reassignProjectRoleUpsert,
  ];

  setReassignmentProjectRoleMutations(projectRoleUpsertArray);

  const updateReassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType = {
    ...reassignRoleSummaryReassignments,
    reassignments:
      reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0
        ? [...reassignRoleSummaryReassignments.reassignments, reassignment]
        : [reassignment],
  };

  setReassignRoleSummaryReassignments(updateReassignRoleSummaryReassignments);

  setActiveStep(REASSIGN_SUMMARY_STEP_INDEX);
};

type ReassignPersonnelStepRoleToFillIsAssignedTypeParams = {
  roleWatch: ProjectRole | undefined;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  projectWatch: Project | undefined;
  transactionKey: string;
  dataSource: ProjectsGridRowData[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignment: reassignmentType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignProjectAndRole: (x: ProjectsGridRowData) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const reassignPersonnelStepRoleToFillIsAssigned = ({
  roleWatch,
  startDateWatch,
  endDateWatch,
  reassignProjectAndRole,
  projectWatch,
  transactionKey,
  dataSource,
  reassignRoleSummaryReassignments,
  reassignment,
  reassignmentProjectRoleMutations,
  setReassignRoleSummaryReassignments,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignProjectAndRole,
  setActiveStep,
}: ReassignPersonnelStepRoleToFillIsAssignedTypeParams) => {
  const dateTimeNow = DateTime.now();

  const roleToReassignIsActive =
    dateTimeNow > DateTime.fromISO(roleWatch?.startDate) && dateTimeNow < DateTime.fromISO(roleWatch?.endDate);

  if (roleToReassignIsActive) {
    reassignPersonnelStepRoleToReassignIsActive({
      roleWatch,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      projectWatch,
      dateTimeNow,
      transactionKey,
      dataSource,
      reassignRoleSummaryReassignments,
      reassignment,
      reassignmentProjectRoleMutations,
      setReassignRoleSummaryReassignments,
      setTransactionKey,
      setReassignmentProjectRoleMutations,
    });
  } else {
    reassignPersonnelStepRoleToReassignIsNotActive({
      roleWatch,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      projectWatch,
      reassignmentProjectRoleMutations,
      reassignRoleSummaryReassignments,
      reassignment,
      setReassignmentProjectRoleMutations,
      setReassignRoleSummaryReassignments,
    });
  }
  setReassignProjectAndRole({
    project: projectWatch as Project,
    role: {
      ...roleWatch,
      startDate: startDateWatch?.toISODate(),
      endDate: endDateWatch?.toISODate(),
    } as ProjectRole,
  });
  setActiveStep(REASSIGN_TO_ANOTHER_ROLE_STEP_INDEX);
};

// If the role you are filling is taken then you will be asked what to do with that person
type ReassignPersonnelStepTypeParams = {
  projectWatch: Project | undefined;
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  roleWatch: ProjectRole | undefined;
  startDateWatch: DateTime | null;
  endDateWatch: DateTime | null;
  transactionKey: string;
  dataSource: ProjectsGridRowData[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
  reassignmentProjectRoleMutations: ProjectRoleUpsertInput[];
  defaultValues: ReassignRoleFormValues;
  setReassignRoleSummaryReassignments: React.Dispatch<React.SetStateAction<ReassignRoleSummaryReassignmentsType>>;
  setTransactionKey: React.Dispatch<React.SetStateAction<string>>;
  setReassignmentProjectRoleMutations: React.Dispatch<React.SetStateAction<ProjectRoleUpsertInput[]>>;
  setReassignProjectAndRole: (x: ProjectsGridRowData) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  reset: UseFormReset<ReassignRoleFormValues>;
};

export const reassignPersonnelStep = ({
  projectWatch,
  reassignProjectAndRole,
  roleWatch,
  startDateWatch,
  endDateWatch,
  transactionKey,
  dataSource,
  reassignRoleSummaryReassignments,
  reassignmentProjectRoleMutations,
  defaultValues,
  setReassignRoleSummaryReassignments,
  setTransactionKey,
  setReassignmentProjectRoleMutations,
  setReassignProjectAndRole,
  setActiveStep,
  reset,
}: ReassignPersonnelStepTypeParams) => {
  const reassignment = createNewReassignment({
    projectName: projectWatch?.name,
    roleName: reassignProjectAndRole?.role?.roleFullDisplayName,
    personnelBeingReassigned: roleWatch?.person?.name?.lastCommaFirst,
    personnelReassignedToRole: reassignProjectAndRole?.role?.person?.name?.lastCommaFirst,
    personnelReassignedToRoleStartDate: startDateWatch?.toISODate(),
    personnelReassignedToRoleEndDate: endDateWatch?.toISODate(),
  });

  if (roleWatch?.person) {
    reassignPersonnelStepRoleToFillIsAssigned({
      roleWatch,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      projectWatch,
      transactionKey,
      dataSource,
      reassignRoleSummaryReassignments,
      reassignment,
      reassignmentProjectRoleMutations,
      setReassignRoleSummaryReassignments,
      setTransactionKey,
      setReassignmentProjectRoleMutations,
      setReassignProjectAndRole,
      setActiveStep,
    });
  } else {
    reassignPersonnelStepRoleToFillIsUnfilled({
      roleWatch,
      startDateWatch,
      endDateWatch,
      reassignProjectAndRole,
      projectWatch,
      reassignmentProjectRoleMutations,
      reassignRoleSummaryReassignments,
      reassignment,
      setReassignmentProjectRoleMutations,
      setReassignRoleSummaryReassignments,
      setActiveStep,
    });
  }
  reset(defaultValues);
};
