import { Card, CardContent, Grid, Popover, SxProps, Theme, Typography } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import { personnelIsConsultant } from 'constants/consultant';
import { EMPLOYEE_MASTER_SOURCE } from 'constants/tooltips';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { Person } from 'types/generated/graphql';
import { LeftSectionEditableConsultantFieldsForm } from './LeftSectionEditableConsultantFieldsForm';

const justifyContentCenterStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const labelStyle: SxProps<Theme> = {
  fontFamily:
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  minWidth: '233px',
  fontWeight: '500',
};

const leftSectionTextStyles: SxProps<Theme> = {
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

type LeftSectionNonEditableFieldsProps = {
  isEditing: boolean;
  setIsEditing: (x: boolean) => void;
  personnel: Person;
};

type InfoItemProps = {
  label: string;
  info: string | DateTime;
  tooltip: string;
  visible?: boolean;
};

const InfoItem: FC<InfoItemProps> = ({ label, info, tooltip, visible }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12} sx={labelStyle}>
      <Grid container style={{ display: visible ? 'flex' : 'none', justifyContent: 'space-between' }}>
        <Grid item xs={6}>
          <HelpTooltip tooltipText={tooltip} titleText={label + ':'} />
        </Grid>
        <Grid item xs={6}>
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={leftSectionTextStyles}
            color="textPrimary"
            className="inline"
            maxWidth={800}
          >
            {info.toString()}
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>{info.toString()}</Typography>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const LeftSectionNonEditableFields: FC<LeftSectionNonEditableFieldsProps> = ({
  isEditing,
  setIsEditing,
  personnel,
}) => {
  const personnelDetailsArr = [
    {
      label: 'Status',
      info: personnel?.isActive,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: true,
    },
    {
      label: 'Address',
      info: personnel?.location?.cityStateAndZipDisplay,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: true,
    },
    {
      label: 'Cell Number',
      info: personnel?.contactInfo?.primaryPhone?.number,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: true,
    },
    {
      label: 'Phone Number',
      info: personnel?.contactInfo?.secondaryPhone?.number,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: true,
    },
    {
      label: 'Email',
      info: personnel?.contactInfo?.hpEmailAddress?.email,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: personnel && !personnelIsConsultant(personnel),
    },
    {
      label: 'Email',
      info: personnel?.contactInfo?.personalEmailAddress?.email,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: personnel && personnelIsConsultant(personnel),
    },
    {
      label: 'Payroll Region',
      info: personnel?.prDistrict,
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: personnel && !personnelIsConsultant(personnel),
    },
    {
      label: 'Last Evaluation Date',
      info: personnel?.lastEvalDate
        ? DateTime.fromFormat(personnel.lastEvalDate.toString(), 'yyyy-mm-dd').toLocaleString()
        : '',
      tooltip: EMPLOYEE_MASTER_SOURCE,
      visible: personnel && !personnelIsConsultant(personnel),
    },
  ];

  return (
    <Grid item xs={12} sx={justifyContentCenterStyles}>
      <Card>
        <CardContent>
          {isEditing && personnel && personnelIsConsultant(personnel) ? (
            <LeftSectionEditableConsultantFieldsForm setIsEditing={setIsEditing} personnel={personnel} />
          ) : (
            <Grid container rowSpacing={1}>
              {personnelDetailsArr.map((item, index) => (
                <InfoItem
                  label={item.label}
                  info={item.info ?? ''}
                  tooltip={item.tooltip ?? ''}
                  key={`details.${index}`}
                  visible={item.visible}
                />
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
