import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import HelpTooltip from 'components/shared/HelpTooltip';
import { personnelIsConsultant } from 'constants/consultant';
import { DISTRICTS } from 'constants/districts';
import { ROLES_BY_NAME, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import { UKG_SOURCE, USER_INPUT_SOURCE } from 'constants/tooltips';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Person, useUpdatePersonMutation } from 'types/generated/graphql';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

const chipStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: `${theme.palette.secondary.dark} !important`,
  borderRadius: '12px !important',
});

const chipSetBoxStyle: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 0.5,
};

const justifyContentSpaceBetweenStyles: SxProps<Theme> = {
  display: 'flex',
};

type LeftSectionEditableFieldsFormProps = {
  setIsEditing: (x: boolean) => void;
  personnel: Person;
};

type PersonnelFormValues = {
  possiblePromotionTitle: string;
  possiblePromotionDate?: string | null;
  anticipatedSeparationDate?: string | null;
  sharedWithDistricts?: string[];
  notes?: string;
  notesFlag?: boolean;
  preferredName?: string;
  company?: string;
};

export const LeftSectionEditableFieldsForm: FC<LeftSectionEditableFieldsFormProps> = ({ setIsEditing, personnel }) => {
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [updatePerson, { loading: isLoading }] = useUpdatePersonMutation({});
  const personnelJobTitleIsCraft = ROLES_BY_NAME[personnel.prJobTitle ?? '']?.type === 'CRAFT';

  const NO_PROMOTION = 'None';

  const defaultValues = {
    possiblePromotionTitle: personnel.possiblePromotionTitle ?? '',
    possiblePromotionDate: personnel.possiblePromotionDate ?? null,
    anticipatedSeparationDate: personnel.anticipatedSeparationDate ?? null,
    sharedWithDistricts: personnel.sharedWithDistricts ?? [],
    notes: personnel.notes ?? '',
    notesFlag: personnel.notesFlag ?? false,
    preferredName: personnel.name?.preferred ?? '',
    company: personnel.company ?? '',
  };

  const { handleSubmit, formState, control, reset } = useForm<PersonnelFormValues>({ defaultValues });
  const { isDirty } = formState;
  const [selectedSharedWithDistricts, setSelectedSharedWithDistricts] = useState<string[]>(
    personnel?.sharedWithDistricts ?? [],
  );

  const handleClose = () => {
    setIsEditing(false);
    reset(defaultValues);
    setSelectedSharedWithDistricts(defaultValues.sharedWithDistricts);
    setTransactionKey(generateTransactionKey());
  };

  const onSubmit: SubmitHandler<PersonnelFormValues> = (data) => {
    const firstIndex = 1;
    const possiblePromotionTitle = data.possiblePromotionTitle?.includes(' - ')
      ? data.possiblePromotionTitle?.split(' - ')[firstIndex]
      : data.possiblePromotionTitle;

    updatePerson({
      variables: {
        input: {
          transactionKey,
          id: personnel.id,
          possiblePromotionTitle: possiblePromotionTitle ?? '',
          possiblePromotionDate: DateTime.fromISO(data.possiblePromotionDate ?? '').toISODate() ?? null,
          anticipatedSeparationDate: DateTime.fromISO(data.anticipatedSeparationDate ?? '').toISODate() ?? null,
          sharedWithDistricts: data.sharedWithDistricts ?? [],
          notes: data.notes ?? null,
          notesFlag: data.notesFlag ?? false,
          name: {
            preferred: data.preferredName ?? '',
          },
          company: data.company ?? '',
        },
      },
    })
      .then(() => {
        displayToast('The personnel was updated successfully', 'success');
        setIsEditing(false);
      })
      .catch((error: any) => {
        console.error('Update personnel failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to update the personnel. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!personnel.name?.preferred && (
        <Grid item xs={12}>
          <Controller
            name="preferredName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <Grid container sx={justifyContentSpaceBetweenStyles}>
                  <Grid item xs={6}>
                    <InputLabel>
                      <HelpTooltip tooltipText={UKG_SOURCE} titleText="Preferred Name" />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField multiline onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid>
      )}
      {personnelIsConsultant(personnel) && (
        <Grid item xs={12}>
          <Controller
            name="company"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <Grid container sx={justifyContentSpaceBetweenStyles}>
                  <Grid item xs={6}>
                    <InputLabel>
                      <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Company" />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid>
      )}
      {!personnelIsConsultant(personnel) && (
        <>
          <Grid item xs={12}>
            <Controller
              name="possiblePromotionTitle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Possible Promotion Title" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        disableClearable={false}
                        id="possiblePromotionTitle"
                        size="small"
                        options={[NO_PROMOTION, ...ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(personnelJobTitleIsCraft)]}
                        filterSelectedOptions
                        onChange={(_event, value) => onChange(value)}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        value={value}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="possiblePromotionDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Grid container sx={justifyContentSpaceBetweenStyles}>
                    <Grid item xs={6}>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Possible Promotion Date" />
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        value={value ? convertUTCDateToLocalDate(value) : null}
                        onChange={(date: DateTime | null) => onChange(date)}
                        format="D"
                        slotProps={{
                          actionBar: {
                            actions: ['clear', 'cancel', 'today'],
                          },
                          textField: { size: 'small' },
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Controller
          name="anticipatedSeparationDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Grid container sx={justifyContentSpaceBetweenStyles}>
                <Grid item xs={6}>
                  <InputLabel>
                    <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Anticipated Separation Date" />
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    value={value ? convertUTCDateToLocalDate(value) : null}
                    onChange={(date: DateTime | null) => onChange(date)}
                    format="D"
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'cancel', 'today'],
                      },
                      textField: { size: 'small' },
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        />
      </Grid>
      {!personnelIsConsultant(personnel) && (
        <Grid item xs={12}>
          <Controller
            name="sharedWithDistricts"
            control={control}
            render={({ field: { onChange } }) => (
              <>
                <Grid container sx={justifyContentSpaceBetweenStyles}>
                  <Grid item xs={6}>
                    <InputLabel>
                      <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Shared with Regions" />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth={true}
                      multiple
                      value={selectedSharedWithDistricts ?? []}
                      onChange={(event: SelectChangeEvent<string[]>) => {
                        const value = event.target.value;
                        const valueAsArray = typeof value === 'string' ? value.split(',') : value;
                        setSelectedSharedWithDistricts(valueAsArray);
                        onChange(event);
                      }}
                      size="small"
                      variant="outlined"
                      displayEmpty
                      style={{ height: '40px' }}
                      renderValue={() => {
                        return (
                          <Box sx={chipSetBoxStyle}>
                            {[...selectedSharedWithDistricts].sort().map((district, index) => (
                              <Chip sx={chipStyle} key={index} label={district} />
                            ))}
                          </Box>
                        );
                      }}
                    >
                      {DISTRICTS.map((district) => (
                        <MenuItem key={`districtFilterOption${district.number}`} value={district.number}>
                          <ListItemText primary={`${district.number} - ${district.name}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="notesFlag"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Grid container sx={justifyContentSpaceBetweenStyles}>
                <Grid item xs={6}>
                  <InputLabel>
                    <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Notes Flag" />
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="notes"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Grid container sx={justifyContentSpaceBetweenStyles}>
                <Grid item xs={6}>
                  <InputLabel>
                    <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Notes" />
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField multiline onChange={onChange} value={value} variant="outlined" fullWidth />
                </Grid>
              </Grid>
            </>
          )}
        />
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center" direction="row" spacing={1} justifyContent="flex-end">
            <Grid item>
              <StyledButtonSecondary label="Cancel" onClick={handleClose} disabled={isLoading} />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                disabled={!isDirty || isLoading}
                type="submit"
                label="Submit"
                onSubmit={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
