import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import { getApolloClient } from 'config/apolloConfig';
import useToast from 'hooks/useToast';
import { FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import { PublicClientApplication } from '@azure/msal-browser';

const Reloader = () => {
  return (
    <StyledButtonPrimary
      label="Reload"
      onClick={() => {
        //Store all syncfusion local storage keys
        const contextmenuProjectsGrid_cmenu = localStorage.getItem('contextmenuProjectsGrid_cmenu');
        const contextmenuProjectsGrid_columnmenu = localStorage.getItem('contextmenuProjectsGrid_columnmenu');
        const gridProjectsGrid = localStorage.getItem('gridProjectsGrid');
        const toolbarPersonnelGrid_toolbarItems = localStorage.getItem('toolbarPersonnelGrid_toolbarItems');
        const contextmenuPersonnelGrid_cmenu = localStorage.getItem('contextmenuPersonnelGrid_cmenu');
        const contextmenuPersonnelGrid_columnmenu = localStorage.getItem('contextmenuPersonnelGrid_columnmenu');
        const gridPersonnelGrid = localStorage.getItem('gridPersonnelGrid');

        //Clear local storage
        localStorage.clear();

        //Set all syncfusion local storage keys back to previous values
        localStorage.setItem('contextmenuProjectsGrid_cmenu', contextmenuProjectsGrid_cmenu ?? '');
        localStorage.setItem('contextmenuProjectsGrid_columnmenu', contextmenuProjectsGrid_columnmenu ?? '');
        localStorage.setItem('gridProjectsGrid', gridProjectsGrid ?? '');
        localStorage.setItem('toolbarPersonnelGrid_toolbarItems', toolbarPersonnelGrid_toolbarItems ?? '');
        localStorage.setItem('contextmenuPersonnelGrid_cmenu', contextmenuPersonnelGrid_cmenu ?? '');
        localStorage.setItem('contextmenuPersonnelGrid_columnmenu', contextmenuPersonnelGrid_columnmenu ?? '');
        localStorage.setItem('gridPersonnelGrid', gridPersonnelGrid ?? '');

        window.location.reload();
      }}
    />
  );
};

export type AppApolloProviderProps = {
  /** MSAL instance needed to acquire Azure AD access token for sending to the API */
  msalInstance: PublicClientApplication;
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const AppApolloProvider: FC<AppApolloProviderProps> = ({ msalInstance, children }) => {
  const { displayToast } = useToast();
  const onWsError = (error: any) => {
    console.error('Websocket error:');
    console.error(error);
    displayToast('Not receiving live updates. Please reload before making any edits.', 'warning', Reloader, false);
  };
  const apolloClient = getApolloClient(msalInstance, onWsError);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AppApolloProvider;
