import { ROLES_BY_NAME } from 'constants/roles';
import { ProjectRole } from 'types/generated/graphql';
import { graphqlDateToJsDate } from 'utils/general';

import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type ProjectRole {
    startJsDate: Date @requires(fields: "startDate")
    endJsDate: Date @requires(fields: "endDate")
    roleAbbreviation: String @requires(fields: "roleName")
    roleFullDisplayName: String @requires(fields: "roleName")
  }
`;

// Resolvers
export const resolvers = {
  ProjectRole: {
    startJsDate: (projectRole: ProjectRole) => {
      return graphqlDateToJsDate(projectRole.startDate);
    },
    endJsDate: (projectRole: ProjectRole) => {
      return graphqlDateToJsDate(projectRole.endDate);
    },
    roleAbbreviation: (projectRole: ProjectRole) => {
      return ROLES_BY_NAME[projectRole.roleName]?.abbreviation ?? '';
    },
    roleFullDisplayName: (projectRole: ProjectRole) => {
      return ROLES_BY_NAME[projectRole.roleName]?.fullDisplayName ?? '';
    },
  },
};

export const PROJECT_ROLE_LIST_RESULT = gql`
  query ProjectRoleListResult(
    $skip: Int
    $first: Int
    $order: ProjectRoleListOrderInput
    $search: String
    $projectIds: [ID!]
    $districts: [String!]
    $projectStatuses: [String!]
  ) {
    projectRoleListResult(
      skip: $skip
      first: $first
      order: $order
      search: $search
      projectIds: $projectIds
      districts: $districts
      projectStatuses: $projectStatuses
    ) {
      items {
        id
        startDate
        endDate
        roleName
        person {
          id
          isCraft
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PROJECT_ROLE = gql`
  mutation DeleteProjectRole($id: ID!) {
    deleteProjectRole(id: $id) {
      id
    }
  }
`;

export const DELETED_PROJECT_ROLE_SUBSCRIPTION = gql`
  subscription DeletedProjectRole {
    deletedProjectRole {
      id
    }
  }
`;

export const UPSERT_PROJECT_ROLE = gql`
  mutation UpsertProjectRole($input: ProjectRoleUpsertInput!) {
    upsertProjectRole(input: $input) {
      id
      person {
        id
      }
      focusArea
      notes
      notesFlag
      startDate
      endDate
    }
  }
`;

export const UPSERT_PROJECT_ROLES = gql`
  mutation UpsertProjectRoles($input: [ProjectRoleUpsertInput!]!) {
    upsertProjectRoles(input: $input) {
      id
      person {
        id
      }
      focusArea
      notes
      startDate
      endDate
    }
  }
`;
