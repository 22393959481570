import { useApolloClient } from '@apollo/client';
import { Grid, SxProps, Theme } from '@mui/material';
import { L10n } from '@syncfusion/ej2-base';
import { GanttComponent } from '@syncfusion/ej2-react-gantt';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { CONTRACT_TYPES } from 'constants/contractTypes';
import { DISTRICT_NUMBERS } from 'constants/districts';
import { STATUS } from 'constants/projectStatus';
import { ROLES, ROLES_BY_FULL_DISPLAY_NAME } from 'constants/roles';
import { VERTICAL_MARKETS } from 'constants/verticalMarkets';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { usePersonListResultQuery, useUpsertProjectMutation } from 'types/generated/graphql';
import { formatAddress, generateTransactionKey, serializeDate } from 'utils/general';

const Papa = require('papaparse');

const errorMessagesContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(2),
});

type MassProjectUploadDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  getGanttInstance: () => GanttComponent | null | undefined;
  districts?: string[];
  isOnlyCraft: boolean;
};

const MassProjectUploadDialog: FC<MassProjectUploadDialogProps> = ({
  isOpen,
  setIsOpen,
  getGanttInstance,
  districts,
  isOnlyCraft,
}) => {
  const [parsedProjectData, setParsedProjectData] = useState<any[]>([]);
  const [transactionKey, setTransactionKey] = useState<string>(generateTransactionKey());
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const ganttInstance = getGanttInstance();
  const { displayToast } = useToast();

  const projectNameFromGanttInstance = ganttInstance?.currentViewData.map((projectData) => {
    const projectTaskData: any = projectData?.taskData;
    const entityType: string = projectTaskData.entityType;
    const projectNames = entityType === 'Project' ? projectData.ganttProperties?.taskName : undefined;
    return projectNames;
  });
  const validProjectNames = projectNameFromGanttInstance?.filter((projectName) => projectName);

  const [addProject] = useUpsertProjectMutation();

  const apolloClient = useApolloClient();

  const onUploadSuccess = (args: any) => {
    Papa.parse(args.file.rawFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        setParsedProjectData(results.data);
      },
    });
    setUploadSuccess(true);
  };

  L10n.load({
    'en-US': {
      uploader: {
        dropFilesHint: 'Or drop file here (1 file per upload)',
      },
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    setTransactionKey(generateTransactionKey());
    setUploadSuccess(false);
  };

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const { data: personnelData } = usePersonListResultQuery({
    variables: {
      isOnlyCraft,
      districts: districts,
      isActive: ['Active', 'Awaiting Hire'],
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmitSuccess = () => {
    const rowsByProjectName = parsedProjectData.reduce<{ [projectName: string]: any[] }>((accumulator, row) => {
      if (!(row['Project Full Name'] in accumulator)) {
        accumulator[row['Project Full Name']] = [];
      }
      accumulator[row['Project Full Name']].push(row);
      return accumulator;
    }, {});

    const personnelList = personnelData?.personListResult?.items ?? [];
    const invalidProjectsArr: any[] = [];
    const projectsFromUploadArr: any[] = Object.values(rowsByProjectName).map((rows) => rows[0]);
    Object.values(rowsByProjectName).forEach((rows, index) => {
      const firstRow = rows[0] ?? null;

      const streetAddressOne = firstRow['Project Street Address 1'] ?? null;
      const streetAddressTwo = firstRow['Project Street Address 2'] ?? null;
      const city = firstRow['Project City'] ?? null;
      const state = firstRow['Project State Abbr.'] ?? null;
      const zip = firstRow['Project Zip'] ?? null;

      const checkDoesProjectExist = validProjectNames?.includes(firstRow['Project Full Name']?.trim());

      const doesProjectHaveValidPersonnelNumber = !rows.some(
        (row) =>
          row['Employee PR Number'] !== '' &&
          !personnelList.find((personnel) => personnel.employeePrNumber === row['Employee PR Number']?.trim()),
      );

      const doesProjectHaveValidRoles = !rows.some(
        (row) => !(row['Role Position']?.trim() in ROLES_BY_FULL_DISPLAY_NAME),
      );

      const doesProjectHaveValidStatus =
        firstRow['Project Status'] !== '' && STATUS.find((status) => status === firstRow['Project Status']?.trim());

      const doesProjectHaveValidVerticals =
        firstRow['Vertical Markets'] !== '' &&
        VERTICAL_MARKETS.find((verticalMarket) => verticalMarket === firstRow['Vertical Markets']?.trim());

      const doesProjectHaveValidContractTypes =
        firstRow['Contract Type'] !== '' &&
        CONTRACT_TYPES.find((contractType) => contractType === firstRow['Contract Type']?.trim());

      const doesProjectHaveValidDistricts =
        firstRow['Project Region'] !== '' &&
        DISTRICT_NUMBERS.find((district) => district === firstRow['Project Region']?.trim());
      if (
        !checkDoesProjectExist &&
        doesProjectHaveValidRoles &&
        doesProjectHaveValidPersonnelNumber &&
        doesProjectHaveValidStatus &&
        doesProjectHaveValidVerticals &&
        doesProjectHaveValidContractTypes &&
        doesProjectHaveValidDistricts
      ) {
        const districts = firstRow['Project Region']?.includes(',')
          ? firstRow['Project Region']?.split(',').map((district: string) => district?.trim())
          : firstRow['Project Region']?.trim();
        const verticalMarkets = firstRow['Vertical Markets']?.includes(',')
          ? firstRow['Vertical Markets']?.split(',').map((vertical: string) => vertical?.trim())
          : firstRow['Vertical Markets']?.trim();

        const convertedAndFormattedProjectStartDate = new Date(
          firstRow['Project Start Date']?.trim().replace(/-/g, '/'),
        );
        const convertedAndFormattedProjectEndDate = new Date(firstRow['Project End Date']?.trim().replace(/-/g, '/'));

        addProject({
          variables: {
            input: {
              transactionKey: `${transactionKey}.${index}`,
              name: firstRow['Project Full Name']?.trim(),
              shortName: firstRow['Project Short Name']?.trim(),
              districts: districts ?? [],
              verticalMarkets: verticalMarkets ?? [],
              status: firstRow['Project Status']?.trim() ?? '',
              startDate: serializeDate(convertedAndFormattedProjectStartDate) ?? undefined,
              endDate: serializeDate(convertedAndFormattedProjectEndDate) ?? undefined,
              roles: rows?.map((row) => {
                const convertedAndFormattedRoleStartDate = new Date(row['Role Begin Date']?.trim().replace(/-/g, '/'));
                const convertedAndFormattedRoleEndDate = new Date(row['Role End Date']?.trim().replace(/-/g, '/'));
                return {
                  roleName: ROLES_BY_FULL_DISPLAY_NAME[row['Role Position']?.trim()]?.roleName as string,
                  startDate: serializeDate(convertedAndFormattedRoleStartDate) ?? undefined,
                  endDate: serializeDate(convertedAndFormattedRoleEndDate) ?? undefined,
                  personId:
                    (personnelList.find((personnel) => personnel.employeePrNumber === row['Employee PR Number']?.trim())
                      ?.id as string) ?? null,
                  notes: row['Notes']?.trim() ?? '',
                  focusArea: row['Focus Area']?.trim() ?? '',
                };
              }),
              description: firstRow['Project Description'] ?? '',
              contractType: firstRow['Contract Type']?.trim() ?? '',
              location: {
                display: formatAddress(streetAddressOne, streetAddressTwo, city, state, zip) ?? '',
                streetAndNumber: streetAddressOne ?? '',
                streetDetail: streetAddressTwo ?? '',
                city: city ?? '',
                stateOrProvince: state ?? '',
                zipOrPostalCode: zip ?? '',
              },
              number: firstRow['Project Number']?.trim() ?? '',
            },
          },
        }).then(() => {
          displayToast('The project(s) were added successfully', 'success');
          apolloClient.reFetchObservableQueries();
        });
      } else {
        setIsErrorDialogOpen(true);

        const validRoleNames = ROLES.map((role) => role.fullDisplayName);
        const validPersonnelNumbers = personnelList.map((personnel) => personnel.employeePrNumber);
        const validVerticalMarkets = VERTICAL_MARKETS.map((vertical) => vertical);
        const validProjectStatus = STATUS.map((projectStatus) => projectStatus);
        const validContractTypes = CONTRACT_TYPES.map((contractType) => contractType);
        const validDistricts = DISTRICT_NUMBERS.map((district) => district);

        const filterProjectsWithInvalidInfo = rows.filter((row) => {
          const projectsWithInvalidRoles = !validRoleNames.includes(row['Role Position']?.trim());
          const projectsWithInvalidPersonnelNumbers =
            row['Employee PR Number'] !== ''
              ? !validPersonnelNumbers.includes(row['Employee PR Number']?.trim())
              : false;
          const projectsWithInvalidVerticals = !validVerticalMarkets.includes(row['Vertical Markets']?.trim());
          const projectsWithInvalidStatus = !validProjectStatus.includes(row['Project Status']?.trim());
          const projectsWithInvalidContractTypes = !validContractTypes.includes(row['Contract Type']?.trim());
          const projectsWithInvalidDistricts = !validDistricts.includes(row['Project Region']?.trim());

          return (
            projectsWithInvalidRoles ||
            projectsWithInvalidPersonnelNumbers ||
            projectsWithInvalidVerticals ||
            projectsWithInvalidStatus ||
            projectsWithInvalidContractTypes ||
            projectsWithInvalidDistricts
          );
        });
        filterProjectsWithInvalidInfo.forEach((project) => invalidProjectsArr.push(project));

        // Create arr containing invalid project info
        const arrOfInvalidInfoOnProjects = invalidProjectsArr?.map((project) => {
          return {
            projectName: project['Project Full Name'],
            invalidRoles: !validRoleNames.includes(project['Role Position']) ? project['Role Position'] : '',
            invalidPersonnelNumbers: !validPersonnelNumbers.includes(project['Employee PR Number'])
              ? project['Employee PR Number']
              : '',
            invalidVerticals: !validVerticalMarkets.includes(project['Vertical Markets'])
              ? project['Vertical Markets']
              : '',
            invalidStatus: !validProjectStatus.includes(project['Project Status']) ? project['Project Status'] : '',
            invalidContractTypes: !validContractTypes.includes(project['Contract Type'])
              ? project['Contract Type']
              : '',
            invalidDistricts: !validDistricts.includes(project['Project Region']) ? project['Project Region'] : '',
          };
        });

        // Create array of non-duplicate project names
        const nonDuplicateProjectNames = [
          ...new Set(invalidProjectsArr?.map((project) => project['Project Full Name'])),
        ];

        // For each project with invalid information, push invalid info to respective arr
        const projectsWithInvalidInfo = nonDuplicateProjectNames.map((name) => {
          const filterProjectWithInvalidInfoByName = arrOfInvalidInfoOnProjects?.filter(
            (invalidProjectsItem: any) => name === invalidProjectsItem.projectName,
          );

          const personnelNumberArr = filterProjectWithInvalidInfoByName?.map(
            (invalidProjectsItem) => invalidProjectsItem.invalidPersonnelNumbers,
          );
          const roleNames = [
            ...new Set(
              filterProjectWithInvalidInfoByName?.map((invalidProjectsItem) => invalidProjectsItem.invalidRoles),
            ),
          ];
          const districtsArr = filterProjectWithInvalidInfoByName?.map(
            (invalidProjectItem) => invalidProjectItem.invalidDistricts,
          );
          const verticalsArr = filterProjectWithInvalidInfoByName?.map(
            (invalidProjectItem) => invalidProjectItem.invalidVerticals,
          );
          const statusArr = filterProjectWithInvalidInfoByName?.map(
            (invalidProjectItem) => invalidProjectItem.invalidStatus,
          );
          const contractTypesArr = filterProjectWithInvalidInfoByName?.map(
            (invalidProjectItem) => invalidProjectItem.invalidContractTypes,
          );

          return {
            name: name,
            roles: roleNames.filter((role) => role),
            personnelNumber: personnelNumberArr.filter((number) => number),
            verticals: verticalsArr.filter((vertical) => vertical),
            districts: districtsArr.filter((district) => district),
            status: statusArr.filter((projectStatus) => projectStatus),
            contractTypes: contractTypesArr.filter((type) => type),
          };
        });

        const errorMessage: any = projectsWithInvalidInfo?.map((project) => {
          let errorTemplate = '';
          if (
            project.roles.length ||
            project.personnelNumber.length ||
            project.districts.length ||
            project.status.length ||
            project.verticals.length ||
            project.contractTypes.length
          ) {
            const roles = project.roles.length ? `Roles: ${project.roles.join(', ')}, ` : '';
            const personnelNumbers = project.personnelNumber.length
              ? `Employee PR Numbers: ${project.personnelNumber.join(', ')}, `
              : '';
            const districts = project.districts.length ? `Regions: ${project.districts.join(', ')}, ` : '';
            const status = project.status.length ? `Status: ${project.status.join(', ')}, ` : '';
            const verticals = project.verticals.length ? `Verticals: ${project.verticals.join(', ')}, ` : '';
            const contractTypes = project.contractTypes.length
              ? `Contract Types: ${project.contractTypes.join(', ')}, `
              : '';

            errorTemplate = `${project.name} was not uploaded because it has invalid information: 
            ${roles}
            ${personnelNumbers}
            ${districts}
            ${status}
            ${verticals}
            ${contractTypes}`;
            errorTemplate.replace(/,\s*$/, '');
          }

          const matchingProject = projectsFromUploadArr.find(
            (projectFromUpload) => projectFromUpload['Project Full Name'] === project.name,
          );
          const projectHasEmptyStatusField = matchingProject['Project Status'] === '';

          const projectHasEmptyVerticalMarketsField = matchingProject['Vertical Markets'] === '';

          const projectHasEmptyContractTypesField = matchingProject['Contract Type'] === '';

          const projectHasEmptyDistrictsField = matchingProject['Project Region'] === '';

          if (
            projectHasEmptyStatusField ||
            projectHasEmptyVerticalMarketsField ||
            projectHasEmptyContractTypesField ||
            projectHasEmptyDistrictsField
          ) {
            errorTemplate =
              errorTemplate + `${matchingProject['Project Full Name']} was not uploaded because it has empty fields: `;
            if (projectHasEmptyStatusField) {
              errorTemplate = errorTemplate + `status, `;
            }
            if (projectHasEmptyVerticalMarketsField) {
              errorTemplate = errorTemplate + `vertical market, `;
            }
            if (projectHasEmptyContractTypesField) {
              errorTemplate = errorTemplate + `contract type, `;
            }
            if (projectHasEmptyDistrictsField) {
              errorTemplate = errorTemplate + `district.`;
            }
          }
          errorTemplate = errorTemplate.slice(-2) === ', ' ? errorTemplate.slice(0, -2) + '.' : errorTemplate;
          return errorTemplate ? errorTemplate : `${project.name} was not uploaded for an unknown reason.`;
        });
        setErrorMessages(errorMessage);
      }
    });

    handleClose();
    apolloClient.reFetchObservableQueries();
  };

  return (
    <>
      <StyledDialog
        title={'Mass Project Upload'}
        content={
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <UploaderComponent
                  id="massProjectFileUpload"
                  type="file"
                  autoUpload={true}
                  buttons={{ browse: 'Choose file' }}
                  multiple={false}
                  asyncSettings={{ saveUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Save' }}
                  success={onUploadSuccess}
                  allowedExtensions=".csv"
                  locale="en-US"
                />
              </Grid>
            </Grid>
          </Grid>
        }
        actions={
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    label={'submit'}
                    type="submit"
                    onClick={onSubmitSuccess}
                    disabled={!uploadSuccess}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        isOpen={isOpen}
        handleClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      {!!errorMessages?.length && (
        <StyledDialog
          title={'Error While Uploading'}
          content={
            <>
              <Grid container>
                <Grid item>
                  <ul>
                    {errorMessages.map((errorMessage: string, index: number) => (
                      <li key={index}>{errorMessage}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
              <Grid container sx={errorMessagesContainerStyle}>
                <Grid item>{'Please update file and try again.'}</Grid>
              </Grid>
            </>
          }
          actions={
            <Grid container>
              <Grid item xs={12}>
                <StyledButtonSecondary disabled={false} label={'close'} onClick={handleErrorDialogClose} />
              </Grid>
            </Grid>
          }
          isOpen={isErrorDialogOpen}
          handleClose={handleErrorDialogClose}
          maxWidth={'md'}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        />
      )}
    </>
  );
};

export default MassProjectUploadDialog;
