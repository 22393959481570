import { Autocomplete, FormHelperText, Grid, InputLabel, SxProps, TextField, Theme } from '@mui/material';
import { FC, Fragment } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { ConsultantFormValues, formHelperErrorTextStyle } from './CreateConsultantDialog';

type ConsultantAutoCompleteProps = {
  label: string;
  formControlValue: string;
  xsSize: number;
  sxStyle: SxProps<Theme>;
  isRequired: boolean;
  options: string[];
  setValue: UseFormSetValue<ConsultantFormValues>;
  control: Control<ConsultantFormValues, any>;
};

export const ConsultantAutoComplete: FC<ConsultantAutoCompleteProps> = ({
  label,
  formControlValue,
  xsSize,
  sxStyle,
  isRequired,
  options,
  setValue,
  control,
}) => {
  return (
    <Grid item xs={xsSize}>
      <InputLabel sx={sxStyle}>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Fragment>
            <Autocomplete
              size="small"
              options={options}
              getOptionLabel={(value) => value}
              filterSelectedOptions
              onChange={(_event, value) => {
                onChange(value);
                setValue(formControlValue as keyof ConsultantFormValues, value ?? '');
              }}
              value={value as string}
              isOptionEqualToValue={(option: string, value: string) => {
                return option === value;
              }}
              renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
            />
            {isRequired && !value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
          </Fragment>
        )}
        name={formControlValue as keyof ConsultantFormValues}
        control={control}
      />
    </Grid>
  );
};
