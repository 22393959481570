export const ENTERPRISE_ADMIN = 'enterprise-admin';
export const STOCKHOLDER = 'stockholders';
export const DM = 'regional-managers';
export const MARKETING = 'marketing';
export const DEFAULT = 'default';
export const SUPPORT = 'support';
export const CRAFT = 'craft';

const roles = [ENTERPRISE_ADMIN, DM, MARKETING, STOCKHOLDER, DEFAULT, SUPPORT, CRAFT];

export default roles;
