import { ProjectDetailsSummary } from 'types';

export const PROJECT_DETAILS_SUMMARY_DICTIONARY: ProjectDetailsSummary[] = [
  {
    name: 'projectDetailsSummary.id',
    label: 'ID',
    required: false,
    key: 'Project ID',
    xs: 0,
  },
  {
    name: 'projectDetailsSummary.name',
    label: 'Name',
    required: true,
    key: 'Project Full Name',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.number',
    label: 'Number',
    required: false,
    key: 'Project Number',
    xs: 3,
  },
  {
    name: 'projectDetailsSummary.shortName',
    label: 'Short Name',
    required: false,
    key: 'Project Short Name',
    xs: 3,
  },
  {
    name: 'projectDetailsSummary.districts',
    label: 'Regions',
    required: true,
    key: 'Project Region',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.status',
    label: 'Status',
    required: true,
    key: 'Project Status',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.startDate',
    label: 'Start Date',
    required: true,
    key: 'Project Start Date',
    xs: 3,
  },
  {
    name: 'projectDetailsSummary.endDate',
    label: 'End Date',
    required: true,
    key: 'Project End Date',
    xs: 3,
  },
  {
    name: 'projectDetailsSummary.streetAddress1',
    label: 'Street Address 1',
    required: false,
    key: 'Project Street Address 1',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.streetAddress2',
    label: 'Street Address 2',
    required: false,
    key: 'Project Street Address 2',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.city',
    label: 'City',
    required: false,
    key: 'Project City',
    xs: 3,
  },
  {
    name: 'projectDetailsSummary.state',
    label: 'State',
    required: false,
    key: 'Project State Abbr.',
    xs: 1,
  },
  {
    name: 'projectDetailsSummary.zip',
    label: 'Zip',
    required: false,
    key: 'Project Zip',
    xs: 2,
  },
  {
    name: 'projectDetailsSummary.verticalMarkets',
    label: 'Vertical Markets',
    required: true,
    key: 'Vertical Markets',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.contractType',
    label: 'Contract Type',
    required: true,
    key: 'Contract Type',
    xs: 6,
  },
  {
    name: 'projectDetailsSummary.description',
    label: 'Description',
    required: false,
    key: 'Project Description',
    xs: 12,
  },
];
