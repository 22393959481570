import { Grid } from '@mui/material';
import { L10n } from '@syncfusion/ej2-base';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { CONTRACT_TYPES } from 'constants/contractTypes';
import { DISTRICTS } from 'constants/districts';
import { VERTICAL_MARKETS } from 'constants/verticalMarkets';
import useToast from 'hooks/useToast';
import Papa from 'papaparse';
import { FC } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { FormValues, RoleImport } from 'types';
import { Person } from 'types/generated/graphql';
import { PROJECT_DETAILS_SUMMARY_DICTIONARY } from '../../../constants/projectDetailsSummary';
import { ROLES_SUMMARY_DICTIONARY } from '../../../constants/rolesSummary';

type CSVUploadDialogProps = {
  setUploadSuccess: (x: boolean) => void;
  setValue: UseFormSetValue<FormValues>;
  setRolesImported: (x: []) => void;
  setProjectImported: (x: []) => void;
  canContinueAfterRolesSummary: boolean | undefined;
  setCanContinueAfterRolesSummary: (x: boolean) => void;
  personnelList: Person[];
};

const getProjectFromResults: any = (results: any) => {
  const subset = Object.fromEntries(
    [
      'Project Full Name',
      'Project Number',
      'Project Short Name',
      'Project Region',
      'Project Status',
      'Project Start Date',
      'Project End Date',
      'Project Street Address 1',
      'Project Street Address 2',
      'Project City',
      'Project State Abbr.',
      'Project Zip',
      'Vertical Markets',
      'Contract Type',
      'Project Description',
    ]
      .filter((key) => key in results[0])
      .map((key) => {
        return key === 'Project Region' || key === 'Vertical Markets'
          ? [
              key,
              results[0][key]
                .split(';')
                .map((value: string) => value.trim())
                .filter((value: string) =>
                  value !== '' && key === 'Vertical Markets'
                    ? VERTICAL_MARKETS.find((verticalMarket) => value === verticalMarket)
                    : key === 'Project Region'
                    ? DISTRICTS.find((district) => value === district.number)
                    : true,
                ),
            ]
          : key === 'Contract Type'
          ? CONTRACT_TYPES.find((contractType) => contractType === results[0][key])
            ? [key, results[0][key].trim()]
            : [key, '']
          : [key, results[0][key].trim()];
      }),
  );
  return subset;
};

const getRolesFromResults: any = (
  results: any,
  setCanContinueAfterRolesSummary: (x: boolean) => void,
  canContinueAfterRolesSummary: boolean,
  personnelList: Person[],
) => {
  const subsetArray: any = [];
  results.forEach((result: any) => {
    subsetArray.push(
      Object.fromEntries(
        ['Employee PR Number', 'Role Position', 'Role Begin Date', 'Role End Date', 'Focus Area', 'Notes']
          .filter((key) => key in result) // line can be removed to make it inclusive
          .map((key) => {
            if (canContinueAfterRolesSummary !== false && result[key]) {
              setCanContinueAfterRolesSummary(true);
            } else {
              setCanContinueAfterRolesSummary(false);
            }
            if (key === 'Employee PR Number') {
              let personID =
                personnelList.find((person: Person) => {
                  return person.employeePrNumber === result[key];
                })?.id ?? null;

              if (personID === null) {
                personID =
                  personnelList.find((person: Person) => {
                    return person?.name?.lastCommaFirst === result['Employee Name'];
                  })?.id ?? null;
              }
              return ['Employee Id', personID];
            } else {
              return [key, result[key]];
            }
          }),
      ),
    );
  });
  subsetArray.forEach((subset: RoleImport) => {
    subset.Quantity = '1';
  });
  return subsetArray;
};

const CSVUploadDialog: FC<CSVUploadDialogProps> = ({
  setUploadSuccess,
  setValue,
  setProjectImported,
  setRolesImported,
  setCanContinueAfterRolesSummary,
  canContinueAfterRolesSummary,
  personnelList,
}) => {
  const { displayToast } = useToast();
  const onUploadSuccess = (args: any) => {
    Papa.parse(args.file.rawFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        const projectImported = getProjectFromResults(results.data);
        const rolesImported = getRolesFromResults(
          results.data,
          setCanContinueAfterRolesSummary,
          canContinueAfterRolesSummary,
          personnelList,
        );

        rolesImported.forEach((role: any) => {
          Object.keys(role).forEach((roleKey: any) => {
            if (
              !role[roleKey] &&
              ROLES_SUMMARY_DICTIONARY.find((role) => {
                return roleKey === role.key;
              })?.required
            ) {
              setCanContinueAfterRolesSummary(false);
            }
          });
        });

        setUploadSuccess(true);
        setProjectImported(projectImported);
        setRolesImported(rolesImported);

        PROJECT_DETAILS_SUMMARY_DICTIONARY.forEach((projectDetailsSummary: { key: string; name: any }) => {
          setValue(projectDetailsSummary.name, projectImported[projectDetailsSummary.key]);
        });
        rolesImported.forEach((roleToImport: any) => {
          ROLES_SUMMARY_DICTIONARY.forEach((rolesSummary: { key: string; name: any }) => {
            setValue(rolesSummary.name, roleToImport[rolesSummary.key]);
          });
        });
      },
    });
  };

  const onUploadFailure = () => {
    setUploadSuccess(false);
    displayToast(
      'Error: Something went wrong while trying to upload the csv file. Please try again. If the problem persists, please contact support.',
      'error',
    );
  };

  L10n.load({
    'en-US': {
      uploader: {
        dropFilesHint: 'or Drop File Here',
      },
    },
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <UploaderComponent
            locale="en-US"
            id="csvImport"
            type="file"
            autoUpload={true}
            buttons={{ browse: 'Choose file' }}
            multiple={false}
            asyncSettings={{
              saveUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Save',
            }}
            failure={onUploadFailure}
            success={onUploadSuccess}
            allowedExtensions=".csv"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CSVUploadDialog;
