import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import apple from 'assets/images/unsupportedBrowserImages/appleOSLogo.png';
import chrome from 'assets/images/unsupportedBrowserImages/chromeBrowserLogoColor.png';
import firefox from 'assets/images/unsupportedBrowserImages/firefoxBrowserLogoColor.png';
import meo from 'assets/images/unsupportedBrowserImages/hpMeoTechnologyEmblem.png';
import linux from 'assets/images/unsupportedBrowserImages/linuxOSLogo.png';
import edge from 'assets/images/unsupportedBrowserImages/microsoftEdgeBrowserLogoColor.png';
import safari from 'assets/images/unsupportedBrowserImages/safariBrowserLogoColor.png';
import mWindows from 'assets/images/unsupportedBrowserImages/windowsOSLogo.png';
import { SERVICENOW_URL } from 'constants/externalLinks';

const browserItemContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  boxShadow: '2px 2px 10px gray',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  backgroundColor: '#FFFFFF',
  border: '8px solid #A6192E',
  borderRadius: 4,
});

const browserImgStyle: SxProps<Theme> = (theme: Theme) => ({
  maxWidth: theme.spacing(8),
  marginBottom: '2rem',
});

const osImgStyle: SxProps<Theme> = (theme: Theme) => ({
  height: theme.spacing(2),
  width: 'auto',
});

const rootStyle: SxProps<Theme> = {
  flex: 1,
  textAlign: 'center',
  backgroundColor: '#F2F2F2',
  minHeight: '100vh',
  height: '100%',
};

const meoImgStyle: SxProps<Theme> = {
  maxWidth: 200,
  margin: '80px 0 32px 0',
};

const unsupportedBrowserTextStyle: SxProps<Theme> = {
  fontWeight: 'bold',
  fontSize: '2rem',
};

const browserTextStyle: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  paddingBottom: 24,
};

const mainTextContainerStyle: SxProps<Theme> = {
  padding: 24,
  color: '#5C5C5C',
};

const mainTextEmphasizedStyle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const mainTextStyle: SxProps<Theme> = {
  fontWeight: 'normal',
};

const osTextStyle: SxProps<Theme> = {
  fontSize: '0.625rem',
};

const helpBtnStyle: SxProps<Theme> = {
  margin: '32px 0',
  backgroundColor: '#A6192E',
  color: '#FFFFFF',
  '&:hover': { backgroundColor: '#700006' },
};

const helpBtnTextStyle: SxProps<Theme> = {
  textTransform: 'capitalize',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  '&:first-letter': { textTransform: 'uppercase' },
};

export const UnsupportedBrowserDisclaimerPage = () => {
  return (
    <Grid sx={rootStyle}>
      <Grid container alignItems="center" alignContent="center" justifyContent="center">
        <Grid item>
          <Box component="img" src={meo} alt={'Hensel Phelps Meo Technology banner'} sx={meoImgStyle} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={unsupportedBrowserTextStyle}>Unsupported Browser</Typography>
        </Grid>
        <Grid item xs={12} sx={mainTextContainerStyle}>
          <Typography gutterBottom sx={mainTextEmphasizedStyle}>
            Oops! We're sorry but …
            <Grid sx={mainTextStyle}>
              {' '}
              the browser you are using is not currently supported by this application. Please select a supported
              browser below and follow the links to download.
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={5} lg={1} sx={browserItemContainerStyle}>
          <Typography sx={browserTextStyle}>Google Chrome</Typography>
          <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            <Box component="img" src={chrome} alt={'Google Chrome Logo'} sx={browserImgStyle} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography sx={osTextStyle}>Operating System</Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={mWindows} alt={'Windows Operating System Logo'} sx={osImgStyle} />
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={linux} alt={'Linux Operating System Logo'} sx={osImgStyle} />
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={apple} alt={' Apple Operating System Logo'} sx={osImgStyle} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} sx={browserItemContainerStyle}>
          <Typography sx={browserTextStyle}>Microsoft Edge</Typography>
          <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">
            <Box component="img" src={edge} alt={'Microsoft Edge Logo'} sx={browserImgStyle} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography sx={osTextStyle}>Operating System</Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={mWindows} alt="Windows Logo" sx={osImgStyle} />
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={apple} alt={'Apple Logo'} sx={osImgStyle} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} sx={browserItemContainerStyle}>
          <Typography sx={browserTextStyle}>Apple Safari</Typography>
          <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">
            <Box component="img" src={safari} alt={'Apple Safari Logo'} sx={browserImgStyle} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography sx={osTextStyle}>Operating System</Typography>
            </Grid>
            <Grid item>
              <Box component="img" src={apple} alt={'Apple Logo'} sx={osImgStyle} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} sx={browserItemContainerStyle}>
          <Typography sx={browserTextStyle}>Mozilla Firefox</Typography>
          <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
            <Box component="img" src={firefox} alt={'Firefox Logo'} sx={browserImgStyle} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography sx={osTextStyle}>Operating System</Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={mWindows} alt={'Windows Logo'} sx={osImgStyle} />
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={linux} alt={'Linux Logo'} sx={osImgStyle} />
            </Grid>
            <Grid item md={1} lg={4}>
              <Box component="img" src={apple} alt={'Apple Logo'} sx={osImgStyle} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              size="small"
              variant="contained"
              sx={helpBtnStyle}
              onClick={() => window.open(SERVICENOW_URL, '_blank')}
            >
              <Typography sx={helpBtnTextStyle}>Need Help?</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnsupportedBrowserDisclaimerPage;
