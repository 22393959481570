export const VERTICAL_MARKETS = [
  'Aviation',
  'Aerospace',
  'Civic',
  'Correctional',
  'Data Center',
  'Energy/Power',
  'Ground Transportation',
  'Healthcare',
  'Higher Education',
  'Hospitality',
  'Housing',
  'Industrial',
  'Infrastructure',
  'K-12',
  'Manufacturing',
  'Office',
  'Research/Laboratories',
  'Retail',
  'Sports and Entertainment',
  'Water/Wastewater',
];
