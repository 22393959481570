import { Card, Grid, GridProps, SxProps, Theme, Typography } from '@mui/material';
import unauthorizedAccessSvg from '../../../assets/images/unauthorizedAccessLight.svg';

const subHeadingStyle: SxProps<Theme> = (theme: Theme) => ({ marginBottom: theme.spacing(1) });
const imgStyle: SxProps<Theme> = (theme: Theme) => ({ width: 200, padding: theme.spacing(3) });

const containerStyle: SxProps<Theme> = {};
const headingStyle: SxProps<Theme> = {
  fontSize: '2rem',
  fontWeight: 'bold',
};

interface UnauthorizedMessageProps extends GridProps {
  unauthorizedMessage?: string;
  children?: React.ReactNode;
}

const UnauthorizedPageNotice = ({
  unauthorizedMessage = 'You are not currently an authorized user for this application. You can request access using the link below, after which any currently authorized user for the application will be able to resolve your request.',
  children,
  ...rest
}: UnauthorizedMessageProps) => {
  return (
    <Grid container sx={containerStyle} justifyContent="center" alignItems="center" alignContent="center" {...rest}>
      <Grid item>
        <Card>
          <Grid item sx={imgStyle}>
            <img src={unauthorizedAccessSvg} alt="" role="presentation" />
          </Grid>
          <Grid item>
            <Typography gutterBottom sx={headingStyle}>
              Unauthorized Access
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={subHeadingStyle}>{unauthorizedMessage}</Typography>
          </Grid>
          <Grid item>{children}</Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UnauthorizedPageNotice;
