import { roleSort } from 'utils/roles';

export interface Role {
  roleName: string;
  abbreviation: string;
  fullDisplayName: string;
  rank?: number;
  type?: string;
}

// Commented out roles are currently out of scope and will be added back in in a later version.

export const ROLES: Role[] = [
  { roleName: 'Accountant', abbreviation: 'ACCT', type: 'CRAFT' },
  { roleName: 'Administrative Assistant', abbreviation: 'ADM', type: 'CRAFT' },
  { roleName: 'Area Superintendent', abbreviation: 'AS', rank: 3 },
  // { roleName: 'Archive Clerk', abbreviation: 'ADM' },
  { roleName: 'Area Safety Manager', abbreviation: 'SAFETYAM' },
  { roleName: 'Assistant Construction Manager', abbreviation: 'ACRM' },
  // { roleName: 'Assistant Equipment Manager', abbreviation: 'ADM' },
  // { roleName: 'Assistant Facility Manager', abbreviation: 'ADM' },
  // { roleName: 'Assistant General Counsel', abbreviation: 'ADM' },
  // { roleName: 'Assistant to the President', abbreviation: 'ADM' },
  // { roleName: 'Associate General Counsel', abbreviation: 'LEGAL' },
  { roleName: 'BIM', abbreviation: 'BIM' },
  { roleName: 'BIM Engineer', abbreviation: 'BIME' },
  // { roleName: 'Billings and Payables Accounting Manager', abbreviation: 'ACCT' },
  { roleName: 'Building Engineer', abbreviation: 'BE' },
  { roleName: 'Business Development Manager', abbreviation: 'BDM' },
  // { roleName: 'CO Captain', abbreviation: 'CORP' },
  // { roleName: 'CEO', abbreviation: 'CORP' },
  // { roleName: 'CFO', abbreviation: 'CORP' },
  { roleName: 'Carpenter', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Carpenter Apprentice', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Carpenter Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Carpenter General Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Carpenter Journeyman', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Center of Excellence (CoE) Manager', abbreviation: 'CECEM' },
  { roleName: 'Cement Finisher', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Cement Finisher Apprentice', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Cement Finisher Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Cement Finisher General Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Cement Finisher Journeyman', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Chief Engineer', abbreviation: 'CE' },
  { roleName: 'Chief Estimator', abbreviation: 'ESTC' },
  // { roleName: 'Chief Information Officer', abbreviation: 'CORP' },
  // { roleName: 'Chief Pilot', abbreviation: 'CORP' },
  // { roleName: 'Commissioning Manager', abbreviation: 'CM' },
  { roleName: 'Construction Superintendent', abbreviation: 'SUPER' },
  { roleName: 'Consultant', abbreviation: 'CONS' },
  // { roleName: 'Controller', abbreviation: 'ACCT' },
  // { roleName: 'Controls System Engineer', abbreviation: 'CSE' },
  { roleName: 'Coord of Supplier Diversity', abbreviation: 'CSD' },
  // { roleName: 'Coordinator', abbreviation: 'COORD' },
  { roleName: 'Coordinator of Supplier Diversity', abbreviation: 'CSD' },
  { roleName: 'Corp Dir of Supplier Diversity', abbreviation: 'CORP' },
  // { roleName: 'Corp Director of Safety Health', abbreviation: 'CDSH' },
  // { roleName: 'Corporate Counsel', abbreviation: 'LEGAL' },
  // { roleName: 'Corporate Director of Project Development', abbreviation: 'CDPD' },
  // { roleName: 'Corporate Scheduling Manager', abbreviation: 'CSM' },
  { roleName: 'Crane Operator', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Custodial Tech', abbreviation: 'CT', type: 'CRAFT' },
  // { roleName: 'Cyber Security Assurance Specialist', abbreviation: 'CYBER' },
  { roleName: 'Design Manager', abbreviation: 'DM' },
  // { roleName: 'Development Manager', abbreviation: 'DM' },
  // { roleName: 'Diesel Mechanic', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Dir of Continuous Improvement', abbreviation: 'CORP' },
  // { roleName: 'Dir of Organization Dev', abbreviation: 'CORP' },
  { roleName: 'Dir of Supplier Diversity', abbreviation: 'CORP' },
  // { roleName: 'Director Aircraft Maint Mech', abbreviation: 'CORP' },
  // { roleName: 'Director of Design Services', abbreviation: 'DDS' },
  // { roleName: 'Director of Electrical Service', abbreviation: 'DES' },
  // { roleName: 'Director of Facility Solutions', abbreviation: 'DFS' },
  // { roleName: 'Director of Innovation', abbreviation: 'DI' },
  // { roleName: 'Director of Marketing & Communications', abbreviation: 'CORP' },
  // { roleName: 'Director of Mechanical Service', abbreviation: 'DMS' },
  { roleName: 'Director of Operations', abbreviation: 'DO' },
  // { roleName: 'Director of Planning', abbreviation: 'DPLNG' },
  // { roleName: 'Director of Safety and Health', abbreviation: 'DSH' },
  { roleName: 'Director of Supplier Diversity', abbreviation: 'CORP' },
  // { roleName: 'Director of Tech Infrastructure', abbreviation: 'DTI' },
  // { roleName: 'Director of Technology', abbreviation: 'IT' },
  // { roleName: 'District Safety Director', abbreviation: 'DSD' },
  // { roleName: 'District Scheduler', abbreviation: 'DS' },
  { roleName: 'Electrician', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Equipment Manager', abbreviation: 'EM', type: 'CRAFT' },
  // { roleName: 'Escort', abbreviation: 'ESCT' },
  { roleName: 'Estimator', abbreviation: 'EST' },
  // { roleName: 'Estimator', abbreviation: 'E' },
  { roleName: 'Estimator Associate', abbreviation: 'ESTA' },
  // { roleName: 'Event Coordinator', abbreviation: 'CORP' },
  // { roleName: 'Executive Vice President', abbreviation: 'CORP' },
  // { roleName: 'Facility Services Coordinator', abbreviation: 'FSC' },
  // { roleName: 'Facility Solutions Associate', abbreviation: 'FSA' },
  // { roleName: 'Facility Solutions Manager', abbreviation: 'FSM' },
  // { roleName: 'Facility Solutions Regional Manager', abbreviation: 'FSRM' },
  // { roleName: 'Facility Support Technician', abbreviation: 'FST' },
  { roleName: 'Field Engineer', abbreviation: 'FE', rank: 6 },
  { roleName: 'Foreman', abbreviation: 'Craft', type: 'CRAFT' },
  { roleName: 'Forklift Operator', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'General Counsel', abbreviation: 'LEGAL' },
  { roleName: 'General Foreman', abbreviation: 'GF', type: 'CRAFT' },
  { roleName: 'General Maintenance Tech', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'General Superintendent', abbreviation: 'GS' },
  // { roleName: 'Graphic Designer', abbreviation: 'GD' },
  { roleName: 'Heavy Equipment Operator', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'HVAC Tech', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Instructional Designer', abbreviation: 'MKTG' },
  { roleName: 'Ironworker Journeyman', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'IT Application Manager', abbreviation: 'IT' },
  // { roleName: 'IT Application Support Analyst I', abbreviation: 'IT' },
  // { roleName: 'IT Application Support Analyst II', abbreviation: 'IT' },
  // { roleName: 'IT Data Analyst II', abbreviation: 'IT' },
  // { roleName: 'IT Data Service Manager', abbreviation: 'IT' },
  // { roleName: 'IT Development Manager / Solution Architect', abbreviation: 'IT' },
  // { roleName: 'IT Infrastructure Manager', abbreviation: 'IT' },
  // { roleName: 'IT Network Manager', abbreviation: 'IT' },
  { roleName: 'IT Operations Engineer I', abbreviation: 'IT' },
  { roleName: 'IT Operations Engineer II', abbreviation: 'IT' },
  { roleName: 'IT Operations Manager', abbreviation: 'IT' },
  // { roleName: 'IT Project Coordinator', abbreviation: 'IT' },
  // { roleName: 'IT Project Manager', abbreviation: 'IT' },
  // { roleName: 'IT Security Administrator', abbreviation: 'IT' },
  // { roleName: 'IT Security Analyst I', abbreviation: 'IT' },
  // { roleName: 'IT Security Analyst II', abbreviation: 'IT' },
  // { roleName: 'IT Security Manager', abbreviation: 'IT' },
  // { roleName: 'IT Senior Data Engineer', abbreviation: 'IT' },
  { roleName: 'IT Senior Operations Engineer', abbreviation: 'IT' },
  // { roleName: 'IT Senior Software Engineer', abbreviation: 'IT' },
  // { roleName: 'IT Senior Systems Administrator', abbreviation: 'IT' },
  // { roleName: 'IT Service Manager', abbreviation: 'IT' },
  // { roleName: 'IT Software Engineer I', abbreviation: 'IT' },
  // { roleName: 'IT Software Engineer II', abbreviation: 'IT' },
  // { roleName: 'IT Systems Engineer I', abbreviation: 'IT' },
  { roleName: 'IT Technician', abbreviation: 'IT', type: 'CRAFT' },
  // { roleName: 'IT Unified Communications Manager', abbreviation: 'IT' },
  { roleName: 'Jobsite Administrator', abbreviation: 'JADMIN', type: 'CRAFT' },
  { roleName: 'Laborer', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Laborer Apprentice', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Laborer Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Laborer General Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Lead Development Coordinator', abbreviation: 'DCL' },
  { roleName: 'Lead Estimator', abbreviation: 'ESTL' },
  { roleName: 'Lead Quality Control Engineer', abbreviation: 'QCL' },
  { roleName: 'Lead VDC Engineer', abbreviation: 'VDCL' },
  { roleName: 'Light Equipment Operator', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Mailroom Clerk', abbreviation: 'ADM' },
  { roleName: 'Manager of Supplier Diversity', abbreviation: 'MSD' },
  // { roleName: 'Manager of Technical Documents', abbreviation: 'MTD' },
  { roleName: 'Marketing & Communications Lead', abbreviation: 'MKTG' },
  { roleName: 'Marketing & Communications Mgr', abbreviation: 'MKTG' },
  { roleName: 'Marketing Coordinator', abbreviation: 'MC' },
  // { roleName: 'Marketing Intern', abbreviation: 'INT' },
  // { roleName: 'Marketing Manager', abbreviation: 'MKTG' },
  { roleName: 'Mason', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'MEP Coordinator', abbreviation: 'MEPC' },
  // { roleName: 'MES Coordinator', abbreviation: 'MESCOORD' },
  // { roleName: 'MMS Coordinator', abbreviation: 'MMSCOORD' },
  // { roleName: 'Millwright', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Millwright Apprentice', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Millwright Foreman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Millwright Journeyman', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Office Engineer', abbreviation: 'OE', rank: 5 },
  { roleName: 'Office Manager', abbreviation: 'OM' },
  { roleName: 'Operations Intern', abbreviation: 'INT', type: 'CRAFT' },
  { roleName: 'Operations Manager', abbreviation: 'OM' },
  { roleName: 'Operator Foreman', abbreviation: 'OF', type: 'CRAFT' },
  { roleName: 'Operator General Foreman', abbreviation: 'OGF', type: 'CRAFT' },
  // { roleName: 'Organizational Dev Facilitator', abbreviation: 'ODF' },
  // { roleName: 'Outside Consultants', abbreviation: 'CONSULTANT' },
  // { roleName: 'Paralegal', abbreviation: 'LEGAL' },
  { roleName: 'Part-Time Project Accountant', abbreviation: 'ACCT' },
  // { roleName: 'Payroll Accounting Manager', abbreviation: 'ACCT' },
  // { roleName: 'Plumber', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Pre-Apprentice Carpenter', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Precon Manager', abbreviation: 'PRECON' },
  { roleName: 'Preconstruction Facilitator', abbreviation: 'PREF' },
  { roleName: 'Project Accountant', abbreviation: 'PACCT', type: 'CRAFT' },
  { roleName: 'Project Coordinator', abbreviation: 'PC' },
  // { roleName: 'Project Development Coordinator', abbreviation: 'PDC' },
  { roleName: 'Project Development Director', abbreviation: 'PDD' },
  { roleName: 'Project Development Manager', abbreviation: 'PDM' },
  // { roleName: 'Project Director', abbreviation: 'PD' },
  { roleName: 'Project Engineer', abbreviation: 'PE', rank: 4 },
  { roleName: 'Project Manager', abbreviation: 'PM', rank: 1 },
  { roleName: 'Project Superintendent', abbreviation: 'PS', rank: 2 },
  { roleName: 'Proposal Lead', abbreviation: 'PL' },
  // { roleName: 'Proposal Manager', abbreviation: 'PRPM' },
  { roleName: 'Quality Control Engineer', abbreviation: 'QCE' },
  // { roleName: 'Quality Control Lead Engineer', abbreviation: 'QCL' },
  { roleName: 'Quality Control Manager', abbreviation: 'QCM' },
  // { roleName: 'Quantity Surveyor', abbreviation: 'QS' },
  { roleName: 'Regional Director of S&H', abbreviation: 'RDSH' },
  // { roleName: 'Regional Director of Tech Infrastructure', abbreviation: 'RDTI' },
  // { roleName: 'Regional IFM Director', abbreviation: 'RIFMD' },
  // { roleName: 'Regional IFM Manager', abbreviation: 'RIFMM' },
  // { roleName: 'Regional Vice President', abbreviation: 'CORP' },
  { roleName: 'Rodman', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Safety', abbreviation: 'SAFETY' },
  { roleName: 'Safety Engineer', abbreviation: 'SFTY' },
  { roleName: 'Safety Manager', abbreviation: 'SFTYM' },
  // { roleName: 'Scheduler', abbreviation: 'SCHED' },
  { roleName: 'Scheduler/Planner', abbreviation: 'SCHLR' },
  { roleName: 'Senior Accountant', abbreviation: 'ACCT' },
  { roleName: 'Senior Estimator', abbreviation: 'ESTS' },
  { roleName: 'Senior Marketing Coordinator', abbreviation: 'SMC' },
  // { roleName: 'Senior Marketing Manager', abbreviation: 'SMM' },
  // { roleName: 'Senior Project Development Coordinator', abbreviation: 'PDCS' },
  { roleName: 'Senior Quality Control Manager', abbreviation: 'QCSM' },
  { roleName: 'Senior Safety Manager', abbreviation: 'SFTYSM' },
  { roleName: 'Senior Tax Accountant', abbreviation: 'ACCT' },
  { roleName: 'Senior VDC Manager', abbreviation: 'VDCSM' },
  // { roleName: 'Sheet Metal Worker', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Site Admin/Jobsite Administrator', abbreviation: 'JADMIN', type: 'CRAFT' },
  // { roleName: 'Site Director I', abbreviation: 'SDI' },
  // { roleName: 'Site Director II', abbreviation: 'SDII' },
  // { roleName: 'Skilled Craft Helper', abbreviation: 'CRAFT', type: 'CRAFT' },
  { roleName: 'Skilled Laborer', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Software Application Trainer', abbreviation: 'CORP' },
  { roleName: 'Scheduling Engineer', abbreviation: 'SCHE' },
  { roleName: 'Scheduling Manager', abbreviation: 'SCHM' },
  { roleName: 'Talent Acquisition Coordinator', abbreviation: 'TAC', type: 'CRAFT' },
  { roleName: 'Talent Acquisition Dev Mgr', abbreviation: 'TAM' },
  { roleName: 'Talent Acquisition Manager', abbreviation: 'TAM' },
  // { roleName: 'Tax Manager', abbreviation: 'TAX' },
  // { roleName: 'Tech Infrastructure PM', abbreviation: 'TIPM' },
  // { roleName: 'Trainer Coordinator', abbreviation: 'TRNC' },
  { roleName: 'VDC Engineer', abbreviation: 'VDCE' },
  { roleName: 'VDC Manager', abbreviation: 'VDCM' },
  { roleName: 'Vice President', abbreviation: 'CORP' },
  // { roleName: 'Warehouse Associate', abbreviation: 'CRAFT', type: 'CRAFT' },
  // { roleName: 'Yard and Shop Technician', abbreviation: 'CRAFT', type: 'CRAFT' },
]
  .map((role) => ({ ...role, fullDisplayName: `${role.abbreviation} - ${role.roleName}` }))
  .sort(roleSort);
// Prevent further tampering just to make sure we don't accidentally break the sort.
Object.freeze(ROLES);

export const ROLES_BY_NAME = ROLES.reduce<{ [name: string]: Role }>((accumulator, role) => {
  accumulator[role.roleName] = role;
  return accumulator;
}, {});

export const ROLES_BY_ABBREVIATION = ROLES.reduce<{ [name: string]: Role }>((accumulator, role) => {
  accumulator[role.abbreviation] = role;
  return accumulator;
}, {});

export const ROLES_BY_FULL_DISPLAY_NAME = ROLES.reduce<{ [name: string]: Role }>((accumulator, role) => {
  accumulator[role.fullDisplayName] = role;
  return accumulator;
}, {});

export const ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE = (isCraft: boolean) => {
  const craftRolesFullDisplayName = ROLES.filter((role) => role.type === 'CRAFT').map((r) => r.fullDisplayName);
  const nonCraftRolesFullDisplayName = ROLES.filter((role) => role.type !== 'CRAFT').map((r) => r.fullDisplayName);

  return isCraft ? craftRolesFullDisplayName : nonCraftRolesFullDisplayName;
};
