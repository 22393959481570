import { DateTime } from 'luxon';
import { ComponentProps, FC } from 'react';

import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const StyledDatePicker: FC<ComponentProps<typeof DatePicker<DateTime>>> = ({
  slotProps = {
    actionBar: {
      actions: ['clear', 'cancel', 'today'],
    },
    textField: { fullWidth: true },
  },
  ...rest
}) => {
  return (
    <>
      <Grid item>
        <DatePicker<DateTime> slotProps={slotProps} {...rest} />
      </Grid>
    </>
  );
};

export default StyledDatePicker;
