import { FC, useEffect, useRef, useState } from 'react';

import { Card, CardContent, Grid, Popover, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import HelpTooltip from 'components/shared/HelpTooltip';
import { USER_INPUT_SOURCE } from 'constants/tooltips';
import { GetSingleProjectQuery } from 'types/generated/graphql';

const fieldStyles: SxProps<Theme> = () => ({
  fontWeight: '600',
  fontSize: '17px',
  color: 'rgb(105, 105, 105)',
});

const firstFieldContainerStyles: SxProps<Theme> = () => ({
  marginBottom: '20px',
  minWidth: '233px',
});

const secondFieldContainerStyles: SxProps<Theme> = () => ({
  marginBottom: '12px',
  minWidth: '233px',
});

const leftSectionTextStyles: SxProps<Theme> = {
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

type ProjectDetailsInfoFieldsProps = {
  project?: GetSingleProjectQuery['project'];
};

type InfoFieldProps = {
  label: string;
  info: string;
  tooltip: string;
  styles: (theme: Theme) => SystemStyleObject<Theme>;
};

function useIsOverflowing() {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsOverflowing(element.offsetWidth < element.scrollWidth);
    }
  });

  return [ref, isOverflowing] as const;
}

const InfoField: FC<InfoFieldProps> = ({ label, info, tooltip, styles }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [textRef, isTextOverflowing] = useIsOverflowing();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container sx={styles}>
      <Grid item xs={5}>
        <Typography sx={fieldStyles}>
          <HelpTooltip tooltipText={tooltip} titleText={label + ':'} />
        </Typography>
      </Grid>
      <Grid item xs={7} textAlign={'right'}>
        <Typography
          ref={textRef}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={isTextOverflowing ? handlePopoverOpen : undefined}
          onMouseLeave={isTextOverflowing ? handlePopoverClose : undefined}
          sx={leftSectionTextStyles}
          color="textPrimary"
          className="inline"
          maxWidth={800}
        >
          {info}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={isTextOverflowing && open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{info}</Typography>
        </Popover>
      </Grid>
    </Grid>
  );
};

const ProjectDetailsInfoFields: FC<ProjectDetailsInfoFieldsProps> = ({ project }) => {
  const projectDetailsFirstCardArr = [
    {
      label: 'Project Number',
      info: project?.number,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Address',
      info: project?.location?.cityAndStateDisplay,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Description',
      info: project?.description,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Start Date',
      info: project?.startDate,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'End Date',
      info: project?.endDate,
      tooltip: USER_INPUT_SOURCE,
    },
  ];

  const projectDetailsSecondCardArr = [
    {
      label: 'Regions',
      info: project?.districts?.join(', '),
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Status',
      info: project?.status,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Vertical Markets',
      info: project?.verticalMarkets?.join(', '),
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Contract Type',
      info: project?.contractType,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Procurement Date',
      info: project?.procurementDate,
      tooltip: USER_INPUT_SOURCE,
    },
    {
      label: 'Awarded Date',
      info: project?.awardedDate,
      tooltip: USER_INPUT_SOURCE,
    },
  ];
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            {projectDetailsFirstCardArr.map((item, index) => (
              <InfoField
                key={index}
                label={item.label}
                info={item.info}
                tooltip={item.tooltip}
                styles={firstFieldContainerStyles}
              />
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            {projectDetailsSecondCardArr.map((item, index) => (
              <InfoField
                key={index}
                label={item.label}
                info={item.info}
                tooltip={item.tooltip}
                styles={secondFieldContainerStyles}
              />
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProjectDetailsInfoFields;
