import { Card, CardContent, Grid, SxProps, Theme, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { personnelIsConsultant } from 'constants/consultant';
import { FC, useState } from 'react';
import { Person } from 'types/generated/graphql';

type PersonnelStaticRightSectionProps = {
  personnel: Person;
};

type PersonnelMapProps = {
  personnel: Person;
};

type RightSectionCardProps = {
  label: string;
  text: string;
};

const personnelMapStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const rightSectionCardStyles: SxProps<Theme> = {
  width: 500,
};

const rightSectionGridStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '5px',
  height: '12vh',
};

const rightSectionStyles: SxProps<Theme> = {
  display: 'flex',
  height: '90vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'sticky',
};

const rightSectionConsultantStyles: SxProps<Theme> = {
  display: 'flex',
  height: '26vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'sticky',
};

const rightSectionCardLabelStyles: SxProps<Theme> = {
  textAlign: 'center',
};

const rightSectionCardTextStyles: SxProps<Theme> = {
  textAlign: 'center',
  fontSize: '15px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const PersonnelMap = ({ personnel }: PersonnelMapProps) => {
  const personnelAddress = `${personnel?.location?.streetAndNumber ?? ''}, ${personnel?.location?.city ?? ''}, ${
    personnel?.location?.stateOrProvince ?? ''
  } ${personnel?.location?.zipOrPostalCode ?? ''}`;
  return (
    <Grid item xs={12} sx={personnelMapStyles}>
      <iframe
        title="Google Maps"
        width="450"
        height="250"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCHezmO-FsvZyFPVyr6FkPBz5GcvvVYD1U&q=${personnelAddress}`}
      ></iframe>
    </Grid>
  );
};

const RightSectionCard = ({ label, text }: RightSectionCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12} sx={rightSectionGridStyles}>
      <Card sx={rightSectionCardStyles}>
        <CardContent>
          <Typography sx={rightSectionCardLabelStyles} gutterBottom variant="h6" component="div">
            {label}
          </Typography>
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={rightSectionCardTextStyles}
            variant="body2"
            color="text.secondary"
          >
            {text}
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>{text}</Typography>
          </Popover>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const PersonnelStaticRightSection: FC<PersonnelStaticRightSectionProps> = ({ personnel }) => {
  const verticalMarkets = personnel?.personVerticalMarkets
    ?.map(
      (personVerticalMarket) => `${personVerticalMarket.verticalMarket} (${personVerticalMarket.verticalMarketYears}y)`,
    )
    .join(', ');

  return (
    <Grid item xs={2} sx={personnelIsConsultant(personnel) ? rightSectionConsultantStyles : rightSectionStyles}>
      <Grid container rowSpacing={1}>
        <PersonnelMap personnel={personnel} />
        {!personnelIsConsultant(personnel) && (
          <>
            <RightSectionCard label="Total Length of Service" text={personnel?.personTenureYear ?? ''} />
            <RightSectionCard label="Industry Experience" text={personnel?.industryLengthOfExperience ?? ''} />
          </>
        )}
        <RightSectionCard label="Regions" text={personnel?.districts.join(', ') ?? ''} />
        {!personnelIsConsultant(personnel) && (
          <>
            <RightSectionCard label="Contract Types" text={personnel?.contractTypes.join(', ') ?? ''} />
            <RightSectionCard label="Vertical Experience" text={verticalMarkets ?? ''} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PersonnelStaticRightSection;
