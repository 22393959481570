import { Flag } from '@mui/icons-material';
import { Card, CardContent, Grid, Popover, SxProps, Theme, Typography } from '@mui/material';
import HelpTooltip from 'components/shared/HelpTooltip';
import { personnelIsConsultant } from 'constants/consultant';
import { USER_INPUT_SOURCE } from 'constants/tooltips';
import { FC, useState } from 'react';
import { Person } from 'types/generated/graphql';
import { graphqlDateToDateTime } from 'utils/general';
import { LeftSectionEditableFieldsForm } from './LeftSectionEditableFieldsForm';

const editableCardStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const justifyContentSpaceBetweenStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
};

const labelStyle: SxProps<Theme> = {
  fontFamily:
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  minWidth: '233px',
  fontWeight: '500',
};

const leftSectionTextStyles: SxProps<Theme> = {
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

type LeftSectionEditableFieldsProps = {
  isEditing: boolean;
  setIsEditing: (x: boolean) => void;
  personnel: Person;
};

type LeftSectionEditableFieldProps = {
  titleText: string;
  fieldValue?: string;
  showFlag?: boolean;
};

const LeftSectionEditableField: FC<LeftSectionEditableFieldProps> = ({ titleText, fieldValue, showFlag }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item sx={labelStyle} xs={12}>
      <Grid container sx={justifyContentSpaceBetweenStyles}>
        <Grid item xs={6}>
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={titleText} />
        </Grid>
        <Grid item xs={6}>
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={leftSectionTextStyles}
            color="textPrimary"
          >
            {fieldValue}
            {showFlag && <Flag />}
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>
              {fieldValue}
              {showFlag && <Flag />}
            </Typography>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const LeftSectionEditableFields: FC<LeftSectionEditableFieldsProps> = ({
  isEditing,
  setIsEditing,
  personnel,
}) => {
  return (
    <Grid item xs={12} sx={editableCardStyles}>
      <Card>
        <CardContent>
          {!isEditing && (
            <Grid container rowSpacing={1}>
              <LeftSectionEditableField titleText="Preferred Name:" fieldValue={personnel?.name?.preferred ?? ''} />
              {personnel && personnelIsConsultant(personnel) && (
                <LeftSectionEditableField titleText="Company:" fieldValue={personnel?.company ?? ''} />
              )}
              {personnel && !personnelIsConsultant(personnel) && (
                <>
                  <LeftSectionEditableField
                    titleText="Possible Promotion Title:"
                    fieldValue={personnel?.possiblePromotionTitle ?? ''}
                  />
                  <LeftSectionEditableField
                    titleText="Possible Promotion Date:"
                    fieldValue={personnel?.possiblePromotionDate ?? ''}
                  />
                </>
              )}
              <LeftSectionEditableField
                titleText="Anticipated Separation Date:"
                fieldValue={
                  (personnel?.anticipatedSeparationDate &&
                    graphqlDateToDateTime(personnel?.anticipatedSeparationDate).toFormat('yyyy-MM-dd')) ??
                  ''
                }
              />
              {personnel && !personnelIsConsultant(personnel) && (
                <LeftSectionEditableField
                  titleText="Shared with Regions:"
                  fieldValue={personnel?.sharedWithDistricts?.join(', ') ?? ''}
                />
              )}
              <LeftSectionEditableField titleText="Notes Flag:" showFlag={personnel?.notesFlag ?? false} />
              <LeftSectionEditableField titleText="Notes:" fieldValue={personnel?.notes ?? ''} />
            </Grid>
          )}
          {isEditing && <LeftSectionEditableFieldsForm setIsEditing={setIsEditing} personnel={personnel} />}
        </CardContent>
      </Card>
    </Grid>
  );
};
