import UnauthorizedPageNotice from 'components/common/UnauthorizedPageNotice';
import { FC } from 'react';

export const Reporting: FC = () => {
  // TODO: Handle authorization
  const isUnauthorized = false;

  const powerBiReportingLink =
    'https://app.powerbi.com/reportEmbed?reportId=5a59e009-c0cb-482a-9478-176e74f89877&autoAuth=true&ctid=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

  if (isUnauthorized) {
    return <UnauthorizedPageNotice />;
  } else {
    return (
      <iframe
        title="People360-Reports-Section"
        width="100%"
        height="100%"
        src={powerBiReportingLink}
        allowFullScreen={true}
      ></iframe>
    );
  }
};

export default Reporting;
