import { useGlobalSearchParams } from 'components/SearchParamsProvider/SearchParamsProvider';
import { DateTime } from 'luxon';
import { useState } from 'react';

type FilterTypes = {
  string: string;
  stringArray: string[];
  date: DateTime;
  boolean: boolean;
};

export type SupportedFilter = keyof FilterTypes;

type FilterDeserializer<Filter extends SupportedFilter> = { (value: string | null): FilterTypes[Filter] | null };

const optionalStringNoOp = (value: string | null) => value;

const FILTER_DESERIALIZERS: { [Filter in SupportedFilter]: FilterDeserializer<Filter> } = {
  string: optionalStringNoOp,
  date: (value) => DateTime.fromISO(value ?? ''),
  stringArray: (value) => (value ? value.split(',') : []),
  boolean: (value) => (value === 'true' ? true : value === 'false' ? false : null),
};

type FilterValueValidChecker<Filter extends SupportedFilter> = { (value: FilterTypes[Filter]): boolean };

const isStringNotEmpty = (value: string) => !!value;

const FILTER_VALUE_VALID_CHECKERS: { [Filter in SupportedFilter]: FilterValueValidChecker<Filter> } = {
  string: isStringNotEmpty,
  date: (value) => !!value && value.isValid,
  stringArray: (value) => value.length > 0,
  boolean: (value) => typeof value === 'boolean',
};

const deserialize = <Filter extends SupportedFilter>(
  filter: Filter,
  value: string | null,
): FilterTypes[Filter] | null => {
  if (value === null) {
    return null;
  }
  const deserializedValue = FILTER_DESERIALIZERS[filter](value);
  return deserializedValue !== null && FILTER_VALUE_VALID_CHECKERS[filter](deserializedValue)
    ? deserializedValue
    : null;
};

type FilterSerializer<Filter extends SupportedFilter> = { (value: FilterTypes[Filter]): string | null };

const FILTER_SERIALIZERS: { [Filter in SupportedFilter]: FilterSerializer<Filter> } = {
  string: optionalStringNoOp,
  date: (value) => (value && value.isValid && value.toISODate()) || null,
  stringArray: (value) => (value.length > 0 ? value.join(',') : null),
  boolean: (value) => (value ? 'true' : 'false'),
};

const serialize = <Filter extends SupportedFilter>(
  filter: Filter,
  value: FilterTypes[Filter] | null,
): string | null => {
  if (value === null) {
    return null;
  }
  return FILTER_SERIALIZERS[filter](value);
};

type UseFilterParams<T extends SupportedFilter> = {
  filter: T;
  searchParamKey?: string;
  localStorageKey?: string;
};

const useDataFilter = <T extends SupportedFilter>({ filter, searchParamKey, localStorageKey }: UseFilterParams<T>) => {
  const [searchParams, setSearchParams] = useGlobalSearchParams();
  let initialSerializedValue: string | null = null;
  if (searchParamKey) {
    initialSerializedValue = searchParams.get(searchParamKey);
  }
  if (localStorageKey) {
    if (initialSerializedValue === null) {
      initialSerializedValue = window.localStorage.getItem(localStorageKey);
    } else {
      window.localStorage.setItem(localStorageKey, initialSerializedValue);
    }
  }

  const [value, setValue] = useState<FilterTypes[typeof filter] | null>(deserialize(filter, initialSerializedValue));

  const updateValue = (newValue: FilterTypes[typeof filter] | null) => {
    setValue(newValue);
    const serializedValue = serialize(filter, newValue);
    if (localStorageKey) {
      if (serializedValue) {
        window.localStorage.setItem(localStorageKey, serializedValue);
      } else {
        window.localStorage.removeItem(localStorageKey);
      }
    }
    if (searchParamKey) {
      setSearchParams((previousParams) => {
        const serializedValue = serialize(filter, newValue);
        if (serializedValue) {
          previousParams.set(searchParamKey, serializedValue);
        } else {
          previousParams.delete(searchParamKey);
        }
        return previousParams;
      });
    }
  };

  return [value, updateValue] as const;
};

export default useDataFilter;
