import { SxProps, Theme, styled } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { FC } from 'react';

const successStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

const errorStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

const warningStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

const infoStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.info.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

export type ToastProviderProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => {
  return {
    '&.notistackMuiContentSuccess': successStyle,
    '&.notistackMuiContentError': errorStyle,
    '&.notistackMuiContentWarning': warningStyle,
    '&.notistackMuiContentInfo': infoStyle,
  };
});

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={2}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
