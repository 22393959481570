import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

type StyledTableProps = {
  title: ReactNode;
  data: any[];
  columns: MUIDataTableColumn[];
  options?: MUIDataTableOptions;
};

const StyledTable: React.FC<StyledTableProps> = ({ title, data, columns, options, ...rest }) => {
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 960 });

  const getTableStackType = () => {
    if (data?.length > 0) {
      return isMobileDevice ? 'vertical' : 'standard';
    } else {
      return 'simple';
    }
  };
  const defaultOptions: MUIDataTableOptions = {
    enableNestedDataAccess: '.',
    fixedHeader: true,
    filterType: 'dropdown',
    filter: true,
    print: false,
    selectToolbarPlacement: 'none',
    selectableRowsHeader: false,
    selectableRows: 'none',
    selectableRowsOnClick: false,
    caseSensitive: false,
    viewColumns: false,
    responsive: getTableStackType(),
    rowsPerPage: isMobileDevice ? 10 : 50,
    rowsPerPageOptions: [10, 20, 50, 100],
    downloadOptions: {
      filename: `${title?.toString().split(' ').join('_')}.csv`,
    },
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
      // https://github.com/gregnb/mui-datatables/pull/722#issuecomment-526346440
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
  };
  return (
    <MUIDataTable title={title} data={data} columns={columns} options={{ ...defaultOptions, ...options }} {...rest} />
  );
};

export default StyledTable;
