import { getAdUserByHpEmployeeNumber, getAdUserImage } from 'config/microsoftGraph';
import useAdAccessToken from 'hooks/useAdAccessToken';
import { useEffect, useState } from 'react';

const getInitials = (adContact: any) => {
  if (adContact?.givenName && adContact?.surname) {
    const name = `${adContact?.givenName} ${adContact?.surname}`;
    return name
      .split(' ')
      .map((part) => part[0])
      .join('');
  }
  return '';
};

const useAdAvatar = (employeeNumber: string | null | undefined) => {
  const accessToken = useAdAccessToken();
  const [url, setUrl] = useState<string>('');
  const [userPrincipalName, setUserPrincipalName] = useState<string>('');
  const [initials, setInitials] = useState<string>('');

  useEffect(() => {
    const getPersonnelImage = async () => {
      if (accessToken.accessToken && url === '') {
        await getAdUserByHpEmployeeNumber(accessToken.accessToken, Number(employeeNumber)).then(async (result) => {
          setUserPrincipalName(result?.userPrincipalName);
          const initialsFromRequest = getInitials(result);
          setInitials(initialsFromRequest);
          if (userPrincipalName !== '' && userPrincipalName) {
            await getAdUserImage(accessToken.accessToken, userPrincipalName).then((result) => setUrl(result));
          }
        });
      }
    };
    getPersonnelImage();
  }, [accessToken]);

  return { url, initials };
};

export default useAdAvatar;
