import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, SxProps, Theme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { ReassignRoleAssignmentsType, ReassignRoleSummaryReassignmentsType } from 'types';

type ReassignSummaryProps = {
  reassignRoleAssignments: ReassignRoleAssignmentsType[];
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
};

type ReassignSummarySectionProps = {
  reassignRoleSummaryReassignments: ReassignRoleSummaryReassignmentsType;
};

const accordionRootStyle: SxProps<Theme> = {
  boxShadow: 'none',
};

const accordionSummaryStyle: SxProps<Theme> = {
  flexDirection: 'row-reverse',
};

const reassignmentSummaryHeaderTextStyle: SxProps<Theme> = {
  fontWeight: 'bold',
  textDecoration: 'underline',
};

const indentedRow: SxProps<Theme> = {
  marginLeft: '15px',
  fontSize: '14px',
};

const ReassignSummaryReassignmentSection: FC<ReassignSummarySectionProps> = ({ reassignRoleSummaryReassignments }) => {
  return (
    <>
      {reassignRoleSummaryReassignments.reassignments && reassignRoleSummaryReassignments.reassignments.length > 0 && (
        <>
          <Grid item sx={reassignmentSummaryHeaderTextStyle}>
            Reassignments
          </Grid>
          {reassignRoleSummaryReassignments.reassignments.map((reassignment, index) => (
            <Grid container key={index}>
              <Grid xs={12} item>{`${reassignment.projectName}`}</Grid>
              <Grid xs={12} sx={indentedRow} item>{`${reassignment.personnelReassignedToRole} -> ${
                reassignment.personnelBeingReassigned && reassignment.personnelBeingReassigned !== ''
                  ? reassignment.personnelBeingReassigned
                  : 'Unassigned'
              }`}</Grid>
              <Grid xs={12} sx={indentedRow} item>{`${reassignment.roleName}`}</Grid>
              <Grid container>
                <Grid
                  xs={12}
                  sx={indentedRow}
                  item
                >{`Start Date: ${reassignment.personnelReassignedToRoleStartDate}`}</Grid>
                <Grid
                  xs={12}
                  sx={indentedRow}
                  item
                >{`End Date: ${reassignment.personnelReassignedToRoleEndDate}`}</Grid>
              </Grid>
              {reassignment.notes && reassignment.notes !== '' && (
                <Grid xs={12} sx={indentedRow} item>{`Additional Information: ${reassignment.notes}`}</Grid>
              )}
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

const ReassignSummaryPreservedRolesSection: FC<ReassignSummarySectionProps> = ({
  reassignRoleSummaryReassignments,
}) => {
  return (
    <>
      {reassignRoleSummaryReassignments.preservedRoles &&
        reassignRoleSummaryReassignments.preservedRoles.length > 0 && (
          <>
            <Grid item sx={reassignmentSummaryHeaderTextStyle}>
              Role History Preserved
            </Grid>
            {reassignRoleSummaryReassignments.preservedRoles.map((preservedRole, index) => (
              <Grid container key={index}>
                <Grid xs={12} item>{`${preservedRole.projectName}`}</Grid>
                <Grid xs={12} sx={indentedRow} item>{`${preservedRole.personnel} - ${preservedRole.roleName}`}</Grid>
                <Grid xs={12} sx={indentedRow} item>{`Start Date: ${preservedRole.startDate}`}</Grid>
                <Grid xs={12} sx={indentedRow} item>{`End Date: ${preservedRole.endDate}`}</Grid>
              </Grid>
            ))}
          </>
        )}
    </>
  );
};

const ReassignSummaryNewAssignmentSection: FC<ReassignSummarySectionProps> = ({ reassignRoleSummaryReassignments }) => {
  return (
    <>
      {reassignRoleSummaryReassignments.newAssignments &&
        reassignRoleSummaryReassignments.newAssignments.length > 0 && (
          <>
            <Grid item sx={reassignmentSummaryHeaderTextStyle}>
              New Assignment Created
            </Grid>
            {reassignRoleSummaryReassignments.newAssignments.map((newAssignment, index) => (
              <Grid container key={index}>
                <Grid xs={12} item>{`${newAssignment.projectName}`}</Grid>
                <Grid xs={12} sx={indentedRow} item>{`${
                  newAssignment.personnel ? newAssignment.personnel : 'Unfilled'
                } - ${newAssignment.roleName}`}</Grid>
                <Grid xs={12} sx={indentedRow} item>{`Start Date: ${newAssignment.startDate}`}</Grid>
                <Grid xs={12} sx={indentedRow} item>{`End Date: ${newAssignment.endDate}`}</Grid>
              </Grid>
            ))}
          </>
        )}
    </>
  );
};

const ReassignSummaryAddToLeaveSection: FC<ReassignSummarySectionProps> = ({ reassignRoleSummaryReassignments }) => {
  return (
    <>
      {reassignRoleSummaryReassignments.addToLeave && reassignRoleSummaryReassignments.addToLeave.length > 0 && (
        <>
          <Grid item sx={reassignmentSummaryHeaderTextStyle}>
            Add To Leave
          </Grid>
          {reassignRoleSummaryReassignments.addToLeave.map((leave, index) => (
            <Grid container key={index}>
              <Grid xs={12} item>{`${leave.personnel}`}</Grid>
              <Grid xs={12} sx={indentedRow} item>{`Start Date: ${leave.startDate}`}</Grid>
              <Grid xs={12} sx={indentedRow} item>{`End Date: ${leave.endDate}`}</Grid>
              {leave.notes && leave.notes !== '' && (
                <Grid xs={12} sx={indentedRow} item>{`Additional Information: ${leave.notes}`}</Grid>
              )}
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

const ReassignSummary: FC<ReassignSummaryProps> = ({ reassignRoleSummaryReassignments }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Accordion sx={accordionRootStyle}>
          <AccordionSummary sx={accordionSummaryStyle} expandIcon={<ExpandMoreIcon />}>
            <Typography>Personnel Reassignments Summary</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3}>
              <ReassignSummaryReassignmentSection reassignRoleSummaryReassignments={reassignRoleSummaryReassignments} />
              <ReassignSummaryPreservedRolesSection
                reassignRoleSummaryReassignments={reassignRoleSummaryReassignments}
              />
              <ReassignSummaryNewAssignmentSection
                reassignRoleSummaryReassignments={reassignRoleSummaryReassignments}
              />
              <ReassignSummaryAddToLeaveSection reassignRoleSummaryReassignments={reassignRoleSummaryReassignments} />
            </Grid>
            {!reassignRoleSummaryReassignments.reassignments &&
              !reassignRoleSummaryReassignments.preservedRoles &&
              !reassignRoleSummaryReassignments.newAssignments &&
              !reassignRoleSummaryReassignments.addToLeave && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>No Reassignments To Show</Typography>
                  </Grid>
                </Grid>
              )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
export default ReassignSummary;
