export const ACTIVE_STATUS = ['Awarded', 'Won'];

export const INACTIVE_STATUS = ['Lost', 'Dropped', 'Cancelled'];

export const ANTICIPATED_STATUS = ['Lead', 'Qualify', 'Evaluate', 'Target', 'Procurement', 'Submitted'];

export const ARCHIVED_STATUS = ['Archived'];

export const STATUS = [
  'Lead',
  'Qualify',
  'Evaluate',
  'Target',
  'Procurement',
  'Submitted',
  'Awarded',
  'Won',
  'Lost',
  'Dropped',
  'Cancelled',
  'Archived',
];
