import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import { FC } from 'react';

type ReassignToAnotherRoleProps = {
  reassignProjectAndRole: ProjectsGridRowData | undefined;
  setRadioButtonSelection: (x: string) => void;
};

type RadioButtonSelectionProps = {
  value: string;
  label: string;
  setRadioButtonSelection: (x: string) => void;
};

const RadioButtonSelection: FC<RadioButtonSelectionProps> = ({ value, label, setRadioButtonSelection }) => {
  return (
    <FormControlLabel value={value} control={<Radio />} label={label} onChange={() => setRadioButtonSelection(label)} />
  );
};

const ReassignToAnotherRole: FC<ReassignToAnotherRoleProps> = ({ reassignProjectAndRole, setRadioButtonSelection }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          Would you like to assign {reassignProjectAndRole?.role?.person?.name?.lastCommaFirst ?? ''} another role?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
          <RadioButtonSelection
            value={'assignExistingRole'}
            label={'Assign to an existing role'}
            setRadioButtonSelection={setRadioButtonSelection}
          />
          <RadioButtonSelection
            value={'assignNewRole'}
            label={'Assign to a new role'}
            setRadioButtonSelection={setRadioButtonSelection}
          />
          <RadioButtonSelection
            value={'leaveAvailable'}
            label={'Leave Available'}
            setRadioButtonSelection={setRadioButtonSelection}
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
export default ReassignToAnotherRole;
