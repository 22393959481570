import { MsalProvider } from '@azure/msal-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';
import App from 'components/App';
import AzureAdProtector from 'components/AzureAdProtector';
import CacheBuster from 'components/CacheBuster';
import DebugObserver from 'components/DebugObserver';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { getMsalInstance } from 'config/msalConfig';
import { detect } from 'detect-browser';
import UnsupportedBrowserDisclaimerPage from 'pages/UnsupportedBrowserDisclaimerPage';
import AppApolloProvider from 'providers/AppApolloProvider';
import AppThemeProvider from 'providers/AppThemeProvider';
import ToastProvider from 'providers/ToastProvider';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { APP_ENVIRONMENTS, ENV } from './constants';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY ?? '');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6a014e06f79449a9a9e3ca99f03df855@o322387.ingest.sentry.io/6434176',
    environment: ENV,
    tracesSampleRate: 0,
  });
}

const msalInstance = getMsalInstance();
const browser = detect();
const container = document.getElementById('root');
const root = createRoot(container!);

if (browser && browser.name === 'ie') {
  root.render(
    <Suspense fallback={<LoadingSpinner isFullScreen />}>
      <UnsupportedBrowserDisclaimerPage />
    </Suspense>,
  );
} else {
  root.render(
    <React.StrictMode>
      <RecoilRoot>
        {ENV === APP_ENVIRONMENTS.local && <DebugObserver />}
        <Sentry.ErrorBoundary>
          <AppThemeProvider>
            <Suspense fallback={<LoadingSpinner isFullScreen />}>
              <BrowserRouter>
                <MsalProvider instance={msalInstance}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <ToastProvider>
                      <AppApolloProvider msalInstance={msalInstance}>
                        <AzureAdProtector>
                          <CacheBuster>
                            <App />
                          </CacheBuster>
                        </AzureAdProtector>
                      </AppApolloProvider>
                    </ToastProvider>
                  </LocalizationProvider>
                </MsalProvider>
              </BrowserRouter>
            </Suspense>
          </AppThemeProvider>
        </Sentry.ErrorBoundary>
      </RecoilRoot>
    </React.StrictMode>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
